import React from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";



const CustomModal = ({ openModal, onclose, header,content ,mwidth}) => {
  //   const [open, setOpen] = useState(openModal);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => {
  //     onclose();
  //     setOpen(false);
  //   };
  const CustomBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: mwidth || 450,
    borderRadius: "15px",
    bgcolor: "background.paper",
    textAlign: "center",
    // border: '2px solid ',
    // boxShadow: 24,
    // backgroundColor:'white',
    // color :'black',
    backgroundColor: theme.palette.mode === "light" ? "white" : "black",
    // padding: "15px 25px 20px 25px",
    border:
      theme.palette.mode === "light"
        ? "1px solid #ccc"
        : "1px solid rgba(255, 255, 255, 0.12)",
  }));

  return (
    <Modal
      open={openModal}
      onClose={() => true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBox>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onclose}>
            <CloseIcon sx={{ color: "#8B9635" }} />
          </IconButton>
        </div>
        <div style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {header}
          </Typography>
          {content()}
        </div>
      </CustomBox>
    </Modal>
  );
};

export { CustomModal };
