import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import {Pagination } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import "../items/items.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import { CustomModal } from "../../shared/common/utilites/customModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material/";
import { useFormik} from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import {
  getInvoiceItems,
  getIncomeAccounts,
  getExpenseAccounts,
  getCurrency,
  saveInvoiceItem,
  updateInvoiceItem,
  deleteItem,
  getTaxcode
} from "../../services/Services";
import { createObject, encryptForm } from "../../services/commonService";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { getEncryptedItem } from "../../services/localStorageUtil";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "20px",
  background: "unset",
  border: "2px solid #8B9635",
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === "light" ? "black" : "#fff",
  "&:hover": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "black",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));

const CustomLoader = styled(Loader)(({ theme }) => ({
  loader: {
    width: "31px",
    height: "31px",
  },
}));

const CustomDataGrid = styled(DataGrid)(({ theme }) =>
({
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.12)',
  '& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: '#8B9635',
  },
  '&.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeSmall .MuiDataGrid-menuIconButton .css-xfvph6': {
    display: 'none'
  },
  '& .css-cipkih-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
    color: '#8B9635',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset'
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset'
  },
  '& .MuiDataGrid-row.Mui-selected.commonSelected': {
    backgroundColor: '#8B9635',
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    '&.MuiSvgIcon-fontSizeMedium':{
      color:'#000'
    },
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#000' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'unset'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
}));

const defaultItems = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  search:''};

  const marginTopValue ='104px';
function Items() {
  const { t, i18n } = useTranslation(["language", "common"]);
  const [itemData, setItemData] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [items, setItems] = useState(defaultItems);
  const [toggleIcon, setToggleBtn] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [cancelItem, setCancelItem] = useState(null);
  const [isLoaderOverlay, setIsLoaderOverlay] = useState(false);
  const [incomeAccount, setincomeAccount] = useState([]);
  const [expenseAccount, setexpenseAccount] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [allTaxCode, setAllTaxCode] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [itemno, setItemno] = useState(null);

  const currentLanguage = i18n.language;

  const CustomLoadingOverlay = ()=>{
    return <Loader />;
  }

  const localeText = {
    noRowsLabel: t("common_no_rows"),
    footerRowSelected: (count) => `${count} ${t("common_row_selected")}`,
  };
  
  function CustomNoRowsOverlay() {
    const { t } = useTranslation(["language", "common"]);
    return (
      <GridOverlay>
        <Typography>{t("common_no_data_available")}</Typography>
      </GridOverlay>
    );
  }

  const handleTemplateFieldChangeTax = (event,value) => {
    // console.log(event.target.value);
    // setTaxValue(0.077); allTaxCode
        let taxvalue = allTaxCode.filter((item)=>item.code === event.target.value)
        // 
        formik.setFieldValue("tax", event.target.value);
      }

  const handleOpenModel = ()=>{
    if (!selectedRowIds.length){return}
      setOpenDeleteModal(true);
  }

  const handlePageChange = (event, newPage) => {
    setItems((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const handlePageSizeChange = (event) => {
    setItems((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };

  useEffect(() => {
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
  }, []);

  const handleDelete=()=>{
    if(!selectedRowIds.length>0){return}
    const deleteItemForm = new FormData();
    deleteItemForm.append("cid",selectedRowIds[0] );
    deleteItemForm.append("userName", userDetails.username?userDetails.username:"");
    let object = createObject(deleteItemForm);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    deleteItem(encrData)
      .then((res) => {
        setIsLoaderOverlay(false);
        setOpenDeleteModal(false);
        if (res.status) {
          loadItemTable();
          openAlertSnackBar('success',res.message,marginTopValue);
        } else {
          openAlertSnackBar('error',res.message,marginTopValue);
        }
      })
      .catch((err) => {
        setIsLoaderOverlay(false);
        openAlertSnackBar('error','common_alert',marginTopValue);
      });
  }

  const formattedAmount = (amount)=>{
    return amount.toLocaleString('de-CH', { 
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  });
 }

  const columns = [
    { field: "itemid", headerName: t("itemid"), flex: 0.8},
    { field: "title", headerName: t("item_name"), flex: 1.5},
    { field: "sellprice", headerName: t("common_price"), flex: 0.8 ,
      valueFormatter:(params) => formattedAmount(parseFloat(params.value)) },
    { field: "earningaccount", headerName: t("common_income_account"), flex: 1 },
    { field: "expenseaccount", headerName: t("common_debit_account"), flex: 1 },
    { field: "currencycode", headerName: t("common_currency_code"), flex: 0.8 },
  ];

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const DeleteModalContent=()=>{
    return(
      <>
        <p id="child-modal-description">{t("confirm_delete")}</p>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <CustomBtn onClick={handleCloseDeleteModal} sx={{ mt: 2 }}>
            {t("common_cancel")}
          </CustomBtn>
          <CustomBtn onClick={handleDelete} sx={{ mt: 2 }}>
            {t("common_delete")}
          </CustomBtn>
        </Box>
      </>)
  }

  const loadItemTable = useCallback(() => {
    const controllerLoadItem = new AbortController();
    const signalLoadItem = controllerLoadItem.signal;
    const d = new FormData();
    d.append("limit", items.limit);
    d.append("page", items.page);
    d.append("column", items.column);
    d.append("order", items.order);
    d.append("search", items.search);
    setTableLoader(true);
    setItemData([]);
    
    let objectItem = createObject(d);
    let encryptedToken = encryptForm(objectItem);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    getInvoiceItems(encrData, signalLoadItem)
      .then((res) => {
        if(isMounted)
        {
          setTableLoader(false);
          if (res.status) {
            if(res.data.length>0)
            {
              setItemData(res.data);
              setItemCount(Number(res.filtered));
              formik.setFieldValue('itemid',res.new_itemid);
              setItemno(res.new_itemid);
            }else{
              setItemCount(0);
            }
          } else {
            openAlertSnackBar('error',res.message,marginTopValue);
          }
        }
      })
      .catch((err) => {
        if(isMounted)
        {
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        }
      });

    return () => {
      controllerLoadItem.abort();
      setIsMounted(false);
    };
  }, [items,isMounted]);

  useEffect(()=>{
    const controller = new AbortController();
    const signal = controller.signal;
    const controllerTax = new AbortController();
    const signalTax = controllerTax.signal;

    getTaxcode(signalTax)
      .then((data) => {
        if ( data && data.status && data.data && data.data.length > 0) {
          setAllTaxCode(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controllerTax.abort();
      });

    getCurrency(signal)
      .then((currencyData) => {
        if (isMounted &&
          currencyData &&
          currencyData.status &&
          currencyData.data &&
          currencyData.data.length > 0
        ) {
          setCurrency(currencyData.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller.abort();
      });

      return () => {
        controller.abort();
        controllerTax.abort();
        setIsMounted(false);
      };
  },[isMounted])

  useEffect(()=>{
    const controller7 = new AbortController();
    const signal7 = controller7.signal;
    const controller8 = new AbortController();
    const signal8 = controller8.signal;

    let incForm = new FormData();
    incForm.append("language", currentLanguage);
    let objectinc = createObject(incForm);
    let encryptedTokeninc = encryptForm(objectinc);
    let encrDatainc = new FormData();
    encrDatainc.append("encrToken", encryptedTokeninc);
    getIncomeAccounts(encrDatainc, signal7)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setincomeAccount(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller7.abort();
      });

    let expForm = new FormData();
    expForm.append("language", currentLanguage);
    let object = createObject(expForm);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    getExpenseAccounts(encrData, signal8)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setexpenseAccount(data.data);
        }
      })
      .catch((err) => {})

    return () => {
      controller7.abort();
      controller8.abort();
    };
  },[currentLanguage,isMounted])

  useEffect(() => {
    setIsMounted(true);
    loadItemTable();
    return ()=>{
      closeAlertSnackBar();
    }
  }, [loadItemTable]);

  const initialValues = {
      description:'',
      price:'',
      title:'',
      itemid:'',
      income_account:'',
      debit_account:'',
      currencycode:'',
      purchase_price:'',
      tax:'401'
  };

    const validationSchemaItem = Yup.object().shape({
      title:Yup.string().required(t('common_validate_item_title')),
      debit_account:Yup.string().required(t('common_validate_expense_account')),
      currencycode:Yup.string().required(t('common_validate_currency')),
      income_account:Yup.string().required(t('common_validate_income_account')),
      price:Yup.number().typeError("common_validation_input_number").required(t('common_validate_price')),
      purchase_price:Yup.number().nullable().typeError("common_validation_input_number")
    });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:validationSchemaItem,
    onSubmit: (values) => {
      setIsLoaderOverlay(true);
      if (!toggleIcon) {
        const createItemForm = new FormData();
        const itemObj = {};
        itemObj["description"] = values.description;
        itemObj["incomeaccount"] = values.income_account;
        itemObj["purchaseprice"] = values.purchase_price;
        itemObj["taxcode"]=values.tax;
        itemObj["debitaccount"] = values.debit_account;
        itemObj["currencycode"] = values.currencycode;
        itemObj["price"] = values.price;
        itemObj["title"] = values.title;
        createItemForm.append("message", JSON.stringify(itemObj));
        createItemForm.append("userName", userDetails.username?userDetails.username:"");
        let object = createObject(createItemForm);
        let encryptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken", encryptedToken);
        saveInvoiceItem(encrData)
          .then((res) => {
            setIsLoaderOverlay(false);
            if (res.status) {
              formik.resetForm();
              loadItemTable();
              openAlertSnackBar('success',res.message,marginTopValue);
            } else {
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          })
          .catch((err) => {
            setIsLoaderOverlay(false);
            openAlertSnackBar('error','common_alert',marginTopValue);
          });
      } else {
        const updateItemForm = new FormData();
        const itemObj = {};
        itemObj["description"] = values.description;
        itemObj["incomeaccount"] = values.income_account;
        itemObj["purchaseprice"] = values.purchase_price;
        itemObj["taxcode"]=values.tax;
        itemObj["debitaccount"] = values.debit_account;
        itemObj["currencycode"] = values.currencycode;
        itemObj["price"] = values.price;
        itemObj["title"] = values.title;
        itemObj["itemid"] = values.itemid;
        updateItemForm.append("message", JSON.stringify(itemObj));
        updateItemForm.append("cid",selectedRowIds[0] );
        updateItemForm.append("userName", userDetails.username?userDetails.username:"");
        let object = createObject(updateItemForm);
        let encryptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken", encryptedToken);
        updateInvoiceItem(encrData)
          .then((res) => {
            setIsLoaderOverlay(false);
            if (res.status) {
              formik.resetForm();
              loadItemTable();
              openAlertSnackBar('success',res.message,marginTopValue);
            } else {
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          })
          .catch((err) => {
            setIsLoaderOverlay(false);
            openAlertSnackBar('error','common_alert',marginTopValue);
          });
      }
    },
  });

  useEffect(()=>{
    if(incomeAccount.length===1)
    { 
      if(!formik.dirty){
        formik.setFieldValue("debit_account",incomeAccount[0].code);
      }
    }
  },[incomeAccount,formik])
  
  const handlePriceChange = (event) => {
    const inputValue = event.target.value;
    if (/^(?!0\d)\d*\.?\d*$/.test(inputValue)) {
          formik.setFieldValue("price", inputValue);
    }
  };

  useEffect(()=>{
    if(incomeAccount.length===1)
    { 
      if(!formik.dirty){
        formik.setFieldValue("debit_account",incomeAccount[0].accountid);
      }
    }
  },[incomeAccount,formik])
  
  const setNewForm = () => {
    formik.resetForm();
    formik.setFieldValue("itemid",itemno);
    setCancelItem(initialValues);
    setSelectedRowIds([]);
    setSelectedItem([]);
    setToggleBtn(false);
  };

  const resetItemForm = () => {
    if(!cancelItem)return;
    if((JSON.stringify(cancelItem) !== JSON.stringify(initialValues))){
      formik.setValues(cancelItem)
    }else{
      formik.resetForm();
      formik.setFieldValue("itemid",itemno);
    }
  };


  const handleItemSelectionChange = (newSelection) => {
    if ((newSelection.length === 0)||(selectedRowIds[0] === newSelection[0])) {
      formik.resetForm();
      formik.setFieldValue("itemid",itemno);
      setSelectedRowIds([]);
      setSelectedItem([]);
      setCancelItem(initialValues);
      setToggleBtn(false);
      return;
    }
    setSelectedRowIds(newSelection);
    formik.resetForm();

    setToggleBtn(true);
    const filteredItemObject = itemData.filter(
      (obj) => obj.cid === newSelection[newSelection.length - 1]
    );

    if(filteredItemObject.length ===0){
      return;
    }
    setSelectedItem(filteredItemObject);
    let itemObj =initialValues;
    itemObj={
      title: filteredItemObject[0]?.title?filteredItemObject[0]?.title:'',
      itemid: filteredItemObject[0]?.itemid?filteredItemObject[0]?.itemid:'',
      description: filteredItemObject[0]?.description?filteredItemObject[0]?.description:'',
      price: filteredItemObject[0]?.sellprice? parseFloat(filteredItemObject[0].sellprice).toFixed(2): '',
      income_account: filteredItemObject[0]?.earningaccount? filteredItemObject[0]?.earningaccount:'',
      purchase_price: filteredItemObject[0]?.purchaseprice? parseFloat(filteredItemObject[0].purchaseprice) : '' ,
      tax: filteredItemObject[0]?.taxcode? filteredItemObject[0]?.taxcode:'',
      debit_account: filteredItemObject[0]?.expenseaccount?filteredItemObject[0]?.expenseaccount:'',
      currencycode :filteredItemObject[0]?.currencycode?filteredItemObject[0]?.currencycode:''
    }
    formik.setValues(itemObj);
    setCancelItem(itemObj);
  };
 
  const lastSelectedRowIndex =
    selectedRowIds.length > 0
      ? selectedRowIds[selectedRowIds.length - 1]
      : null;

  const getRowClassName = useCallback(
    (params) => {
      if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
        return "commonSelected";
      }
      if (lastSelectedRowIndex === null) {
        return "commonSelected";
      }
      return "";
    },
    [lastSelectedRowIndex]
  );
  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setItems((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setItems((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };
  
  const copyItem = () => {
    setSelectedRowIds([]);
    formik.setFieldValue("itemid",itemno);
    formik.setFieldValue("title","");
    setCancelItem(initialValues);
    setToggleBtn(false);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setItems((previousParams)=>({
        ...previousParams,
        search:event.target.value,
        page:1
      }))
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="MuiDataGrid-virtualScroller">
        <Box sx={{ flexGrow: 1, margin: "10px 0 0 0" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid item>
                <span className="commonCreateLabel">
                  {t("item")}
                </span>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <div className="actionSection">
                    <div className="commonActionItem">
                      <IconButton onClick={setNewForm}>
                        <AddOutlinedIcon
                          sx={{ fontSize: 30, color: "#8B9635" }}
                        />
                      </IconButton>
                      <span>{t("common_new")}</span>
                    </div>
                    {!toggleIcon ? (
                      <div className="commonActionItem">
                        <IconButton onClick={formik.handleSubmit}>
                          <SaveSharpIcon
                            sx={{ fontSize: 30, color: "#8B9635" }}
                          />
                        </IconButton>
                        <span>{t("common_save")}</span>
                      </div>
                    ) : (
                      <div className="commonActionItem">
                        <IconButton onClick={formik.handleSubmit}>
                          <EditIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                        </IconButton>
                        <span>{t("common_update")}</span>
                      </div>
                    )}
                    <div className="commonActionItem">
                      <IconButton onClick={resetItemForm}>
                        <CloseSharpIcon
                          sx={{ fontSize: 30, color: "#ff0000" }}
                        />
                      </IconButton>
                      <span>{t("common_cancel")}</span>
                    </div>
                    <Box
                      className="commonActionItemEnd"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      {isLoaderOverlay && <CustomLoader />}
                    </Box>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <CustomBox
                  className="leftSection"
                  sx={{
                    minHeight: "725px",
                    height: "auto",
                    boxSizing: "borderBox",
                  }}
                >
                  <Grid container spacing={1}>
                    {!toggleIcon ? (
                      <Grid item xs={12}>
                        <Box
                          style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <span style={{ color: "#8B9635" }}>
                            {t("template_new_mode")}
                          </span>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Box style={{display: "flex", justifyContent: "flex-end" }}>
                          <span style={{ color: "#8B9635" }}>
                            {t("template_edit_mode")}
                          </span>
                        </Box>
                      </Grid>
                    )}
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          id="itemid"
                          label={t("itemid")}
                          variant="outlined"
                          fullWidth
                          disabled ={!toggleIcon}
                          size="small"
                          onChange={formik.handleChange}
                          {...formik.getFieldProps('itemid')}
                          value={formik.values.itemid}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="title"
                          label={t("item_name")+'*'}
                          variant="outlined"
                          fullWidth
                          size="small"
                          onChange={formik.handleChange}
                          {...formik.getFieldProps('title')}
                          value={formik.values.title}
                          error={formik.touched.title && Boolean(formik.errors.title)}
                          helperText={formik.touched.title && formik.errors.title}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="currencycode"
                          label={t("common_currency_code")+'*'}
                          variant="outlined"
                          fullWidth
                          select
                          size="small"
                          onChange={formik.handleChange}
                          value={formik.values.currencycode}
                          {...formik.getFieldProps("currencycode")}
                          error={
                            formik.touched.currencycode &&
                            Boolean(formik.errors.currencycode)
                          }
                          helperText={
                            formik.touched.currencycode &&
                            formik.errors.currencycode
                          }
                        >
                          {currency?.map((option) => (
                            <MenuItem key={option.id} value={option.code}>
                              {option.code}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="price"
                          label={t("common_price")+'*'}
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={formik.values.price}
                          onChange={handlePriceChange}
                          inputProps={{ step: '0.01' }}
                          step={1.00}
                          error={formik.touched.price && Boolean(formik.errors.price)}
                          helperText={formik.touched.price && formik.errors.price}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="debit_account"
                          type="number"
                          label={t("SollKonto")+'*'}
                          variant="outlined"
                          fullWidth
                          select
                          size="small"
                          onChange={formik.handleChange}
                          {...formik.getFieldProps("debit_account")}
                          error={
                            formik.touched.debit_account &&
                            Boolean(formik.errors.debit_account)
                          }
                          helperText={
                            formik.touched.debit_account &&
                            formik.errors.debit_account
                          }
                        >
                          {incomeAccount?.map((option) => (
                            <MenuItem key={option.id} value={option.accountid}>
                              { option.accountid +" | " +option.label }
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="income_account"
                          label={t("ertragskonto")+'*'}
                          variant="outlined"
                          fullWidth
                          select
                          size="small"
                          onChange={formik.handleChange}
                          {...formik.getFieldProps("income_account")}
                          error={
                            formik.touched.income_account &&
                            Boolean(formik.errors.income_account)
                          }
                          helperText={
                            formik.touched.income_account &&
                            formik.errors.income_account
                          }
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: "265px",
                                },
                              },
                            },
                          }}
                        >
                          {expenseAccount?.map((option) => (
                            <MenuItem key={option.id} value={option.accountid}>
                              {option.accountid +" | " + option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="purchase_price"
                          label={t("purchase_price")}
                          variant="outlined"
                          fullWidth
                          size="small"
                          onChange={formik.handleChange}
                          {...formik.getFieldProps("purchase_price")}
                          error={
                            formik.touched.purchase_price &&
                            Boolean(formik.errors.purchase_price)
                          }
                          helperText={
                            formik.touched.purchase_price &&
                            formik.errors.purchase_price
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <TextField
                            id="tax"
                            label={t('tax_field')}
                            variant="outlined"
                            fullWidth
                            name="tax"
                            size="small"
                            select
                            value={formik.values.tax}
                            onChange={(event, value) => {
                              handleTemplateFieldChangeTax(event,value);
                            }}
                          >
                            {allTaxCode.length >0 ? (allTaxCode?.map((option) => (
                              <MenuItem key={option.id} value={option.taxid}>
                                {option.label}
                              </MenuItem>
                            ))):(<MenuItem value="401"> Mwst. 8.1 exkl. </MenuItem>)}
                          </TextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        label={t("common_description")}
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        {...formik.getFieldProps('description')}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                      />
                    </Grid>
                  </Grid>
                </CustomBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="commonCreateLabel">
                  {t("common_list_items")}
              </span>
              <Item>
                <div className="actionSectionRight">
                  <div className="commonactionRight">
                    <div className="commonActionItem">
                      <IconButton onClick={copyItem}>
                        <ContentCopyIcon
                          sx={{ fontSize: 30, color: "#8B9635" }}
                        />
                      </IconButton>
                      <span>{t("common_copy")}</span>
                    </div>
                    <div className="commonActionItem">
                      <IconButton onClick={handleOpenModel}>
                        <DeleteIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                      </IconButton>
                      <span>{t("common_delete")}</span>
                    </div>
                  </div>
                <div className="searchInvoiceDiv">
                  <TextField
                    label={t("common_search")}
                    id="searchInvoices"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyDown={handleSearchKeyDown}
                    sx={{ mb: 1, width: "100%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  </div>
               </div>
              </Item>
              <div style={{ height: "725px", marginTop: "10px" }}>
                <CustomDataGrid
                  rows={itemData}
                  getRowId={(row) => row.cid}
                  columns={columns}
                  rowHeight={33}
                  headerHeight={40}
                  pagination
                  rowsPerPageOptions={[10, 20]}
                  onPageChange={handlePageChange}
                  page={items.page-1}
                  getRowClassName={getRowClassName}
                  selectionModel={selectedRowIds}
                  onSortModelChange={handleSortModelChange}
                  onSelectionModelChange={handleItemSelectionChange}
                  pageSize={items.limit}
                  loading={tableLoader}
                  onPageSizeChange={handlePageSizeChange}
                  paginationMode="server"
                  paginationComponent={Pagination}
                  rowCount={itemCount}
                  localeText={localeText}
                  scrollbarSize={0}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                  disableColumnMenu
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: t("common_rows_per_page"),
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      <CustomModal
        openModal={openDeleteModal}
        onclose={handleCloseDeleteModal}
        header={t("common_delete")}
        content={DeleteModalContent}
      />
    </>
  );
}

export default Items;
