export const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
  };

  export const AuthDatatypes ={
    LOGIN:'LOGIN',
    LOGOUT:'LOGOUT'
  }

  const auth = (state = initialState, action) => {
    switch (action.type) {
      case AuthDatatypes.LOGIN: {
        const { user } = action?.payload;
        return {
          ...state,
          isLoggedIn: action?.payload.isLoggedIn,
          isInitialized: action?.payload.isInitialized,
          user
        };
      }
      case AuthDatatypes.LOGOUT: {
        return {
          ...state,
          isInitialized: true,
          isLoggedIn: false,
          user: null
        };
      }
  
      default: {
        return { ...state };
      }
    }
  };
  
  export default auth;