import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
// import { encryptForm } from './commonService';
import { clearCookieStorage } from './localStorageUtil';

const IdleTimeout=(timeout)=>{
    const { reset } = useIdleTimer({
        timeout,
        onIdle: handleLogout,
      });

      function handleLogout() {
        clearCookieStorage();
        // let encryptedData = encryptForm(process.env.REACT_APP_REDIRECT_PROFFIX_URL, process.env.REACT_APP_ENCRIPTIONKEY);
        // window.location.assign(process.env.REACT_APP_MTCURL + '/auth/login/' + encodeURIComponent(encryptedData));
      }

      useEffect(() => {
        // Reset the idle timer on user activity
        const onActivity = () => {
          reset();
        };
        document.addEventListener('keydown', onActivity);
        document.addEventListener('mousemove', onActivity);
        document.addEventListener('mousedown', onActivity);
        document.addEventListener('touchstart', onActivity);
        document.addEventListener('touchmove', onActivity);
        document.addEventListener('click', onActivity);
        document.addEventListener('scroll', onActivity);
        document.addEventListener('wheel', onActivity);
        document.addEventListener('resize', onActivity);
    
        // Clean up event listeners
        
        return () => {
          document.removeEventListener('keydown', onActivity);
          document.removeEventListener('mousemove', onActivity);
          document.removeEventListener('mousedown', onActivity);
          document.removeEventListener('touchstart', onActivity);
          document.removeEventListener('touchmove', onActivity);
          document.removeEventListener('click', onActivity);
          document.removeEventListener('scroll', onActivity);
          document.removeEventListener('wheel', onActivity);
          document.removeEventListener('resize', onActivity);
        };
      }, [ reset]);
    
      return { reset };
}

export default IdleTimeout;