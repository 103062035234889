import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
// const crypto = require('crypto-browserify');
// const jwt = require('jsonwebtoken');
// import jwt from 'jsonwebtoken';


export function encrypt(data, encryptionKey) {
  const ciphertext = CryptoJS.AES.encrypt(data, encryptionKey).toString();
  return ciphertext;
}

// export function encryptData(data) {
//   // Encrypt the data
//   const encryptedText = CryptoJS.AES.encrypt(data, process.env.REACT_APP_ENCRIPTIONKEY).toString();
//   return encryptedText;
// }


export function decrypt(cookie) {
  const binaryEncryptionKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENCRIPTIONKEY);
  const binaryIV = CryptoJS.enc.Hex.parse('');

  let cookieExists = Cookies.get(cookie) ;
  if (!cookieExists) return; 
  const decryptedInput = CryptoJS.AES.decrypt({
     ciphertext: CryptoJS.enc.Base64.parse(Cookies.get(cookie)),
  }, binaryEncryptionKey, {
    iv: binaryIV,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);

  return decryptedInput;
}

export function createObject(formData) {
  let obj = {}
  formData.forEach((value, key) => {
    obj[key] = value;
  });
  return JSON.stringify(obj)
}

export function encryptForm(data) {
  const binaryEncryptionKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENCRIPTIONKEY);
  const encryptedOutput = CryptoJS.AES.encrypt(data, binaryEncryptionKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return encryptedOutput;
}

export function decryptForm(encryptedData){  

  const binaryEncryptionKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENCRIPTIONKEY);
  const binaryIV = CryptoJS.enc.Hex.parse('');
  
  const decryptedInput = CryptoJS.AES.decrypt({
      ciphertext: CryptoJS.enc.Base64.parse(encryptedData),
  }, binaryEncryptionKey, {
      iv: binaryIV,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
  return decryptedInput;
  // console.log(decryptedInput);
}
