import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, FormGroup, Pagination } from "@mui/material";
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "../address/address.scss";
import Autocomplete from "@mui/material/Autocomplete";
import CachedIcon from "@mui/icons-material/Cached";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material/";
import { useFormik } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import {
  loadAddress,
  getCurrency,
  getLanguages,
  getConditions,
  getCountry,
  getTaxcode,
  createAddress,
  updateAddress,
  checkLoadAddress,
  getModules,
  loadTemplates,
  updateCredit,
} from "../../services/Services";
import { createObject, encryptForm } from "../../services/commonService";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { getEncryptedItem } from "../../services/localStorageUtil";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CustomCheckbox  = styled(Checkbox)(({ theme }) => ({
  '&.MuiButtonBase-root.Mui-checked':{
    color:'#8B9635'
  }
}))

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "20px",
  background: "unset",
  border: "2px solid #8B9635",
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === "light" ? "black" : "#fff",
  "&:hover": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "black",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
backgroundColor:theme.palette.mode === "light" ?'#f7f7f7':'#242424a3',
'&:hover':{
  backgroundColor:theme.palette.mode === "light" ?'#efefef':'#626262a8'
}
}))

const CustomBoxTemplate = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "4%",
  bgcolor: "background.paper",
  textAlign: "center",
  backgroundColor: theme.palette.mode === "light" ? "white" : "black",
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
}));

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
  "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#8B9635",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "unset",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "unset",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#8B9635",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
}));

const defaultAddress = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  search:'',
  ebill:false
};
  
const marginTopValue = '114px';

function Address() {
  const { t, i18n } = useTranslation(["language", "common"]);
  const [addressData, setAddressData] = useState([]);
  const [queueCount, setQueueCount] = useState(0);
  const [modules, setModules] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [lang, setLanguage] = useState([]);
  const [isLastLoading, setIsLastLoading] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [condition, setConditions] = useState([]);
  const [country, setCountry] = useState([]);
  const [toggleIcon, setToggleBtn] = React.useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedAddressIds, setselectedAddressIds] = useState([]);
  const [storeSelected, setDataToStore] = React.useState({});
  const [tableLoader, setTableLoader] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isLoaderOverlay, setIsLoaderOverlay] = useState(false);
  const [allTaxCode, setAllTaxCode] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [lastUpdatedTimeTemplate, setlastUpdatedTemplate] = useState("");
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [templateItems, setTemplateItems] = useState([]);
  const [previousTemplateItem, setPreviousTemplateItem] = useState([]);
  const [searchColumnTemplate, setSearchColumnTemplate] = useState("");
  const [creditValue, setCreditValue] = useState(0);
  const [currencyValue, setCurrencyValue] = useState("");
  const [address, setAddress] = useState(defaultAddress);
  const [moduleValue, setModuleValue] = useState([]);
  const [isMounted, setIsMounted] = useState(true);

  const columns = [
    { field: "companyname", headerName: t("common_company_name"),t_key:'common_company_name', flex: 1.3 },
    { field: "street", headerName: t("common_street"),t_key:'common_street', flex: 1.4 ,
      renderCell: (params) => {
        const {value}=params;
        return (
          (value)? value +' '+params.row.housenumber :''
        );
      },
    },
    // { field: "housenumber", headerName: t("common_street_number"), flex: 1 },
    { field: "zip", headerName: t("common_zip"),t_key:'common_zip', flex: 0.8 },
    { field: "city", headerName: t("common_city"),t_key:'common_city', flex: 1 },
    { field: "country", headerName: t("common_country_code"),t_key:'common_country_code', flex: 1 }
  ];
  
  const [addressColumn, setAddressColumn] = useState(columns);

  useEffect(()=>{
   
    setAddressColumn((prevColumns) => prevColumns.map((col) => ({
      ...col,
      headerName: t(col.t_key),
    })));
  },[t])

  const handleClickTemplateModal = () => {
    setOpenTemplateModal(true);
    setIsTemplate(true);
  };

  const handleCloseTemplateModal = () => {
    setOpenTemplateModal(false);
    formikModal.resetForm();
    setIsTemplate(false);
  };

  const handleClickCreditModal = () => {
    setOpenCreditModal(true);
    formikModalCredit.setFieldValue("credit", creditValue);
    formikModalCredit.setFieldValue("selectedOptions", moduleValue);
  };

  const handleCloseCreditModal = () => {
    setOpenCreditModal(false);
    formikModalCredit.resetForm();
  };

  const handleConfirmCredit = async () => {
    setCreditValue(formikModalCredit.values.credit);
    UpdateCredit(formikModalCredit.values.credit,formikModalCredit.values.selectedOptions.toString());
    handleCloseCreditModal();
  };

  function CustomNoRowsOverlay() {
    const { t } = useTranslation(["language", "common"]);
    return (
      <GridOverlay>
        <Typography>{t("common_no_data_available")}</Typography>
      </GridOverlay>
    );
  }
  const currentLanguage = i18n.language;

  const formateD = useCallback(
    (data) => {
      if (currentLanguage === "en") {
        return new Date(data).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      } else {
        return new Date(data).toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      }
    },
    [currentLanguage]
  );

  const handleKeyDown = (event) => {
    event.preventDefault();
    setSearchColumnTemplate(event.target.value);
  };

  const handleClearField = () => {
    if (formikModal.values.templatename === "") {
      setSearchColumnTemplate("");
      formikModal.setFieldValue("templatename", "");
    }
  };

  const handleChangeCurrency = (event) => {
    formik.setFieldValue("currencycode", event.target.value);
    setCurrencyValue(event.target.value);
  };

  const initialValuesModal = {
    id: 0,
    module: "",
    template: "",
    templatename: "",
    user: "",
    date: "",
  };
  const initialValuesCredit = {
    credit: creditValue,
    selectedOptions:[]
  };

  const columnsTemplate = [
    { field: "module", headerName: t("common_module"), flex: 1 },
    { field: "templatename", headerName: t("invoice_queue_template"), flex: 2 },
    { field: "user", headerName: t("user"), flex: 1.3 },
    {
      field: "date",
      headerName: t("date"),
      flex: 1.3,
      valueFormatter: (params) => formateD(params.value),
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      flex: 0.5,
      renderCell: (params) => {
        const handleClick = () => {
          let templates = templateItems.filter(
            (item) => item.id !== params.row.id
          );
          setTemplateItems(templates);
        };

        return (
          <IconButton onClick={handleClick}>
            <CloseSharpIcon sx={{ fontSize: 30, color: "#ff0000" }} />
          </IconButton>
        );
      },
    },
  ];

  const UpdateCredit=(credit,modules)=>{
    const d =new FormData();
    d.append("addressId",selectedAddressIds);
    //d.append("isAuthUser",'');
    d.append("userName",userDetails?.username);
    d.append("credit",credit);
    d.append("modules",modules);
    let object=createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    updateCredit(encrData)
      .then((res)=>{
        if(res.status){
          openAlertSnackBar('success',res.message,marginTopValue);
          loadAddressTable();
        }else{
          openAlertSnackBar('error',res.message,marginTopValue);
        }
        formikModalCredit.resetForm();
      }).catch((error)=>{
        openAlertSnackBar('error','common_alert',marginTopValue);
      })
  }

  const loadTemplateData = useCallback(() => {
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData();
    d.append("limit", 10);
    d.append("page", 1);
    d.append("column", "name");
    d.append("order", "asc");
    d.append("search", searchColumnTemplate);
    //d.append("isAuthUser", '');
    setTemplateData([]);
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    const getTemplateData = setTimeout(() => {
      loadTemplates(encrData, signal4)
        .then((res) => {
          if(isMounted)
          {
            if (res.status) {
              setTemplateData(res.data);
            }
          }
        })
    }, 2000);

    return () => {
      controller4.abort();
      clearTimeout(getTemplateData);
      setIsMounted(false);
    };
  }, [searchColumnTemplate,isMounted]);

  useEffect(() => {
    if (isTemplate) {
      loadTemplateData();
    }
  }, [loadTemplateData, isTemplate]);

  const validationSchemaTemplate = Yup.object().shape({
    module: Yup.string().required(t("common_validate_module")),
    templatename: Yup.string().required(t("common_validate_template")),
  });

  const validationSchemaCredit = Yup.object().shape({
    credit: Yup.number()
    .typeError(t('common_validation_input_number'))
    .required(t('common_credit_required'))
  });

  const formikModalCredit = useFormik({
    initialValues: initialValuesCredit,
    validationSchema: validationSchemaCredit,
    onSubmit: (values) => {
      if ((creditValue === 0) && (parseInt(values.credit) === 0))
      {
        formikModalCredit.setFieldError('credit', t('common_credit_required'));
        return;
      }
      handleConfirmCredit();
    },
  });

  const formikModal = useFormik({
    initialValues: initialValuesModal,
    validationSchema: validationSchemaTemplate,
    onSubmit: (values) => {
      const dates = new Date();
      const formatedate = dates.toISOString().split("T")[0];
      values.date = formatedate;
      values.id = dates.getTime();
      values.user = userDetails?.username;
      const templateCid = templateData
        .filter((options) => options.name === values.templatename)
        .map((options) => options.cid)[0]
        .toString();
      values.template = templateCid;
      if (templateItems.length > 0) {
        setTemplateItems([...templateItems, values]);
      } else {
        setTemplateItems([values]);
      }
      handleCloseTemplateModal();
      formikModal.resetForm();
    },
  });

  useEffect(() => {
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData);
          setUserDetails(userDataobj);
      }
  }, []);

  const loadAddressTable = useCallback(() => {
    const controllerLoadAddress = new AbortController();
    const signalLoadAddress = controllerLoadAddress.signal;
    const d = new FormData();
    d.append("limit", address.limit);
    d.append("page", address.page);
    d.append("column", address.column);
    d.append("order", address.order);
    d.append("search", address.search);
    d.append("ebill", address.ebill);
   // d.append("isAuthUser", '');
    setTableLoader(true);
    setAddressData([]);
    const lastUpdated = localStorage.getItem("LastUpdatedAddress");
    if (lastUpdated) {
      setlastUpdatedTemplate(lastUpdated);
      setIsLastLoading(true);
    }
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadAddress(encrData, signalLoadAddress)
      .then((res) => {
        if(isMounted)
        {
          setTableLoader(false);
          if (res.status) {
            if(res.data.length>0)
            {
              setAddressData(res.data);
              setQueueCount(Number(res.filtered));
            }else{
              setQueueCount(0);
            }
          } else {
            openAlertSnackBar('error',res.message,marginTopValue);
          }
        }
      })
      .catch((err) => {
        if(isMounted)
        {
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        }
      });

    return () => {
      controllerLoadAddress.abort();
    };
  }, [address,isMounted]);

  const formatDate = useCallback(
    (data) => {
      if (currentLanguage === "en") {
        return new Date(data).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return new Date(data).toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    },
    [currentLanguage]
  );

  const getCheckLoadAddress = useCallback(() => {
    const controllerCheckLoadAddress = new AbortController();
    const signalCheckLoadAddress = controllerCheckLoadAddress.signal;
    setTableLoader(true);
    setAddressData([]);
    const d = new FormData();
    //d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setIsLastLoading(false);
    checkLoadAddress(encrData, signalCheckLoadAddress)
      .then((res) => {
        if(isMounted)
        {
          setTableLoader(false);
          if (res.status) {
            loadAddressTable();
            const now = new Date();
            localStorage.setItem("LastUpdatedAddress", now);
            setlastUpdatedTemplate(now);
            setIsLastLoading(true);
          } else {
            openAlertSnackBar('error',res.message,marginTopValue);
          }
        }
      })
      .catch((err) => {
        if(isMounted)
        {
          openAlertSnackBar('error','common_alert',marginTopValue);
        }
      });
    return () => {
      controllerCheckLoadAddress.abort();
      setIsMounted(false);
    };
  }, [loadAddressTable,isMounted]);

  useEffect(() => {
    setIsMounted(true);
    loadAddressTable();
    return ()=>{
      closeAlertSnackBar()
    }
  }, [loadAddressTable]);

  const initialValuesCreateInvoice = {
    companyname: "",
    street: "",
    housenumber: "",
    zip: "",
    city: "",
    countrycode: "",
    taxcode: "",
    currencycode: "",
    conditioncode: "",
    credit: 0,
    language: "",
    recipientId: "",
    email:""
  };

  function CustomLoadingOverlay() {
    return <Loader />;
  }

  const validationSchema = Yup.object().shape({
    companyname: Yup.string().required(t("address_companyname_required")),
    street: Yup.string().required(t("address_street_required")),
    housenumber: Yup.string().required(t("address_housenumber_required")),
    zip: Yup.string().required(t("address_zip_required")),
    city: Yup.string().required(t("address_city_required")),
    countrycode: Yup.string().required(t("address_countrycode_required")),
    currencycode: Yup.string().required(t("address_currencycode_required")),
    conditioncode: Yup.string().required(t("address_conditioncode_required")),
    language: Yup.string().required(t("address_language_required")),
    recipientId: Yup.string().test(
      "optionalNumber",
      "Value must be a number with 17 digits",
      (value) => {
        if (!value) {
          return true;
        }
        return /^\d{17}$/.test(value);
      }
    ),
    email: Yup.string().test(
      'isMultipleEmails',
      t('invalid_email'),
      value => {
        if (!value) return false; 
        const emails = value.split(',').map(email => email.trim());
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        return emails.every(email => emailRegex.test(email));
      }
    )
  });

  const formik = useFormik({
    initialValues: initialValuesCreateInvoice,
    validationSchema,
    onSubmit: (values) => {
      setIsLoaderOverlay(true);
      let templates = "";
      values.credit = creditValue;
      if (templateItems && templateItems.length > 0) {
        const templateObj = templateItems.map(
          ({ id, templatename, ...keepData }) => keepData
        );
        templates = templateObj;
      }
      if (!toggleIcon) {
        const addressForm = new FormData();
        const addressObj = initialValuesCreateInvoice;
        addressObj["companyname"] = values.companyname;
        addressObj["street"] = values.street;
        addressObj["housenumber"] = values.housenumber;
        addressObj["zip"] = values.zip;
        addressObj["city"] = values.city;
        addressObj["countrycode"] = values.countrycode;
        addressObj["taxcode"] = values.taxcode;
        addressObj["currencycode"] = values.currencycode;
        addressObj["credit"] = values.credit;
        addressObj["conditioncode"] = values.conditioncode;
        addressObj["recipientId"] = values.recipientId;
        addressObj["language"] = values.language;
        addressObj["email"] = values.email;
        // addressObj["groupcode"] = process.env.REACT_APP_GROUPCODE;
        addressObj["template_json"] = templates;
        addressObj["userName"] = userDetails.username?userDetails.username:"";
        addressForm.append("message", JSON.stringify(addressObj));
        //addressForm.append("isAuthUser", '');
        let object = createObject(addressForm);
        let encryptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken", encryptedToken);
        createAddress(encrData)
          .then((res) => {
            setIsLoaderOverlay(false);
            if (res.status) {
              resetForm();
              setTemplateItems([]);
              loadAddressTable();
              openAlertSnackBar('success',res.message,marginTopValue);
            } else {
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          })
          .catch((err) => {
            setIsLoaderOverlay(false);
            openAlertSnackBar('error','common_alert',marginTopValue);
          });
      } else {
        const addressForm = new FormData();
        const addressObj = initialValuesCreateInvoice;
        addressObj["companyname"] = values.companyname;
        addressObj["street"] = values.street;
        addressObj["housenumber"] = values.housenumber;
        addressObj["zip"] = values.zip;
        addressObj["city"] = values.city;
        addressObj["countrycode"] = values.countrycode;
        addressObj["recipientId"] = values.recipientId;
        addressObj["taxcode"] = values.taxcode;
        addressObj["credit"] = values.credit;
        addressObj["currencycode"] = values.currencycode;
        addressObj["conditioncode"] = values.conditioncode;
        addressObj["language"] = values.language;
        addressObj["email"] = values.email;
        addressObj["template_json"] = templates;
        // addressObj["groupcode"] = process.env.REACT_APP_GROUPCODE;
        addressObj["userName"] = userDetails.username?userDetails.username:"";
        let messageObj = {
          addressid: selectedAddressIds,
          custaddress: addressObj,
        };
        addressForm.append("message", JSON.stringify(messageObj));
     //   addressForm.append("isAuthUser", '');
        let object = createObject(addressForm);
        let encryptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken", encryptedToken);
        updateAddress(encrData)
          .then((res) => {
            setIsLoaderOverlay(false);
            if (res.status) {
              loadAddressTable();
              setTemplateItems([]);
              openAlertSnackBar('success',res.message,marginTopValue);

            } else {
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          })
          .catch((err) => {
            setIsLoaderOverlay(false);
            openAlertSnackBar('error','common_alert',marginTopValue);
          });
      }
    },
  });

  const localeText = {
    noRowsLabel: t("common_no_rows"),
    footerRowSelected: (count) => `${count} ${t("common_row_selected")}`,
  };

  const CustomLoader = styled(Loader)(({ theme }) => ({
    loader: {
      width: "31px",
      height: "31px",
    },
  }));

  const resetForm = ()=>{
    formik.resetForm();
    // setIsTax(true);
  }

  const setNewForm = () => {
    resetForm();
    setDataToStore({});
    setTemplateItems([]);
    setSelectedRowIds([]);
    setToggleBtn(false);
    setCurrencyValue("");
    setCreditValue(0);
  };

  const resetAddressForm = () => {
    resetForm();
    setTemplateItems([]);
    Object.keys(storeSelected).length > 0 && formik.setValues(storeSelected);
    previousTemplateItem.length > 0 && setTemplateItems(previousTemplateItem);
  };
  const handlePageChange = (event, newPage) => {
    setAddress((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const handlePageSizeChange = (event) => {
    setAddress((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };

  const handleFieldChange = (event) => {
    const fieldValue = event;
    formik.setFieldValue("countrycode", fieldValue);
  };

  const handleaddressSelectionModelChange = (newSelection) => {
    if (newSelection.length === 0) {
      resetForm();
      setSelectedRowIds([]);
      setselectedAddressIds([]);
      setDataToStore({});
      setToggleBtn(false);
      setTemplateItems([]);
      setCurrencyValue("");
      setCreditValue(0);
      return;
    }
    setTemplateItems([]);
    setCurrencyValue("");
    setCreditValue(0);
    setSelectedRowIds(newSelection);
    if (selectedRowIds[0] === newSelection[0]) {
      resetForm();
      setSelectedRowIds([]);
      setselectedAddressIds([]);
      setDataToStore({});
      setToggleBtn(false);
    } else {
      setToggleBtn(true);
      const filteredObject = addressData.filter(
        (obj) => obj.cid === newSelection[newSelection.length - 1]
      );
      if (filteredObject[0].template_json) {
        const templateValues = filteredObject[0].template_json.map(
          (items, index) => {
            items.id = index + new Date().getTime();
            return items;
          }
        );
        setPreviousTemplateItem(templateValues);
        setTemplateItems(templateValues);
      }
      setselectedAddressIds(filteredObject[0].addressid);
      const filteredcurrencyObject = currency.filter(
        (obj) => obj.id === filteredObject[0].currencyid
      );

      const filteredcountryObject = country.filter(
        (obj) => obj.id === filteredObject[0].countryid
      );

      const filteredTaxidObject = allTaxCode.filter(
        (obj) => obj.id === filteredObject[0].taxid
      );

      const filteredcondtionObject = condition.filter(
        (obj) => obj.id === filteredObject[0].conditionid
      );

      const filteredlangObject = lang.filter(
        (obj) => obj.id === filteredObject[0].languageid
      );

      let obj = initialValuesCreateInvoice;
      obj = {
        companyname:
          filteredObject.length !== 0
            ? filteredObject[0].companyname
              ? filteredObject[0].companyname
              : ""
            : "",
        street:
          filteredObject.length !== 0
            ? filteredObject[0].street
              ? filteredObject[0].street
              : ""
            : "",
        housenumber:
          filteredObject.length !== 0
            ? filteredObject[0].housenumber
              ? filteredObject[0].housenumber
              : ""
            : "",
        zip:
          filteredObject.length !== 0
            ? filteredObject[0].zip
              ? filteredObject[0].zip
              : ""
            : "",
        city:
          filteredObject.length !== 0
            ? filteredObject[0].city
              ? filteredObject[0].city
              : ""
            : "",
        countrycode:
          filteredcountryObject.length !== 0
            ? filteredcountryObject[0].code
              ? filteredcountryObject[0].code
              : ""
            : "",
        taxcode:
          filteredTaxidObject.length !== 0
            ? filteredTaxidObject[0].code
              ? filteredTaxidObject[0].code
              : ""
            : "",
        currencycode:
          filteredcurrencyObject.length !== 0
            ? filteredcurrencyObject[0].code
              ? filteredcurrencyObject[0].code
              : ""
            : "",
        conditioncode:
          filteredcondtionObject.length !== 0
            ? filteredcondtionObject[0].code
              ? filteredcondtionObject[0].code
              : ""
            : "",
        language:
          filteredlangObject.length !== 0
            ? filteredlangObject[0].code
              ? filteredlangObject[0].code
              : ""
            : "",
        recipientId:
          filteredObject.length > 0
            ? filteredObject[0].recipientid
              ? filteredObject[0].recipientid
              : ""
            : "",
        credit:
          filteredObject.length > 0
            ? filteredObject[0].credit
              ? filteredObject[0].credit
              : ""
            : "",
        email:
          filteredObject.length > 0
            ? filteredObject[0].email
              ? filteredObject[0].email
              : ""
            : ""
      };
      formik.setValues(obj);

      setDataToStore(obj);
      filteredObject.length > 0 && filteredObject[0].credit
        ? setCreditValue(filteredObject[0].credit)
        : setCreditValue(0);
      (filteredObject.length > 0 && filteredObject[0].modules)
        ? setModuleValue(filteredObject[0].modules):setModuleValue([]);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const controller1 = new AbortController();
    const signal1 = controller1.signal;
    const controller3 = new AbortController();
    const signal3 = controller3.signal;
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const controller5 = new AbortController();
    const signal5 = controller5.signal;
    const controller6 = new AbortController();
    const signal6 = controller6.signal;
    getCurrency(signal)
      .then((currencyData) => {
        if (isMounted &&
          currencyData &&
          currencyData.status &&
          currencyData.data &&
          currencyData.data.length > 0
        ) {
          setCurrency(currencyData.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller.abort();
      });
    getLanguages(signal1)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setLanguage(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller1.abort();
      });
    getConditions(signal3)
      .then((data) => {
        if ( isMounted && data && data.status && data.data && data.data.length > 0) {
          setConditions(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller3.abort();
      });

    getCountry(signal4)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setCountry(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller4.abort();
      });
    getTaxcode(signal5)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          let taxLength = data.data.reduce((max, obj) => obj.id > max ? obj.id : max, data.data[0].id) + 1;
          let empty = {id:taxLength,code:'',label:'',value:''};
          data.data.push(empty);
          let tax = data.data;
          setAllTaxCode(tax);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller5.abort();
      });
    getModules(signal6)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setModules(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller6.abort();
      });
    return () => {
      controller.abort();
      controller1.abort();
      controller3.abort();
      controller4.abort();
      controller5.abort();
      controller6.abort();
      setIsMounted(false);
    };
  }, [isMounted]);

  const lastSelectedRowIndex =
    selectedRowIds.length > 0
      ? selectedRowIds[selectedRowIds.length - 1]
      : null;

  const getRowClassName = useCallback(
    (params) => {
      if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
        return "commonSelected";
      }
      if (lastSelectedRowIndex === null) {
        return "commonSelected";
      }
      return "";
    },
    [lastSelectedRowIndex]
  );
  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setAddress((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setAddress((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setAddress((previousParams)=>({
        ...previousParams,
        search:event.target.value,
        page:1
      }))
    }
  };

  const handleCheckBoxChange = (event)=>{
    console.log("eve",event.target.checked);
    if(event.target.checked)
    {
      const editableColumns = [...columns];
      editableColumns.push(
        { field: "recipientid", headerName: t("recipientid"),t_key:'recipientid', flex: 1.4 }
      );

      setAddressColumn(editableColumns);
      setAddress((previousParams)=>({
        ...previousParams,
        ebill:true,
        page:1
      }))
    }else{
      setAddressColumn(columns);
      setAddress((previousParams)=>({
        ...previousParams,
        ebill:false,
        page:1
      }))
    }
  }

  const handleCopyAddress = () => {
    setSelectedRowIds([]);
    setDataToStore({});
    formik.setFieldValue("companyname","");
    setToggleBtn(false);
  };

  return (
    <>
      <CssBaseline />
      <div className="MuiDataGrid-virtualScroller">
        <Box sx={{ flexGrow: 1, margin: "10px 0 0 0" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <span className="commonCreateLabel">
                {t("common_create_address")}
              </span>
              <Grid item >
                <Item >
                  <div className="actionSection">
                    <div className="commonActionItem">
                      <IconButton onClick={setNewForm}>
                        <AddOutlinedIcon
                          sx={{ fontSize: 30, color: "#8B9635" }}
                        />
                      </IconButton>
                      <span>{t("common_new")}</span>
                    </div>
                    {!toggleIcon ? (
                      <div className="commonActionItem">
                        <IconButton onClick={formik.handleSubmit}>
                          <SaveSharpIcon
                            sx={{ fontSize: 30, color: "#8B9635" }}
                          />
                        </IconButton>
                        <span>{t("common_save")}</span>
                      </div>
                    ) : (
                      <div className="commonActionItem">
                        <IconButton onClick={formik.handleSubmit}>
                          <EditIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                        </IconButton>
                        <span>{t("common_update")}</span>
                      </div>
                    )}
                    <div className="commonActionItem">
                      <IconButton onClick={resetAddressForm}>
                        <CloseSharpIcon
                          sx={{ fontSize: 30, color: "#ff0000" }}
                        />
                      </IconButton>
                      <span>{t("common_cancel")}</span>
                    </div>
                    <Box
                      className="commonActionItemEnd"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      {isLoaderOverlay && <CustomLoader />}
                    </Box>
                  </div>
                </Item>
              </Grid>
              <Grid item container xs={12}>
                <CustomBox
                  className="leftSection"
                  sx={{
                    minHeight: "755px",
                    height: "auto",
                    boxSizing: "borderBox",
                  }}
                >
                  <Grid item container xs={12} spacing={1}>
                  {!toggleIcon ? (
                    <Grid item  xs={12}>
                      <Box
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <span style={{ color: "#8B9635" }}>
                          {t("template_new_mode")}
                        </span>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item  xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flex: "0 0 100%",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            display:"flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <span>{t("common_credit")}</span>&nbsp;:
                          {currencyValue && <>&nbsp;{currencyValue}</>}
                          <span style={{ margin: "0px 5px 0px 5px" }}>
                            {creditValue}
                          </span>
                          <CustomIconButton
                            className="creditEditBtn"
                            onClick={handleClickCreditModal}
                            title={t("edit_credit")}
                            sx={{ padding: "5px", marginLeft: "5px" }}
                          >
                            <EditIcon sx={{ color: "#8B9635", fontSize: 22 }} />
                          </CustomIconButton>
                        </span>
                        <Box style={{ justifyContent: "flex-end" }}>
                          <span style={{ color: "#8B9635" }}>
                            {t("template_edit_mode")}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  <Grid item  xs={12}>
                    <TextField
                      id="companyname"
                      label={t("company_name")+'*'}
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...formik.getFieldProps("companyname")}
                      error={
                        formik.touched.companyname &&
                        Boolean(formik.errors.companyname)
                      }
                      helperText={
                        formik.touched.companyname && formik.errors.companyname
                      }
                    />
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="street"
                        label={t("common_street")+'*'}
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...formik.getFieldProps("street")}
                        error={
                          formik.touched.street && Boolean(formik.errors.street)
                        }
                        helperText={formik.touched.street && formik.errors.street}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="housenumber"
                        label={t("common_house_number")+'*'}
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...formik.getFieldProps("housenumber")}
                        error={
                          formik.touched.housenumber &&
                          Boolean(formik.errors.housenumber)
                        }
                        helperText={
                          formik.touched.housenumber &&
                          formik.errors.housenumber
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="zip"
                        label={t("common_zip")+'*'}
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...formik.getFieldProps("zip")}
                        error={formik.touched.zip && Boolean(formik.errors.zip)}
                        helperText={formik.touched.zip && formik.errors.zip}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="city"
                        label={t("common_city")+'*'}
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...formik.getFieldProps("city")}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="countrycode"
                        label={t("common_country_code")+'*'}
                        variant="outlined"
                        className="countrycode-virtualScroller"
                        fullWidth
                        select
                        size="small"
                        value={formik.values.countrycode}
                        onChange={(e) => handleFieldChange(e.target.value)}
                        error={
                          formik.touched.countrycode &&
                          Boolean(formik.errors.countrycode)
                        }
                        helperText={
                          formik.touched.countrycode &&
                          formik.errors.countrycode
                        }
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: "300px",
                              },
                            },
                          },
                        }}
                      >
                        {country?.map((option) => (
                          <MenuItem key={option.id} value={option.code}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="taxcode"
                        label={t("tax_field")}
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        {...formik.getFieldProps("taxcode")}
                        value={formik.values.taxcode || ""}
                      >
                        {allTaxCode.length > 0 ?allTaxCode?.map((option) => (
                          <MenuItem key={option.id} value={option.code}>
                            {option.label}
                          </MenuItem>
                        )):(<MenuItem value="TAXFREE"> Steuerfrei </MenuItem>)}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="conditioncode"
                        label={t("common_condition_code")+'*'}
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        {...formik.getFieldProps("conditioncode")}
                        error={
                          formik.touched.conditioncode &&
                          Boolean(formik.errors.conditioncode)
                        }
                        helperText={
                          formik.touched.conditioncode &&
                          formik.errors.conditioncode
                        }
                      >
                        {condition?.map((option) => (
                          <MenuItem key={option.id} value={option.code}>
                            {t(option.label)}
                          </MenuItem>
                        ))}
                      </TextField>
                      </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="language"
                        label={t("common_language")+'*'}
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        {...formik.getFieldProps("language")}
                        error={
                          formik.touched.language &&
                          Boolean(formik.errors.language)
                        }
                        helperText={
                          formik.touched.language && formik.errors.language
                        }
                      >
                        {lang?.map((option) => (
                          <MenuItem key={option.id} value={option.code}>
                            {option.code}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item sm={6} xs={12}>
                    <TextField
                        id="currencycode"
                        label={t("common_currency_code")+'*'}
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        onChange={(e) => handleChangeCurrency(e)}
                        value={formik.values.currencycode}
                        error={
                          formik.touched.currencycode &&
                          Boolean(formik.errors.currencycode)
                        }
                        helperText={
                          formik.touched.currencycode &&
                          formik.errors.currencycode
                        }
                      >
                        {currency?.map((option) => (
                          <MenuItem key={option.id} value={option.code}>
                            {option.code}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="recipientId"
                        name="recipientId"
                        label={t("common_recipientId")}
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...formik.getFieldProps("recipientId")}
                        error={
                          formik.touched.recipientId &&
                          Boolean(formik.errors.recipientId)
                        }
                        helperText={
                          formik.touched.recipientId &&
                          formik.errors.recipientId
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="email"
                        label={t('Email')+'*'}
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...formik.getFieldProps("email")}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid  item xs={12} sx={{ height: "320px" }}>
                    <Grid item > 
                      <span className="commonTextLabel">
                        <p>{t("invoice_queue_template")}</p>
                        <div className="commonCreateButton">
                          <IconButton
                            onClick={handleClickTemplateModal}
                            sx={{ padding: "0px", margin: "0px" }}
                          >
                            <AddCircleOutlineIcon sx={{ color: "#8B9635" }} />
                          </IconButton>
                        </div>
                      </span>
                    </Grid> 
                    <Grid item   style={{ height: "250px" }}>
                        <CustomDataGrid
                          rows={templateItems}
                          getRowId={(row) => row.id}
                          columns={columnsTemplate}
                          rowHeight={33}
                          headerHeight={40}
                          pagination
                          rowsPerPageOptions={[10,20]}
                          paginationMode="server"
                          paginationComponent={Pagination}
                          scrollbarSize={0}
                          rowCount={templateItems.length}
                          disableColumnMenu
                          components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                    </Grid>
                  </Grid>
                </Grid>
                </CustomBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flex: "0 0 100%" }}>
                <span className="commonCreateLabel">
                  {t("common_list_address")}
                </span>
                <IconButton
                  onClick={getCheckLoadAddress}
                  sx={{ fontSize: "1em" ,p:'0px 8px'}}
                >
                  <CachedIcon sx={{ color: "#8B9635" }} />
                </IconButton>
                {isLastLoading && (
                  <span
                    style={{ alignSelf: "center" }}
                    className="lastUpdatedTimeLbl"
                  >
                    {t("queue_last_updatedTime")}:
                    {formatDate(lastUpdatedTimeTemplate)}
                  </span>
                )}
              </Box>
              <Item>
                <div className="actionSectionRight">
                  <div className="commonactionRight" style={{justifyContent:'space-between'}} >
                    <div className="commonActionItemCreateInvoice">
                      <IconButton  onClick={handleCopyAddress} disabled={!toggleIcon}>
                        {!toggleIcon ?(<ContentCopyIcon
                          sx={{ fontSize: 30, color: "#8F8C8C" }}
                        />):(<ContentCopyIcon
                          sx={{ fontSize: 30, color: "#8B9635" }}
                        />)}
                      </IconButton>
                      <span>{t("common_copy")}</span>
                    </div>
                    <div className="commonActionItemCreateInvoice" style={{alignSelf:'center'}}>
                      <FormGroup>
                        <FormControlLabel control={<CustomCheckbox  onChange={handleCheckBoxChange}/>} label={t("ebill_customers")} />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="searchInvoiceDiv">
                    <TextField
                      label={t("common_search")}
                      id="searchInvoices"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      onKeyDown={handleSearchKeyDown}
                      fullWidth
                      sx={{ m: 1, width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Item>
              {/* <div className="searchInvoiceDiv">
                <TextField
                  label={t("common_search")}
                  id="searchInvoices"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onKeyDown={handleSearchKeyDown}
                  sx={{ mb: 1, width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div> */}
              <div style={{ height: "755px", marginTop: "10px" }}>
                <CustomDataGrid
                  rows={addressData}
                  getRowId={(row) => row.cid}
                  columns={addressColumn}
                  rowHeight={33}
                  headerHeight={40}
                  pagination
                  rowsPerPageOptions={[10, 20]}
                  onPageChange={handlePageChange}
                  page={address.page-1}
                  getRowClassName={getRowClassName}
                  selectionModel={selectedRowIds}
                  onSortModelChange={handleSortModelChange}
                  onSelectionModelChange={handleaddressSelectionModelChange}
                  pageSize={address.limit}
                  loading={tableLoader}
                  onPageSizeChange={handlePageSizeChange}
                  paginationMode="server"
                  paginationComponent={Pagination}
                  rowCount={queueCount}
                  localeText={localeText}
                  scrollbarSize={0}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                  disableColumnMenu
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: t("common_rows_per_page"),
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Modal
        open={openTemplateModal}
        onClose={handleCloseTemplateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxTemplate>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseTemplateModal}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </div>
          <div style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("invoice_queue_template")}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  id="module"
                  label={t("common_module") + "*"}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  select
                  sx={{ margin: "10px 0 0px 0" }}
                  value={formikModal.values.module}
                  {...formikModal.getFieldProps("module")}
                  error={
                    formikModal.touched.module &&
                    Boolean(formikModal.errors.module)
                  }
                  helperText={
                    formikModal.touched.module && formikModal.errors.module
                  }
                >
                  {modules?.map((option) => (
                    <MenuItem key={option.id} value={option.code}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  fullWidth
                  sx={{ margin: "10px 0 0px 0" }}
                  value={formikModal.values.templatename}
                  onChange={(event, value) => {
                    formikModal.setFieldValue("templatename", value);
                  }}
                  options={templateData.map((options) => options.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("invoice_queue_template") + "*"}
                      onChange={handleKeyDown}
                      value={searchColumnTemplate}
                      onBlur={handleClearField}
                      error={
                        formikModal.touched.templatename &&
                        Boolean(formikModal.errors.templatename)
                      }
                      helperText={
                        formikModal.touched.templatename &&
                        formikModal.errors.templatename
                      }
                    />
                  )}
                  error={
                    formikModal.touched.templatename &&
                    Boolean(formikModal.errors.templatename)
                  }
                  helperText={
                    formikModal.touched.templatename &&
                    formikModal.errors.templatename
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <CustomBtn
                    onClick={handleCloseTemplateModal}
                    sx={{ mt: 2, mb: 1 }}
                  >
                    {t("common_cancel")}
                  </CustomBtn>
                  <CustomBtn onClick={formikModal.handleSubmit} sx={{ mt: 2, mb: 1, ml: 2 }}>
                    {t("common_submit")}
                  </CustomBtn>
                </div>
              </Grid>
            </Grid>
          </div>
        </CustomBoxTemplate>
      </Modal>

      <Modal
        open={openCreditModal}
        onClose={handleCloseCreditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxTemplate>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseCreditModal}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </div>
          <div style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("common_credit")}
            </Typography>
             <Grid container >
              <Grid item xs={12}>
                <TextField
                  id="credit"
                  label={t("common_credit")}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ margin: "10px 0 0px 0" }}
                  {...formikModalCredit.getFieldProps("credit")}
                  error={
                    formikModalCredit.touched.credit &&
                    Boolean(formikModalCredit.errors.credit)
                  }
                  helperText={
                    formikModalCredit.touched.credit &&
                    formikModalCredit.errors.credit
                  }
                />
                <InputLabel sx={{marginTop:'5px'}} >{t('common_module')}:</InputLabel>
                {modules.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    control={
                      <CustomCheckbox
                        name="selectedOptions"
                        type="checkbox"
                        value={option.code}
                        onChange={formikModalCredit.handleChange}
                        onBlur={formikModalCredit.handleBlur}
                        checked={formikModalCredit.values.selectedOptions.includes(option.code)}
                      />
                    }
                    label={option.code}
                  />))}
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <CustomBtn onClick={handleCloseCreditModal} sx={{ mt: 2 }}>
                      {t("common_cancel")}
                    </CustomBtn>
                    <CustomBtn onClick={formikModalCredit.handleSubmit} sx={{ mt: 2 }}>
                      {t("common_submit")}
                    </CustomBtn>
                  </div>
                </Grid>
            </Grid>
          </div>
        </CustomBoxTemplate>
      </Modal>
    </>
  );
}

export default Address;
