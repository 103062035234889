import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import "../Templates/template.scss";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Dialog from "@mui/material/Dialog";
import EditIcon from "@mui/icons-material/Edit";
// import MuiAlert from "@mui/material/Alert";
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import Modal from "@mui/material/Modal";
// import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
// import Slide from "@mui/material/Slide";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
// import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
// import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import IconButton from "@mui/material/IconButton";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { Pagination } from "@mui/material";
import {
  getLanguages,
  getModules,
  getConditions,
  createTemplate,
  loadTemplates,
  updateTemplates,
  deleteTemplates,
  getCountryTemplate,
  getTaxcode,
  getQrEsr,
  getIncomeAccounts,
  getExpenseAccounts,
  getBank,
} from "../../services/Services";
import Checkbox from "@mui/material/Checkbox";
import { createObject, encryptForm } from "../../services/commonService";
import { previewPdf } from "../../services/Services";
import { getEncryptedItem } from "../../services/localStorageUtil";
// import { Select, FormControl, InputLabel } from '@mui/material';
// import TextareaAutosize from '@mui/base/TextareaAutosize';
// import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
//   width: "275px",
//   "&.MuiSnackbar-root": {
//     top: "104px",
//   },
// }));

const CustomLoader = styled(Loader)(({ theme }) => ({
  loader: {
    width: "31px",
    height: "31px",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
}));

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "20px",
  background: "unset",
  border: "2px solid #8B9635",
  padding: "4px 10px",
  margin: "10px 0 10px 0px",
  color: theme.palette.mode === "light" ? "#000" : "#fff",
  "&:hover": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "#000",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));

const CustomBoxDelete = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "4%",
  width: 400,
  textAlign: "center",
  backgroundColor: theme.palette.mode === "light" ? "white" : "black"
}));

const CustomBoxHelp = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "4%",
  width: 450,
  textAlign: "center",
  backgroundColor: theme.palette.mode === "light" ? "white" : "black",
}));

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
  "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#8B9635",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "unset",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "unset",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#8B9635",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    outline: "none",
    border: "none",
  },
}));

const marginTopValue = '104px';
const defaultTemplateParams = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  search:''
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  button: {
    color: "#8B9635",
  },
}));

function Templates() {
  const { t, i18n } = useTranslation(["language", "common"]);
  const [lang, setLanguage] = useState([]);
  const [modules, setModules] = useState([]);
  const [condition, setConditions] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [setTemplateItem, setTemplateData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [country, setCountry] = useState([]);
  const [qrEsrIban, setqrEsrIban] = useState([]);
  const [bank, setBank] = useState([]);
  const [updateBank, setUpdateBankBank] = useState([]);
  const [incomeAccount, setincomeAccount] = useState([]);
  const [expenseAccount, setexpenseAccount] = useState([]);
  const [templateCount, setTemplateCount] = React.useState(0);
  const [toggleIcon, setToggleBtn] = React.useState(false);
  const [toggleMode, setToggleMode] = React.useState(false);
  const [storeSelected, setDataToStore] = React.useState({});
  const [pdfModalOpen, setPDFModalOpen] = useState(false);
  const [isLoaderOverlay, setIsLoaderOverlay] = useState(false);
  const [isConfirmSubmit, setIsConfirmSubmit] = useState(false);
  const [allTaxCode, setAllTaxCode] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [pdfData, setPDFData] = useState(null);
  const [qrAccount, setQrAccount] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const [params, setTemplateParams] = useState(defaultTemplateParams);
  const [openContryConditionModal, setOpenContryConditionModal] = useState(false);
  const handleOpenContryConditionModal = () =>setOpenContryConditionModal(true);
  const handleCloseContryConditionModal = () =>setOpenContryConditionModal(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isOtherQr, setIsOtherQR] = useState(false);
  const [openDialogBookingtext, setOpenDialogBookingtext] = useState(false);
  const [openDialogHeader, setOpenDialogHeader] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const handleCloseContryModal = () => {
    setIsLoaderOverlay(false);
    handleCloseContryConditionModal();
  };

  const handleClickOpenHeader = () => {
    setOpenDialogHeader(true);
  };

  const handleCloseHeader = () => {
    setOpenDialogHeader(false);
  };

  const handleClickOpenBookingtext = () => {
    setOpenDialogBookingtext(true);
  };

  const handleCloseBookingtext = () => {
    setOpenDialogBookingtext(false);
  };

  useEffect(() => {
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
      return()=>{
        closeAlertSnackBar();
      }
  }, []);

  const handleCancelContryModel = () => {
    setIsLoaderOverlay(false);
    handleClear();
    handleCloseContryModal();
  };
  const initialValues = {
    name: "",
    language: "",
    bookingtext: "",
    active: false,
    moduleId: "",
    condition: "",
    tax_field: "",
    countrycode: "",
    income_account: "",
    expense_account: "",
    our_reference: "",
    qr_esr_iban: "",
    bank:"",
    header: "",
    footer: "",
  };
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const currentLanguage = i18n.language;
  
  const columns = [
    { field: "name", headerName: t("template_description"), flex: 1.5 },
    {
      field: "createddate",
      headerName: t("template_created_date"),
      flex: 1.5,
      valueFormatter:
        currentLanguage === "en"
          ? (params) =>
              params.value !== ""
                ? new Date(params.value).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : params.value
          : (params) =>
              params.value !== ""
                ? new Date(params.value).toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : params.value,
    },
    {
      field: "our_reference",
      headerName: t("common_our_reference"),
      flex: 1.5,
    },
    { field: "language", headerName: t("common_language"), flex: 1 },
    { field: "country", headerName: t("common_country_code"), flex: 1 },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("common_validate_template")),
    language: Yup.string().required(t("common_validate_language")),
    // bookingtext: Yup.string().required(t("common_validate_booking_text")),
    moduleId: Yup.string().required(t("common_validate_module")),
    condition: Yup.string().required(t("common_validate_condition")),
    countrycode: Yup.string().required(t("common_validate_countrycode")),
    // income_account: Yup.string().required(t("common_validate_expense_account")),
    // expense_account: Yup.string().required(t("common_validate_income_account")),
    // our_reference: Yup.string().required(t("common_validate_our_reference")),
    qr_esr_iban: Yup.string().required(t("common_validate_qr_esr_iban")),
    bank: Yup.string().test('check-bank-validation', t("common_validate_bank"), function(value) {
      const QrEsr = this.parent.qr_esr_iban; 
      if ((QrEsr === 'METHOD_LESS')||(value)) {
        return true; 
      }
      return false; 
    })
  });

  const handleSubmitCountry = () => {
    setIsConfirmSubmit(true);
    formik.handleSubmit();
  };

  const resetAddressFrom = ()=>{
    formik.resetForm();
    setTaxes(allTaxCode);
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsLoaderOverlay(true);
      const filterCondition = condition.filter(
        (obj) => obj.label === values.condition
      );
      let conditionid = filterCondition[0].id;

      if (!toggleIcon) {
        if (values.countrycode === "OTHERS" && values.qr_esr_iban === "QR") {
          handleOpenContryConditionModal();
          if (isConfirmSubmit) {
            handleCloseContryModal();
            const templateForm = new FormData();
            templateForm.append("name", values.name);
            templateForm.append("header", values.header ? values.header : "");
            templateForm.append("footer", values.footer ? values.footer : "");
            const filteredObject = country.filter(
              (obj) => obj.code === values.countrycode
            );
            templateForm.append("country", filteredObject[0].id);
            const filteredLang = lang.filter(
              (obj) => obj.code === values.language
            );
            const filteredModule = modules.filter(
              (obj) => obj.code === values.moduleId
            );
            templateForm.append("moduleId", filteredModule[0].id);
            templateForm.append("conditionId", conditionid);
            templateForm.append("language", filteredLang[0].id);
            templateForm.append("active", values.active);
            templateForm.append("userName", userDetails.username?userDetails.username:"");
            const jsonObj = {};
            jsonObj["language"] = values.language;
            jsonObj["country"] = values.countrycode;
            jsonObj["tax_field"] = values.tax_field;
            jsonObj["bookingtext"] = values.bookingtext;
            jsonObj["our_reference"] = values.our_reference;
            jsonObj["condition"] = values.condition;
            jsonObj["income_account"] = values.income_account;
            jsonObj["expense_account"] = values.expense_account;
            jsonObj["header"] = values.header ? values.header : "";
            jsonObj["footer"] = values.footer ? values.footer : "";
            jsonObj["qr_esr_iban"] = values.qr_esr_iban;
            jsonObj["banktype"] = values.bank;
            templateForm.append("templateJson", JSON.stringify(jsonObj));
            let object = createObject(templateForm);
            let encryptedToken = encryptForm(object);
            let encrData = new FormData();
            encrData.append("encrToken", encryptedToken);
            setIsConfirmSubmit(false);
            createTemplate(encrData)
              .then((data) => {
                setIsLoaderOverlay(false);
                if (data.status) {
                  resetAddressFrom();
                  setSelectedRowIds([]);
                  setToggleMode(false);
                  setToggleBtn(false);
                  loadTemplateTable();
                  openAlertSnackBar('success',data.message,marginTopValue);
                } else {
                  openAlertSnackBar('error',data.message,marginTopValue);
                }
              })
              .catch((err) => {
                setIsLoaderOverlay(false);
                openAlertSnackBar('error','common_alert',marginTopValue);
              });
          }
        } else {
          const templateForm = new FormData();
          templateForm.append("name", values.name);
          templateForm.append("header", values.header ? values.header : "");
          templateForm.append("footer", values.footer ? values.footer : "");
          const filteredObject = country.filter(
            (obj) => obj.code === values.countrycode
          );
          templateForm.append("country", filteredObject[0].id);
          const filteredLang = lang.filter(
            (obj) => obj.code === values.language
          );
          const filteredModule = modules.filter(
            (obj) => obj.code === values.moduleId
          );
          templateForm.append("moduleId", filteredModule[0].id);
          templateForm.append("language", filteredLang[0].id);
          templateForm.append("conditionId", conditionid);
          templateForm.append("active", values.active);
          templateForm.append("userName", userDetails.username?userDetails.username:"");
          const jsonObj = {};
          jsonObj["language"] = values.language;
          jsonObj["country"] = values.countrycode;
          jsonObj["tax_field"] = values.tax_field;
          jsonObj["bookingtext"] = values.bookingtext;
          jsonObj["our_reference"] = values.our_reference;
          jsonObj["condition"] = values.condition;
          jsonObj["income_account"] = values.income_account;
          jsonObj["expense_account"] = values.expense_account;
          jsonObj["header"] = values.header ? values.header : "";
          jsonObj["footer"] = values.footer ? values.footer : "";
          jsonObj["qr_esr_iban"] = values.qr_esr_iban;
          jsonObj["banktype"] = values.bank;
          templateForm.append("templateJson", JSON.stringify(jsonObj));
          let object = createObject(templateForm);
          let encryptedToken = encryptForm(object);
          let encrData = new FormData();
          encrData.append("encrToken", encryptedToken);
          createTemplate(encrData)
            .then((data) => {
              setIsLoaderOverlay(false);
              if (data.status) {
                resetAddressFrom();
                setSelectedRowIds([]);
                setToggleMode(false);
                setToggleBtn(false);
                loadTemplateTable();
                openAlertSnackBar('success',data.message,marginTopValue);
              } else {
                openAlertSnackBar('error',data.message,marginTopValue);
              }
            })
            .catch((err) => {
              setIsLoaderOverlay(false);
              openAlertSnackBar('error','common_alert',marginTopValue);
            });
        }
      } else {
        if (
          values.countrycode === "OTHERS" &&
          values.qr_esr_iban === "QR" &&
          !isOtherQr
        ) {
          handleOpenContryConditionModal();
          if (isConfirmSubmit) {
            handleCloseContryModal();
            const templateUpdateForm = new FormData();
            templateUpdateForm.append("templateId", selectedRowIds[0]);
            templateUpdateForm.append("name", values.name);
            templateUpdateForm.append(
              "header",
              values.header ? values.header : ""
            );
            templateUpdateForm.append(
              "footer",
              values.footer ? values.footer : ""
            );
            const filteredObject = country.filter(
              (obj) => obj.code === values.countrycode
            );
            templateUpdateForm.append("country", filteredObject[0].id);
            const filteredLang = lang.filter(
              (obj) => obj.code === values.language
            );
            const filteredModule = modules.filter(
              (obj) => obj.code === values.moduleId
            );
            templateUpdateForm.append("moduleId", filteredModule[0].id);
            templateUpdateForm.append("language", filteredLang[0].id);
            templateUpdateForm.append("conditionId", conditionid);
            templateUpdateForm.append("active", values.active);
            templateUpdateForm.append("userName", userDetails.username?userDetails.username:"");
            const jsonObj = {};
            jsonObj["language"] = values.language;
            jsonObj["country"] = values.countrycode;
            jsonObj["tax_field"] = values.tax_field;
            jsonObj["bookingtext"] = values.bookingtext;
            jsonObj["our_reference"] = values.our_reference;
            jsonObj["condition"] = values.condition;
            jsonObj["income_account"] = values.income_account;
            jsonObj["expense_account"] = values.expense_account;
            jsonObj["header"] = values.header ? values.header : "";
            jsonObj["footer"] = values.footer ? values.footer : "";
            jsonObj["qr_esr_iban"] = values.qr_esr_iban;
            jsonObj["banktype"] = values.bank;
            templateUpdateForm.append("templateJson", JSON.stringify(jsonObj));
            let object = createObject(templateUpdateForm);
            let encryptedToken = encryptForm(object);
            let encrData = new FormData();
            encrData.append("encrToken", encryptedToken);
            setIsConfirmSubmit(false);
            updateTemplates(encrData)
              .then((data) => {
                setIsLoaderOverlay(false);
                if (data.status) {
                  resetAddressFrom();
                  setSelectedRowIds([]);
                  setToggleMode(false);
                  setToggleBtn(false);
                  loadTemplateTable();
                  openAlertSnackBar('success',data.message,marginTopValue);
                } else {
                  openAlertSnackBar('error',data.message,marginTopValue);
                }
              })
              .catch((err) => {
                setIsLoaderOverlay(false);
                openAlertSnackBar('error','common_alert',marginTopValue);
              });
          }
        } else {
          const templateUpdateForm = new FormData();
          templateUpdateForm.append("templateId", selectedRowIds[0]);
          templateUpdateForm.append("name", values.name);
          templateUpdateForm.append(
            "header",
            values.header ? values.header : ""
          );
          templateUpdateForm.append(
            "footer",
            values.footer ? values.footer : ""
          );
          const filteredObject = country.filter(
            (obj) => obj.code === values.countrycode
          );
          templateUpdateForm.append("country", filteredObject[0].id);
          const filteredLang = lang.filter(
            (obj) => obj.code === values.language
          );
          const filteredModule = modules.filter(
            (obj) => obj.code === values.moduleId
          );
          templateUpdateForm.append("userName", userDetails.username?userDetails.username:"");
          templateUpdateForm.append("moduleId", filteredModule[0].id);
          templateUpdateForm.append("conditionId", conditionid);
          templateUpdateForm.append("language", filteredLang[0].id);
          templateUpdateForm.append("active", values.active);
          const jsonObj = {};
          jsonObj["language"] = values.language;
          jsonObj["country"] = values.countrycode;
          jsonObj["tax_field"] = values.tax_field;
          jsonObj["bookingtext"] = values.bookingtext;
          jsonObj["our_reference"] = values.our_reference;
          jsonObj["condition"] = values.condition;
          jsonObj["income_account"] = values.income_account;
          jsonObj["expense_account"] = values.expense_account;
          jsonObj["header"] = values.header ? values.header : "";
          jsonObj["footer"] = values.footer ? values.footer : "";
          jsonObj["qr_esr_iban"] = values.qr_esr_iban;
          jsonObj["banktype"] = values.bank;
          templateUpdateForm.append("templateJson", JSON.stringify(jsonObj));
          let object = createObject(templateUpdateForm);
          let encryptedToken = encryptForm(object);
          let encrData = new FormData();
          encrData.append("encrToken", encryptedToken);
          updateTemplates(encrData)
            .then((data) => {
              setIsLoaderOverlay(false);
              if (data.status) {
                resetAddressFrom();
                setSelectedRowIds([]);
                setToggleMode(false);
                setToggleBtn(false);
                loadTemplateTable();
                openAlertSnackBar('success',data.message,marginTopValue);
              } else {
                openAlertSnackBar('error',data.message,marginTopValue);
              }
            })
            .catch((err) => {
              setIsLoaderOverlay(false);
              openAlertSnackBar('error','common_alert',marginTopValue);
            });
        }
      }
    },
  });

  // useEffect(()=>{
  //   if(incomeAccount.length===1)
  //   { 
  //     if(!formik.dirty){
  //       formik.setFieldValue("income_account",incomeAccount[0].code);
  //     }
  //   }
  // },[incomeAccount,formik])
  
  const handleQrEsrChange = (event) => {
    formik.setFieldValue("qr_esr_iban",event.target.value);
    setQrAccount(null);
    formik.setFieldValue("bank","");
  }
  const handleFieldChange = (event) => {
    const fieldValue = event.target.value;
    formik.setFieldValue("countrycode", fieldValue);
    
    if (fieldValue === 'CH/LI') {
      
      formik.setFieldValue('tax_field', 'SSVR81');
      let selectedTaxes = allTaxCode.filter((tax)=>tax.code !=='TAXFREE' )
      setTaxes(selectedTaxes);
    } 
    else {
      formik.setFieldValue('tax_field', 'TAXFREE');
      let selectedTaxes = allTaxCode.filter((tax)=>tax.code ==='TAXFREE' )
      setTaxes(selectedTaxes);
    }
    
  };

  useEffect(() => {
    const controller1 = new AbortController();
    const signal1 = controller1.signal;
    const controller2 = new AbortController();
    const signal2 = controller2.signal;
    const controller3 = new AbortController();
    const signal3 = controller3.signal;
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const controller5 = new AbortController();
    const signal5 = controller5.signal;
    const controller6 = new AbortController();
    const signal6 = controller6.signal;
    const controller7 = new AbortController();
    const signal7 = controller7.signal;

    getLanguages(signal1)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setLanguage(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller1.abort();
      });
    getModules(signal2)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setModules(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller2.abort();
      });
    getConditions(signal3)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setConditions(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller3.abort();
      });
    getCountryTemplate(signal4)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setCountry(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller4.abort();
      });
    getTaxcode(signal5)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setAllTaxCode(data.data);
          setTaxes(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller5.abort();
      });
    getQrEsr(signal6)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setqrEsrIban(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller6.abort();
      });
    getBank(signal7)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setBank(data.data);
          setUpdateBankBank(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller7.abort();
      });

   
    return () => {
      controller1.abort();
      controller2.abort();
      controller3.abort();
      controller4.abort();
      controller5.abort();
      controller6.abort();
      controller7.abort();
      setIsMounted(false);
    };
  }, [isMounted]);

  useEffect(()=>{
    const controller7 = new AbortController();
    const signal7 = controller7.signal;
    const controller8 = new AbortController();
    const signal8 = controller8.signal;

    let incForm = new FormData();
    incForm.append("language", currentLanguage);
    let objectinc = createObject(incForm);
    let encryptedTokeninc = encryptForm(objectinc);
    let encrDatainc = new FormData();
    encrDatainc.append("encrToken", encryptedTokeninc);
    getIncomeAccounts(encrDatainc, signal7)
      .then((data) => {
        if (isMounted && data && data.status && data.data && data.data.length > 0) {
          setincomeAccount(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller7.abort();
      });

    let expForm = new FormData();
    expForm.append("language", currentLanguage);
    let object = createObject(expForm);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    getExpenseAccounts(encrData, signal8)
      .then((data) => {
        if ( isMounted && data && data.status && data.data && data.data.length > 0) {
          setexpenseAccount(data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        controller8.abort();
      });
    return () => {
      controller7.abort();
      controller8.abort();
    };
  },[currentLanguage,isMounted])

  const loadTemplateTable = useCallback(() => {
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData();
    d.append("limit", params.limit);
    d.append("page", params.page);
    d.append("column", params.column);
    d.append("order", params.order);
    d.append("search", params.search);
    setTableLoader(true);
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadTemplates(encrData, signal4)
      .then((res) => {
        if(isMounted ){
        setTableLoader(false);
        if (res.status) {
          setTemplateData(res.data);
          if(res.data.length>0)
          {
            setTemplateCount(Number(res.filtered));
          }else
          {
            setTemplateCount(0);
          }
        } else {
          openAlertSnackBar('error',res.message,marginTopValue);
        }
       }
      })
      .catch((err) => {
        if(isMounted ){
        setTableLoader(false);
        openAlertSnackBar('error','common_alert',marginTopValue);
        }
      })
      .finally(() => {
        controller4.abort();
      });

    return () => {
      controller4.abort();
    };
  }, [params,isMounted]);

  useEffect(()=>{
    if(formik.values.bank)
    {
      const bankAccount = bank.filter((item)=>item.code===formik.values.bank)[0]['bankConfig']['bankAccount'];
      setQrAccount(bankAccount);
    }else{
      setQrAccount(null);
    }
  },[formik.values.bank,bank])

  useEffect(() => {
    loadTemplateTable();
  }, [loadTemplateTable]);

  const deletetemplate = () => {
    const deleteTemplateForm = new FormData();
    handleCloseDeleteModal();
    deleteTemplateForm.append("templateId", selectedRowIds[0]);
    deleteTemplateForm.append("userName", userDetails.username?userDetails.username:"");
    let object = createObject(deleteTemplateForm);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    deleteTemplates(encrData)
      .then((res) => {
        if (res.status) {
          resetAddressFrom();
          loadTemplateTable();
          setSelectedRowIds([]);
          setToggleMode(false);
          setToggleBtn(false);
          openAlertSnackBar('success',res.message,marginTopValue);
        } else {
          openAlertSnackBar('error',res.message,marginTopValue);
        }
      })
      .catch((err) => {
        openAlertSnackBar('error','common_alert',marginTopValue);
      });
  };
  function CustomLoadingOverlay() {
    return <Loader />;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setTemplateParams((previousParams)=>({
        ...previousParams,
        search:event.target.value,
        page:1
      }))
    }
  };
  const handleSelectionModelChange = (newSelection) => {
    if (newSelection.length === 0) {
      setSelectedRowIds([]);
      setDataToStore(initialValues);
      resetAddressFrom();
      setIsDelete(false);
      setToggleMode(false);
      setToggleBtn(false);
      return;
    }
    if (selectedRowIds[0] === newSelection[0]) {
      setSelectedRowIds([]);
      setDataToStore(initialValues);
      resetAddressFrom();
      setIsDelete(false);
      setToggleMode(false);
      setToggleBtn(false);
    } else {
      setIsDelete(true);
      setSelectedRowIds(newSelection);
      setToggleBtn(true);
      setToggleMode(true);
      const filteredObject = setTemplateItem.filter(
        (obj) => obj.cid === newSelection[newSelection.length - 1]
      );
      if (filteredObject.length !== 0) {
        resetAddressFrom();
        const filterModule = modules.filter(
          (obj) => obj.id === filteredObject[0].moduleid
        );

        const filteredBankObject = bank.filter(
          (obj) => obj.code === filteredObject[0].banktype
        );

        const filterCondition = condition.filter(
          (obj) => obj.label === filteredObject[0].condition
        );

        if((filteredObject.length !== 0) && (filteredObject[0]?.country))
        {
          if (filteredObject[0].country === 'CH/LI') {
            let selectedTaxes = allTaxCode.filter((tax)=>tax.code !=='TAXFREE' )
            setTaxes(selectedTaxes);
          } 
          else {
            let selectedTaxes = allTaxCode.filter((tax)=>tax.code ==='TAXFREE' )
            setTaxes(selectedTaxes);
          }
        }
        
        // setTaxes(allTaxCode);
        const Obj = {
          name:
            filteredObject.length !== 0
              ? filteredObject[0].name
                ? filteredObject[0].name
                : ""
              : "",
          language:
            filteredObject.length !== 0
              ? filteredObject[0].language
                ? filteredObject[0].language
                : ""
              : "",
          countrycode:
            filteredObject.length !== 0
              ? filteredObject[0].country
                ? filteredObject[0].country
                : ""
              : "",
          tax_field:
            filteredObject.length !== 0
              ? filteredObject[0].tax_field
                ? filteredObject[0].tax_field
                : ""
              : "",
          bookingtext:
            filteredObject.length !== 0
              ? filteredObject[0].bookingtext
                ? filteredObject[0].bookingtext
                : ""
              : "",
          active:
            filteredObject.length !== 0
              ? filteredObject[0].active === true
                ? true
                : false
              : false,
          moduleId: filterModule.length
            ? filterModule[0].code
              ? filterModule[0].code
              : ""
            : "",
          condition: filterCondition.length
            ? filterCondition[0].label
              ? filterCondition[0].label
              : ""
            : "",
          income_account:
            filteredObject.length !== 0
              ? filteredObject[0].income_account
                ? filteredObject[0].income_account
                : ""
              : "",
          expense_account:
            filteredObject.length !== 0
              ? filteredObject[0].expense_account
                ? filteredObject[0].expense_account
                : ""
              : "",
          our_reference:
            filteredObject.length !== 0
              ? filteredObject[0].our_reference
                ? filteredObject[0].our_reference
                : ""
              : "",
          bank:
            filteredObject.length !== 0
              ? filteredBankObject[0]?.code
                ? filteredBankObject[0].code
                : ""
              : "",
          qr_esr_iban:
            filteredObject.length !== 0
              ? filteredObject[0].qr_esr_iban
                ? filteredObject[0].qr_esr_iban
                : ""
              : "",
          header: filteredObject[0].header
            ? filteredObject[0].header
              ? filteredObject[0].header
              : ""
            : "",
          footer: filteredObject[0].footer
            ? filteredObject[0].footer
              ? filteredObject[0].footer
              : ""
            : "",
        };
        if (Obj.countrycode === "OTHERS" && Obj.qr_esr_iban === "QR") {
          setIsOtherQR(true);
        } else {
          setIsOtherQR(false);
        }
        formik.setValues(Obj);
        setDataToStore(Obj);
      }
    }
  };

  const handleOpenPDFModal = () => {
    if (selectedRowIds.length > 0) {
      const _formData = new FormData();
      _formData.append("id", selectedRowIds[0]);
      let object = createObject(_formData);
      let encryptedToken = encryptForm(object);
      setIsLoaderOverlay(true);
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      previewPdf(encrData)
        .then((res) => {
          setIsLoaderOverlay(false);
          if (res.status) {
            setPDFData(res.data);
            setPDFModalOpen(true);
          } else {
            openAlertSnackBar('error',res.message,marginTopValue);
          }
        })
        .catch((err) => {
          setIsLoaderOverlay(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        });
    }
  };

  const handleClosePDFModal = () => {
    setPDFData(null);
    setPDFModalOpen(false);
  };

  const handleDelete = () => {
    if (isDelete) {
      handleOpenDeleteModal();
    }
  };

  function CustomNoRowsOverlay() {
    const { t } = useTranslation(["language", "common"]);
    return (
      <GridOverlay>
        <Typography>{t("common_no_data_available")}</Typography>
      </GridOverlay>
    );
  }

  const lastSelectedRowIndex =
    selectedRowIds.length > 0
      ? selectedRowIds[selectedRowIds.length - 1]
      : null;

  const getRowClassName = useCallback(
    (params) => {
      if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
        return "commonSelected";
      }
      if (lastSelectedRowIndex === null) {
        return "commonSelected";
      }
      return "";
    },
    [lastSelectedRowIndex]
  );

  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setTemplateParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setTemplateParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };

  const handlePageChange = (event, newPage) => {
    setTemplateParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const localeText = {
    noRowsLabel: t("common_no_rows"),
    footerRowSelected: (count) => `${count} ${t("common_row_selected")}`,
  };

  const handlePageSizeChange = (event) => {
    setTemplateParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };

  const handleClear = () => {
    setSelectedRowIds([]);
    setDataToStore(initialValues);
    resetAddressFrom();
    setIsDelete(false);
    setToggleMode(false);
    setToggleBtn(false);
    setTaxes(allTaxCode);
  };

  useEffect(()=>{
    if(formik.values.qr_esr_iban)
    {
      let filterdBankValues = bank.filter((details)=>details.code.includes(formik.values.qr_esr_iban));
      setUpdateBankBank(filterdBankValues);
    }else{
      setUpdateBankBank(bank);
    }
  },[formik.values.qr_esr_iban,bank]);

  const handleCancel = () => {
    if(selectedRowIds.length === 0){return}
    if(JSON.stringify(storeSelected) !== JSON.stringify(initialValues)){
      formik.setValues(storeSelected);
    }else{
      resetAddressFrom();
    }
  };

  const copyTempalte = () => {
    formik.setFieldValue("language", "");
    formik.setFieldValue("countrycode", "");
    formik.setFieldValue("condition", "");
    setSelectedRowIds([]);
    setToggleMode(false);
    setToggleBtn(false);
  };

  return (
    <>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, margin: "10px 0 0 0" }}>
        <Grid container spacing={2}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <span className="commonCreateLabel">{t("common_template")}</span>
              <Item >
                <div className="actionSection">
                  <div className="commonActionItem">
                    <IconButton onClick={handleClear}>
                      <AddOutlinedIcon
                        sx={{ fontSize: 30, color: "#8B9635" }}
                      />
                    </IconButton>
                    <span>{t("common_new")}</span>
                  </div>
                  <div className="commonActionItem">
                    {!toggleIcon ? (
                      <>
                        <IconButton onClick={formik.handleSubmit}>
                          <SaveSharpIcon
                            sx={{ fontSize: 30, color: "#8B9635" }}
                          />
                        </IconButton>
                        <span>{t("common_save")}</span>
                      </>
                    ) : (
                      <>
                        <IconButton onClick={formik.handleSubmit}>
                          <EditIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                        </IconButton>
                        <span>{t("common_update")}</span>
                      </>
                    )}
                  </div>
                  <div className="commonActionItem">
                    <IconButton onClick={handleCancel}>
                      <CloseSharpIcon sx={{ fontSize: 30, color: "#ff0000" }} />
                    </IconButton>
                    <span>{t("common_cancel")}</span>
                  </div>
                  <span
                    style={{ border: "1px solid", borderColor: "#8B9635" }}
                  ></span>
                  <div className="commonActionItem">
                    <IconButton onClick={handleOpenPDFModal}>
                      <RemoveRedEyeSharpIcon
                        sx={{ fontSize: 30, color: "#8B9635" }}
                      />
                    </IconButton>
                    <span>{t("common_preview")}</span>
                  </div>
                  <Box
                    className="commonActionItemEnd"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {isLoaderOverlay && <CustomLoader />}
                  </Box>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <CustomBox
                className="leftSection"
                sx={{
                  minHeight: "725px",
                  height: "auto",
                }}
              >
                <Grid item container  spacing={1}>
                {!toggleMode ? (
                  <Grid item xs={12}>
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                      <span style={{ color: "#8B9635" }}>
                        {t("template_new_mode")}
                      </span>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                      <span style={{ color: "#8B9635" }}>
                        {t("template_edit_mode")}
                      </span>
                    </Box>
                  </Grid>
                )}
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={7} sm={9} lg={8} xl={9}>
                    <TextField
                      id="name"
                      label={t("template_name")+'*'}
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...formik.getFieldProps("name")}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    ></TextField>
                  </Grid>
                  <Grid item xs={5} sm={3} lg={4} xl={3}>
                      <label style={{ display: "flex" }}>
                        <span
                          style={{
                            display: "flex",
                            alignSelf: "center",
                          }}
                        >
                          {t("template_aktivieren")}
                        </span>
                        <Checkbox
                          sx={{ fontSize: 30 }}
                          name="active"
                          checked={formik.values.active}
                          onChange={formik.handleChange}
                        />
                      </label>
                      {formik.errors.active && (
                        <div>{formik.errors.active}</div>
                      )}
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="language"
                      label={t("common_language")+'*'}
                      variant="outlined"
                      fullWidth
                      select
                      size="small"
                      {...formik.getFieldProps("language")}
                      error={
                        formik.touched.language &&
                        Boolean(formik.errors.language)
                      }
                      helperText={
                        formik.touched.language && formik.errors.language
                      }
                    >
                      {lang?.map((option) => (
                        <MenuItem key={option.id} value={option.code}>
                          {option.code}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="moduleId"
                      label={t("common_module")+'*'}
                      variant="outlined"
                      fullWidth
                      select
                      size="small"
                      {...formik.getFieldProps("moduleId")}
                      error={
                        formik.touched.moduleId &&
                        Boolean(formik.errors.moduleId)
                      }
                      helperText={
                        formik.touched.moduleId && formik.errors.moduleId
                      }
                    >
                      {modules?.map((option) => (
                        <MenuItem key={option.id} value={option.code}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1} >
                  <Grid item  xs={11} >
                    <TextField
                      id="bookingtext"
                      label={t("booking_text")}
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...formik.getFieldProps("bookingtext")}
                      // error={
                      //   formik.touched.bookingtext &&
                      //   Boolean(formik.errors.bookingtext)
                      // }
                      // helperText={
                      //   formik.touched.bookingtext && formik.errors.bookingtext
                      // }
                    />
                  </Grid>
                  <Grid item xs={1} >
                    <IconButton
                      onClick={handleClickOpenBookingtext}
                      title={t("help")}
                    >
                      <InfoIcon sx={{ fontSize: 25, color: "#8B9635" }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="our_reference"
                      label={t("common_our_reference")}
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...formik.getFieldProps("our_reference")}
                      // error={
                      //   formik.touched.our_reference &&
                      //   Boolean(formik.errors.our_reference)
                      // }
                      // helperText={
                      //   formik.touched.our_reference &&
                      //   formik.errors.our_reference
                      // }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="condition"
                      label={t("condition")+'*'}
                      variant="outlined"
                      fullWidth
                      select
                      size="small"
                      {...formik.getFieldProps("condition")}
                      error={
                        formik.touched.condition &&
                        Boolean(formik.errors.condition)
                      }
                      helperText={
                        formik.touched.condition && formik.errors.condition
                      }
                    >
                      {condition?.map((option) => (
                        <MenuItem key={option.id} value={option.label}>
                          {t(option.label)}
                        </MenuItem>
                      ))}
                    </TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="countrycode"
                      label={t("common_country_code")+'*'}
                      variant="outlined"
                      fullWidth
                      select
                      size="small"
                      value={formik.values.countrycode}
                      onChange={(e) => handleFieldChange(e)}
                      error={
                        formik.touched.countrycode &&
                        Boolean(formik.errors.countrycode)
                      }
                      helperText={
                        formik.touched.countrycode && formik.errors.countrycode
                      }
                    >
                      {country?.map((option) => (
                        <MenuItem key={option.id} value={option.code}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="tax_field"
                      label={t("tax_field")}
                      variant="outlined"
                      fullWidth
                      select
                      size="small"
                      {...formik.getFieldProps("tax_field")}
                      value={formik.values.tax_field || ""}
                      error={
                        formik.touched.tax_field &&
                        Boolean(formik.errors.tax_field)
                      }
                      helperText={
                        formik.touched.tax_field && formik.errors.tax_field
                      }
                    >
                      {taxes.length >0 ? taxes?.map((option) => (
                        <MenuItem key={option.id} value={option.code}>
                          {option.label}
                        </MenuItem>
                      )) :  (<MenuItem value=""> </MenuItem>)}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        id="qr_esr_iban"
                        label={t("common_qr_esr_iban")+'*'}
                        variant="outlined"
                        fullWidth
                        select
                        value={formik.values.qr_esr_iban}
                        size="small"
                        onChange={handleQrEsrChange}
                        error={
                          formik.touched.qr_esr_iban &&
                          Boolean(formik.errors.qr_esr_iban)
                        }
                        helperText={
                          formik.touched.qr_esr_iban && formik.errors.qr_esr_iban
                        }
                      >
                        {qrEsrIban?.map((option) => (
                          <MenuItem key={option.id} value={option.code}>
                            {t(option.label)}
                          </MenuItem>
                        ))}
                    </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                          id="bank"
                          label={updateBank.length > 0 ? t("Bank")+'*': t("Bank")}
                          variant="outlined"
                          fullWidth
                          select
                          size="small"
                          {...formik.getFieldProps('bank')}
                          error={
                            formik.touched.bank &&
                            Boolean(formik.errors.bank)
                          }
                          helperText={
                            formik.touched.bank && formik.errors.bank
                          }
                        >
                        {updateBank?.map((option) => (
                          <MenuItem key={option.cid} value={option.code}>
                            {option?.bankConfig.bankName +' - '+option?.bankConfig.currency}
                          </MenuItem>
                        ))}
                      </TextField>
                      <p style={{color:"#8B9635",margin:'4px 0px 0px 13px'}}>{qrAccount}</p>
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1} sx={{alignItems:'center'}}>
                  <Grid item xs={12} xl={2.5} lg={4} sm={12}>
                    <span>
                      {t("verbuchung_buHa")}
                    </span>
                  </Grid>
                  <Grid item xs={12} xl={4.75} lg={4}  sm={6}>
                    <TextField
                      id="income_account"
                      label={t("SollKonto")}
                      variant="outlined"
                      fullWidth
                      select
                      size="small"
                      {...formik.getFieldProps("income_account")}
                      // error={
                      //   formik.touched.income_account &&
                      //   Boolean(formik.errors.income_account)
                      // }
                      // helperText={
                      //   formik.touched.income_account &&
                      //   formik.errors.income_account
                      // }
                    >
                      {incomeAccount.length > 0 ? incomeAccount?.map((option) => (
                        <MenuItem key={option.id} value={option.code}>
                          {option.accountid +" | " + option.label}
                        </MenuItem>
                      )): (<MenuItem value=""> </MenuItem>)}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} xl={4.75} lg={4}  sm={6}>
                    <TextField
                      id="expense_account"
                      label={t("ertragskonto")}
                      variant="outlined"
                      fullWidth
                      select
                      size="small"
                      {...formik.getFieldProps("expense_account")}
                      // error={
                      //   formik.touched.expense_account &&
                      //   Boolean(formik.errors.expense_account)
                      // }
                      // helperText={
                      //   formik.touched.expense_account &&
                      //   formik.errors.expense_account
                      // }
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "265px",
                            },
                          },
                        },
                      }}
                    >
                      {expenseAccount?.map((option) => (
                        <MenuItem key={option.id} value={option.code}>
                          {option.accountid +" | " + option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={11}>
                    <TextField
                      id="header"
                      label={t("free_text_header")}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      
                      size="small"
                      {...formik.getFieldProps("header")}
                      error={
                        formik.touched.header && Boolean(formik.errors.header)
                      }
                      helperText={formik.touched.header && formik.errors.header}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={handleClickOpenHeader}
                      title="Help"
                    >
                      <InfoIcon sx={{ fontSize: 25, color: "#8B9635" }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="footer"
                    label={t("free_text_footer")}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    size="small"
                    {...formik.getFieldProps("footer")}
                    error={
                      formik.touched.footer && Boolean(formik.errors.footer)
                    }
                    helperText={formik.touched.footer && formik.errors.footer}
                  />
                </Grid>
                </Grid>
              </CustomBox>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <span className="commonCreateLabel">
              {t("common_list_templates")}
            </span>
            <Item>
              <div className="actionSectionRight">
                <div className="commonactionRight">
                  <div className="commonActionItem">
                    <IconButton onClick={copyTempalte}>
                      <ContentCopyIcon
                        sx={{ fontSize: 30, color: "#8B9635" }}
                      />
                    </IconButton>
                    <span>{t("common_copy")}</span>
                  </div>
                  <div className="commonActionItem setMarginLeft">
                    <IconButton onClick={handleDelete}>
                      <DeleteIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                    </IconButton>
                    <span>{t("common_delete")}</span>
                  </div>
                </div>
                <div className="searchInvoiceDiv">
                  <TextField
                    label={t("common_search")}
                    id="searchInvoices"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyDown={handleKeyDown}
                    sx={{ m: 1, width: "100%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </Item>
            <div style={{ height: "725px", marginTop: "10px" }}>
              <CustomDataGrid
                rows={setTemplateItem}
                getRowId={(setTemplateItem) => setTemplateItem.cid}
                columns={columns}
                pageSize={params.limit}
                page={params.page-1}
                onSelectionModelChange={handleSelectionModelChange}
                selectionModel={selectedRowIds}
                onSortModelChange={handleSortModelChange}
                getRowClassName={getRowClassName}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                loading={tableLoader}
                rowHeight={33}
                headerHeight={40}
                pagination
                rowsPerPageOptions={[10, 20]}
                paginationMode="server"
                paginationComponent={Pagination}
                localeText={localeText}
                rowCount={templateCount}
                scrollbarSize={0}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  LoadingOverlay: CustomLoadingOverlay,
                }}
                disableColumnMenu
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: t("common_rows_per_page"),
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <BootstrapDialog
        onClose={handleClosePDFModal}
        aria-labelledby="customized-dialog-title"
        open={pdfModalOpen}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosePDFModal}
        >
          {t("common_preview")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <iframe
            title="PDF Viewer"
            src={`data:application/pdf;base64,${pdfData}`}
            width="100%"
            height="750px"
          ></iframe>
        </DialogContent>
      </BootstrapDialog>
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxDelete>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseDeleteModal}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </Box>
          <div style={{ padding: "0px 25px 20px 25px", textAlign: "start" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("common_delete")}
            </Typography>
            <p id="child-modal-description">{t("confirm_delete")}</p>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <CustomBtn onClick={handleCloseDeleteModal} sx={{ mt: 2 }}>
                {t("common_cancel")}
              </CustomBtn>
              <CustomBtn onClick={deletetemplate} sx={{ mt: 2 }}>
                {t("common_delete")}
              </CustomBtn>
            </Box>
          </div>
        </CustomBoxDelete>
      </Modal>

      <Modal
        open={openContryConditionModal}
        onClose={handleCloseContryModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxDelete>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseContryModal}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </Box>
          <div style={{ padding: "0px 25px 20px 25px", textAlign: "start" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("common_confirm_template")}
            </Typography>
            <p id="child-modal-description">{t("confirm_template_submit")}</p>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <CustomBtn onClick={handleCancelContryModel} sx={{ mt: 2 }}>
                {t("common_cancel")}
              </CustomBtn>
              <CustomBtn onClick={handleSubmitCountry} sx={{ mt: 2 }}>
                {t("common_confirm_template")}
              </CustomBtn>
            </Box>
          </div>
        </CustomBoxDelete>
      </Modal>
      <Modal
        open={openDialogBookingtext}
        onClose={handleCloseBookingtext}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxHelp>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseBookingtext}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </Box>
          <div style={{ padding: "0px 25px 20px 25px", textAlign: "start" }}>
            <p>{t("help_booking_text")}</p>
          </div>
        </CustomBoxHelp>
      </Modal>
      <Modal
        open={openDialogHeader}
        onClose={handleCloseHeader}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxHelp>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseHeader}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </Box>
          <div style={{ padding: "0px 25px 20px 25px", textAlign: "start" }}>
            <p>{t("help_header")}</p>
          </div>
        </CustomBoxHelp>
      </Modal>
    </>
  );
}

export default Templates;
