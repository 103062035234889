import axios from 'axios';
import { encryptForm } from "../../services/commonService";
import {clearCookieStorage} from "../../services/localStorageUtil"

const api = axios.create({
  withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    // modify request config options here
    return config;
  },
  (error) => {
    // handle request errors here
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if(response.data.message ==="unauthorized"){
      // clearStorage()
      clearCookieStorage();
      let encryptedData = encryptForm(window.location.href, process.env.REACT_APP_ENCRIPTIONKEY);
      window.location.assign(process.env.REACT_APP_MTCURL + '/auth/login/' + encodeURIComponent(encryptedData));
    }
    // handle response data here
    return response;
  },
  (error) => {
    // handle response errors here
    if ((error.response.status === 401 || error.response.status === 403)) {
      // let encryptedData = encrypt('testproffix.io-procurement.com', process.env.REACT_APP_ENCRIPTIONKEY);
      let encryptedData = encryptForm(window.location.href, process.env.REACT_APP_ENCRIPTIONKEY);
      return window.location.assign(process.env.REACT_APP_MTCURL + '/auth/login/' + encodeURIComponent(encryptedData));
    }
    return Promise.reject(error);
  }
);

export default api;