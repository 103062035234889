// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftSearchFormDiv {
  display: "flex";
  flex: 0 0 50%;
}

form {
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  align-items: center;
}

.pdfsection div {
  height: 97% !important;
}

.rightSearchFormDiv {
  position: "relative";
  display: "flex";
  flex: 0 0 19%;
}

.rightSearchDatePicker {
  display: "flex";
  flex: 0 0 19%;
  align-self: center;
}

.searchForm1 {
  display: flex;
}

.rightSearchFormDiv {
  margin-right: 10px;
}

.rightSearchFormDiv, .rightSearchDatePicker {
  margin: 0 10px 0 10px;
}

.lastUpdatedTimeLbl {
  font-size: 12px;
  font-weight: 100;
}

@media (max-width: 768px) {
  form {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/dashboard/dashboard.scss"],"names":[],"mappings":"AAAA;EAEG,eAAA;EACA,aAAA;AAAH;;AAGA;EACG,aAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;AAAH;;AAIG;EACI,sBAAA;AADP;;AAIA;EACG,oBAAA;EACA,eAAA;EACA,aAAA;AADH;;AAIA;EAEG,eAAA;EACA,aAAA;EACA,kBAAA;AAFH;;AAQA;EACI,aAAA;AALJ;;AAOA;EACG,kBAAA;AAJH;;AAMA;EACG,qBAAA;AAHH;;AAKA;EACG,eAAA;EACA,gBAAA;AAFH;;AAKA;EACG;IACG,sBAAA;EAFJ;AACF","sourcesContent":[".leftSearchFormDiv{\n    // position: \"relative\";\n   display: \"flex\";\n   flex: 0 0 50%;  \n}\n\nform{\n   display: flex;\n   flex-direction: row;\n   flex: 0 0 100%;\n   align-items: center;\n}\n\n.pdfsection {\n   div {\n       height: 97% !important;\n   }\n}\n.rightSearchFormDiv{\n   position: \"relative\";\n   display: \"flex\";\n   flex: 0 0 19%;  \n}\n\n.rightSearchDatePicker{\n   // position: \"relative\";\n   display: \"flex\";\n   flex: 0 0 19%;  \n   align-self: center;\n}\n// .searchForm{\n//     display: flex;\n//     // flex: 0 0 50%;  \n// }\n.searchForm1{\n    display: flex;\n}\n.rightSearchFormDiv{\n   margin-right: 10px;\n}\n.rightSearchFormDiv,.rightSearchDatePicker{\n   margin: 0 10px 0 10px;\n}\n.lastUpdatedTimeLbl{\n   font-size: 12px;\n   font-weight: 100;\n}\n\n@media (max-width: 768px) {\n   form{\n      flex-direction: column;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
