import { createObject, encryptForm } from "../../../services/commonService";

const EncryptToken=()=>{
    const d = new FormData();
    //d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    return encrData
}

export default EncryptToken;