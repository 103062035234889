import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import reducers from '../reducer';

// export default configureStore({
//     reducer: {notifications},
// })
const store = configureStore({
    reducer: reducers
  });

  const { dispatch } = store;
  const useSelector = useAppSelector;
  const useDispatch = () => useAppDispatch();
  
  export { store, dispatch, useSelector, useDispatch };

