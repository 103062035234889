import React, { useState, memo } from "react";
import { DataGrid, GridFilterPanel, GridOverlay } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mui/material";
import Loader from "./PageLoader/PageLoader";
import Typography from "@mui/material/Typography";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solis #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
  "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#8B9635",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "unset",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "unset",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#8B9635",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
  },
}));

const CustomGridPanel = styled(GridFilterPanel)(({ theme }) => ({
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.mode === "light" ? "#000" : "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.mode === "light" ? "#000" : "#fff",
  },
}));

const CustomDataGridTable = memo(
  ({
    data,
    columns,
    rowId,
    selection,
    pagesize,
    selectedRowId,
    isLoader,
    rowCount,
    pageChange,
    currentPage,
    handleSort,
    limit
    // page
  }) => {
    const { t } = useTranslation(["language", "common"]);
    const [pageSize, setPageSize] = useState(limit);
    // const [page, setPage] = useState(1);

    function CustomNoRowsOverlay() {
      const { t } = useTranslation(["language", "common"]);
      return (
        <GridOverlay>
          <Typography>{t("common_no_data_available")}</Typography>
        </GridOverlay>
      );
    }

    const localeText = {
      noRowsLabel: t("common_no_rows"),
      footerRowSelected: (count) => `${count} ${t("common_row_selected")}`,
    };

    function CustomLoadingOverlay() {
      return <Loader />;
    }

    const handlePageSizeChange = (event) => {
      setPageSize(event);
      pagesize(event);
    };

    const handlePageChange = (event) => {
      pageChange(event);
    };

    const handleSortChange=(event)=>{
      handleSort(event);
    }

    return (
      <CustomDataGrid
        rows={data}
        getRowId={rowId}
        columns={columns}
        pageSize={pageSize}
        // onRowClick={handleRowClick}
        onSortModelChange={handleSortChange}
        page={currentPage - 1}
        onPageChange={handlePageChange}
        onSelectionModelChange={selection}
        selectionModel={selectedRowId}
        onPageSizeChange={handlePageSizeChange}
        loading={isLoader}
        rowHeight={33}
        headerHeight={40}
        rowsPerPageOptions={[10, 20]}
        pagination
        paginationMode="server"
        paginationComponent={Pagination}
        rowCount={rowCount}
        scrollbarSize={0}
        disableColumnMenu
        localeText={localeText}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("common_rows_per_page"),
          },
        }}
        components={{
          FilterPanel: CustomGridPanel,
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    );
  }
);

export default CustomDataGridTable;
