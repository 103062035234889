// material-ui
import { Alert, Button, Fade, Grow, Slide } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// project import
import IconButton from '@mui/material/IconButton';
import { dispatch, useSelector } from '../../../../redux/InvoiceStore';
import { closeSnackbar, openSnackbar } from '../../../../reducer/snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// animation function
function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

// animation options
const animation = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

// ==============================|| SNACKBAR ||============================== //

export const openAlertSnackBar = (type, message,marginTop)=>{
  dispatch(
    openSnackbar({
      open: true,
      message: message ? message : (type === 'success' ? 'success' : 'common_alert'),
      variant: 'alert',
      marginTop: marginTop,
      alert: {
        color: type,
        severity:type
      },
      close: true,
    })
  );
}
export const closeAlertSnackBar = ()=>{
  dispatch(closeSnackbar());
}

const Snackbar = () => {
  const snackbar = useSelector((state) => state.snackbar);
  const { t } = useTranslation(["language", "common"]);
  const { actionButton, anchorOrigin, alert, close, message, open, transition, variant,margintop } = snackbar;

  const CustomSnackbar = styled(MuiSnackbar)(() => ({
    // '&..MuiAlert-action.css-1mzcepu': {
    //   padding:'0px 0px 0px 4px'
    // },
    '&.MuiSnackbar-root': {
      top: margintop,
    },
    '&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0':{
      color: alert.color === 'error' ? '#000': '#fff'
    },
    maxWidth:'360px'
  }));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <>
      {/* default snackbar */}
      {variant === 'default' && (
        <CustomSnackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={t(message)}
          TransitionComponent={animation[transition]}
          action={
            <>
              <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                <CloseIcon />
              </IconButton>
            </>
          }
        />
      )}

      {/* alert snackbar */}
      {variant === 'alert' && (
        <CustomSnackbar
          TransitionComponent={animation[transition]}
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            variant={alert.variant}
            severity={alert.severity}
            color={alert.color}
            // action={
            //   <>
            //     {actionButton !== false && (
            //       <Button color={alert.color} size="small" onClick={handleClose}>
            //         UNDO
            //       </Button>
            //     )}
            //     {close !== false && (
            //       <IconButton
            //         size="small"
            //         aria-label="close"
            //         variant="contained"
            //         color={alert.color}
            //         onClick={handleClose}
            //       >
            //         <CloseIcon sx={{color:'white'}}/>
            //       </IconButton>
            //     )}
            //   </>
            // }
            sx={{
              ...(alert.variant === 'outlined' && {
                bgcolor: 'grey.0'
              })
            }}
          >
            {t(message)}
          </Alert>
        </CustomSnackbar>
      )}
    </>
  );
};

export default Snackbar;
