
import React, { useEffect } from "react";
import './App.css';
import Routes from './routes';
import { Suspense } from 'react';
import { createAccessToken } from "./services/Services";
import IdleTimeout from "./services/IdleTimer";
import Loader from "./shared/common/utilites/PageLoader/PageLoader";
import {AuthProvider} from "./shared/contexts/AuthProvider";
import ThemeCustomization from "./themes";
import Snackbar from "./shared/common/utilites/notification/snackbar";


function App() {

  useEffect(() => {
    // const intervalId = setInterval(fetchData, 30 * 60 * 1000); // set interval for 30 minutes
    const interval = setInterval(() => {
    fetchData();
    }, 600000);
    return () => {
      clearInterval(interval); // clear interval when component unmounts
    };
    
  }, []);

  IdleTimeout(1000 * 60 * 30); // Timeout 30 minutes inactivity  

  const fetchData = () => {
    createAccessToken().then(d => { });
  };

  return (
    <ThemeCustomization>
      <>
        <Suspense fallback={<div className="fallback"><Loader/></div>}>
          <AuthProvider>
            <>
              <Routes/>
              <Snackbar/>
            </>
          </AuthProvider>
        </Suspense>
      </>
    </ThemeCustomization>
  );
}

export default App;
