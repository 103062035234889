// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 45px !important;
  width: 160px;
  display: block;
  margin-top: 10px;
  margin-left: 36px;
  margin-right: 50px;
}`, "",{"version":3,"sources":["webpack://./src/shared/common/logo/companylogoimage.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,qBAAA;EACA,uBAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[".logo{\n    background-repeat: no-repeat;\n    background-size: 100%;\n    height: 45px !important;\n    width: 160px;\n    display: block;\n    margin-top: 10px;\n    margin-left: 36px;\n    margin-right: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
