import React,{useState,useCallback,useEffect} from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { CssBaseline, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { CustomModal } from "../../shared/common/utilites/customModal";
import IconButton from "@mui/material/IconButton";
import {Pagination } from "@mui/material";
// import Slide from "@mui/material/Slide";
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import EditIcon from "@mui/icons-material/Edit";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import {TextField } from "@mui/material/";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik} from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import * as Yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
// import MuiAlert from "@mui/material/Alert";
import { deleteModule, editModule, getModulelists, saveModule } from "../../services/Services";
import { createObject, encryptForm } from "../../services/commonService";
import { getEncryptedItem } from "../../services/localStorageUtil";
import "../module/module.scss";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const CustomLoadingOverlay = ()=>{
    return <Loader />;
}

const CustomLoader = styled(Loader)(({ theme }) => ({
    loader: {
      width: "31px",
      height: "31px",
    },
  }));

const CustomBtn = styled(Button)(({ theme }) => ({
    textTransform: "none",
    borderRadius: "20px",
    background: "unset",
    border: "2px solid #8B9635",
    padding: "0px 16px 0px 16px",
    color: theme.palette.mode === "light" ? "black" : "#fff",
    "&:hover": {
      backgroundColor: "#8B9635",
      color: theme.palette.mode === "light" ? "#fff" : "#000"
    },
    "&.MuiLoadingButton-root.Mui-disabled": {
      background: theme.palette.mode === "light" ? "#fff" : "black",
      padding: "0px 0px",
      span: {
        color: "#8B9635",
      },
    },
}));

const CustomBox = styled(Box)(({ theme }) => ({
    border:
      theme.palette.mode === "light"
        ? "1px solid #ccc"
        : "1px solid rgba(255, 255, 255, 0.12)",
  }));

const CustomDataGrid = styled(DataGrid)(({ theme }) =>
({
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.12)',
  '& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: '#8B9635',
  },
  '&.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeSmall .MuiDataGrid-menuIconButton .css-xfvph6': {
    display: 'none'
  },
  '& .css-cipkih-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
    color: '#8B9635',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset'
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset'
  },
  '& .MuiDataGrid-row.Mui-selected.commonSelected': {
    backgroundColor: '#8B9635',
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    '&.MuiSvgIcon-fontSizeMedium':{
      color:'#000'
    },
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#000' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'unset'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
}));

// const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
//     width: "275px",
//     "&.MuiSnackbar-root": {
//       top: "104px",
//     },
// }));

const defaultModules = { 
    limit: 20, 
    page: 1, 
    column: '', 
    order: '' ,
    search:''};
const marginTopValue = '104px';
const Module =()=>{
    const { t } = useTranslation(["language", "common"]);
    const [modules, setModules] = useState(defaultModules);
    const [moduleCount, setModuleCount] = useState(0);
    const [tableLoader, setTableLoader] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [moduleData, setModuleData] = useState([]);
    // const [snackopen, setsnackOpen] = useState(false);
    // const [isPushed, setPushValue] = useState("");
    const [toggleIcon, setToggleBtn] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [isLoaderOverlay, setIsLoaderOverlay] = useState(false);
    // const [transition, setTransition] = useState(undefined);
    // const [pushAlert, setAlert] = useState("info");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [cancelModule, setCancelModule] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    const columns = [
        { field: "label", headerName: t("common_module"), flex: 1}
    ];

    const initialValues = {
        module_name:'',
        modulecode:''
    };

    const validationSchemaModule = Yup.object().shape({
        module_name:Yup.string().required(t('common_validate_module')),
   });

   useEffect(() => {
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
  }, []);

  const handleOpenModel = ()=>{
    if (!selectedRowIds.length){return}
    setOpenDeleteModal(true);
  }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema:validationSchemaModule,
        onSubmit: (values) => {
            setIsLoaderOverlay(true);
            if (!toggleIcon) {
              const createModuleForm = new FormData();
              const moduleObj = {};
              moduleObj["name"] = values.module_name.trim();
              createModuleForm.append("message", JSON.stringify(moduleObj));
              createModuleForm.append("userName", userDetails.username?userDetails.username:"");
              //createModuleForm.append("isAuthUser", '');
              let object = createObject(createModuleForm);
              let encryptedToken = encryptForm(object);
              let encrData = new FormData();
              encrData.append("encrToken", encryptedToken);
              saveModule(encrData)
                .then((res) => {
                  setIsLoaderOverlay(false);
                  if (res.status) {
                    formik.resetForm();
                    loadModule();
                    openAlertSnackBar('success',res.message,marginTopValue);
                  } else {
                    openAlertSnackBar('error',res.message,marginTopValue);
                  }
                })
                .catch((err) => {
                  setIsLoaderOverlay(false);
                  openAlertSnackBar('error','common_alert',marginTopValue);
                });
            }else{
              // const filteredModule = moduleData.filter((item)=> item.id === selectedRowIds[0])[0];
              // if(values.module_name.trim()===filteredModule.label){
              //   setIsLoaderOverlay(false);
              //   openAlertSnackBar('error','common_no_change',marginTopValue);
              //   return;
              // };

              const editModuleForm = new FormData();
              const moduleObj = {};
              moduleObj["name"] = values.module_name.trim();
              editModuleForm.append("message", JSON.stringify(moduleObj));
              editModuleForm.append("cid", selectedRowIds[0]);
              editModuleForm.append("userName", userDetails.username?userDetails.username:"");
              //editModuleForm.append("isAuthUser", '');
              let object = createObject(editModuleForm);
              let encryptedToken = encryptForm(object);
              let encrData = new FormData();
              encrData.append("encrToken", encryptedToken);
              editModule(encrData)
                .then((res) => {
                  setIsLoaderOverlay(false);
                  if (res.status) {
                    formik.resetForm();
                    loadModule();
                    openAlertSnackBar('success',res.message,marginTopValue);
                  } else {
                    openAlertSnackBar('error',res.message,marginTopValue);
                  }
                })
                .catch((err) => {
                  setIsLoaderOverlay(false);
                  openAlertSnackBar('error','common_alert',marginTopValue);
                });

            }
        }
    })
    // const Alert = React.forwardRef(function Alert(props, ref) {
    //     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    // });

    function CustomNoRowsOverlay() {
        return (
          <GridOverlay>
            <Typography>{t("common_no_data_available")}</Typography>
          </GridOverlay>
        );
    }

    const handleCloseDeleteModal = () => {
      setOpenDeleteModal(false);
    };

    const DeleteModalContent=()=>{
      return(
        <>
          <p id="child-modal-description">{t("confirm_delete")}</p>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <CustomBtn onClick={handleCloseDeleteModal} sx={{ mt: 2 }}>
              {t("common_cancel")}
            </CustomBtn>
            <CustomBtn onClick={handleDelete} sx={{ mt: 2 }}>
              {t("common_delete")}
            </CustomBtn>
          </Box>
        </>)
    }

    const lastSelectedRowIndex =selectedRowIds.length > 0 ? selectedRowIds[selectedRowIds.length - 1]: null;

    const getRowClassName = useCallback(
        (params) => {
          if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
            return "commonSelected";
          }
          if (lastSelectedRowIndex === null) {
            return "commonSelected";
          }
          return "";
        },
        [lastSelectedRowIndex]
    );

    const handlePageChange = (event, newPage) => {
        setModules((previousParams)=>({
          ...previousParams,
          page:event + 1
        }))
    };

    const handleSortModelChange = (newModel) => {
        if (newModel.length > 0) {
            setModules((previousParams)=>({
            ...previousParams,
            column:newModel[0].field,
            order:newModel[0].sort,
            page:1
          }))
        }else{
            setModules((previousParams)=>({
            ...previousParams,
            column:"",
            order:"",
            page:1
          }))
        }
    };

    const handlePageSizeChange = (event) => {
        setModules((previousParams)=>({
          ...previousParams,
          limit:event,
          page:1
        }))
    };

    const handleModuleSelectionChange = (newSelection) => {
        if ((newSelection.length === 0)||(selectedRowIds[0] === newSelection[0])) {
            formik.resetForm();
            setSelectedRowIds([]);
            setCancelModule(initialValues);
            setToggleBtn(false);
            return;
        }
        setSelectedRowIds(newSelection);

        const filteredModule = moduleData.filter((item)=>
            item.id === newSelection[newSelection.length -1 ])
        if(!filteredModule.length){return};
        setToggleBtn(true);
        let moduleObj =initialValues;
        moduleObj={
            module_name:filteredModule[0].label?filteredModule[0].label:'',
            modulecode:filteredModule[0].code?filteredModule[0].code:''
        }
        formik.setValues(moduleObj);
        setCancelModule(moduleObj)
    }

    const handleSearchKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          setModules((previousParams)=>({
            ...previousParams,
            search:event.target.value,
            page:1
          }))
        }
    };

    const handleModuleName=(event)=>{
      formik.setFieldValue("module_name",event.target.value);
      if(selectedRowIds.length===0)
      {
        const uppercaseString = event.target.value.replace(/\s/g, '').toUpperCase();
        const shortCode = uppercaseString.replace(/[^A-Z0-9]/g, '');
        formik.setFieldValue("modulecode",shortCode);
      }
    }
    const handleSearch=()=>{
      setModules((previousParams)=>({
        ...previousParams,
        search:searchValue,
        page:1
      }))
    }

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value)
        // if (event.key === "Enter") {
        //   event.preventDefault();
        //   setModules((previousParams)=>({
        //     ...previousParams,
        //     search:event.target.value,
        //     page:1
        //   }))
        // }
    };

    // function TransitionUp(props) {
    //     return <Slide {...props} direction="left" />;
    // }

    // const getAlert =useCallback((type, message) => {
    //     setTransition(() => TransitionUp);
    //     setsnackOpen(true);
    //     setAlert(type);
    //     if (message === "" || message === undefined)
    //       return setPushValue(("common_alert"));
    //     setPushValue((message));
    // },[]);

    const setNewForm = () => {
        formik.resetForm();
        setCancelModule(initialValues);
        setSelectedRowIds([]);
        setToggleBtn(false);
    };

    const resetModuleForm = () => {
        formik.resetForm();
        if(cancelModule){
          formik.setValues(cancelModule);
        }
    };

    const loadModule = useCallback(() => {
        const controllerModules = new AbortController();
        const signalModules = controllerModules.signal;
    
        const d = new FormData();
        d.append("limit", modules.limit);
        d.append("page", modules.page);
        d.append("column", modules.column);
        d.append("order", modules.order);
        d.append("search", modules.search);
        //d.append("isAuthUser", '');

        setTableLoader(true);
        setModuleData([]);
        
        let objectItem = createObject(d);
        let encryptedToken = encryptForm(objectItem);
        let encrData = new FormData();
        encrData.append("encrToken", encryptedToken);
        getModulelists(encrData, signalModules)
          .then((res) => {
            // if(isMounted)
            // {
            setTableLoader(false);
            if (res.status) {
                if(res.data.length>0)
                {
                  setModuleData(res.data);
                  setModuleCount(Number(res.filtered));
                }else{
                setModuleCount(0);
                }
            } else {
                openAlertSnackBar('error',res.message,marginTopValue);
            }
            // }
          })
          .catch((err) => {
            // if(isMounted)
            // {
            setTableLoader(false);
            openAlertSnackBar('error','common_alert',marginTopValue);
            // }
          })
          .finally(() => {
            controllerModules.abort();
          });

        return () => {
            controllerModules.abort();
            // setIsMounted(false);
          };
    },[modules])

    useEffect(() => {
      loadModule();
      return ()=>{
        closeAlertSnackBar();
      }
    },[loadModule])

    const handleDelete=()=>{
        const deleteItemForm = new FormData();
        deleteItemForm.append("cid",selectedRowIds[0] );
        deleteItemForm.append("userName", userDetails.username?userDetails.username:"");
       // deleteItemForm.append("isAuthUser", '');
        let object = createObject(deleteItemForm);
        let encryptedToken = encryptForm(object);
        let encrData = new FormData();
        encrData.append("encrToken", encryptedToken);
        deleteModule(encrData)
          .then((res) => {
            setIsLoaderOverlay(false);
            setOpenDeleteModal(false);
            if (res.status) {
              loadModule();
              openAlertSnackBar('success',res.message,marginTopValue);
            } else {
              openAlertSnackBar('error',res.message,marginTopValue);
            }
          })
          .catch((err) => {
            setIsLoaderOverlay(false);
            openAlertSnackBar('error','common_alert',marginTopValue);
          });
    }

    // const handleCloseSnackbar = () => {
    //     setsnackOpen(false);
    // };

    const localeText = {
        noRowsLabel: t("common_no_rows"),
        footerRowSelected: (count) => `${count} ${t("common_row_selected")}`,
    };

    return(
      <>
        <CssBaseline />
        <Box sx={{ flexGrow: 1, margin: "10px 0 0 0" }}>
          <Grid container  spacing={2}>
            <Grid item xs={12} md={6}>
              <span className="commonCreateLabel">
                  {t("common_module")}
              </span>
              <Item >
                <div className="actionSection">
                    <div className="commonActionItem">
                    <IconButton onClick={setNewForm}>
                        <AddOutlinedIcon
                        sx={{ fontSize: 30, color: "#8B9635" }}
                        />
                    </IconButton>
                    <span>{t("common_new")}</span>
                    </div>
                    {!toggleIcon ? (
                    <div className="commonActionItem">
                        <IconButton onClick={formik.handleSubmit}>
                        <SaveSharpIcon
                            sx={{ fontSize: 30, color: "#8B9635" }}
                        />
                        </IconButton>
                        <span>{t("common_save")}</span>
                    </div>
                    ) : (
                    <div className="commonActionItem">
                        <IconButton onClick={formik.handleSubmit}>
                        <EditIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                        </IconButton>
                        <span>{t("common_update")}</span>
                    </div>
                    )}
                    <div className="commonActionItem">
                    <IconButton onClick={resetModuleForm}>
                        <CloseSharpIcon
                        sx={{ fontSize: 30, color: "#ff0000" }}
                        />
                    </IconButton>
                    <span>{t("common_cancel")}</span>
                    </div>
                    <Box
                    className="commonActionItemEnd"
                    display="flex"
                    justifyContent="flex-end"
                    >
                    {isLoaderOverlay && <CustomLoader />}
                    </Box>
                </div>
              </Item>
              <CustomBox
                  className="leftSection"
                  sx={{
                      minHeight: "725px",
                      height: "auto",
                      boxSizing: "borderBox",
                  }}
                  >
                  {!toggleIcon ? (
                      <Box
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                      <span style={{ color: "#8B9635" }}>
                          {t("template_new_mode")}
                      </span>
                      </Box>
                  ) : (
                      <Box style={{display: "flex", justifyContent: "flex-end" }}>
                          <span style={{ color: "#8B9635" }}>
                          {t("template_edit_mode")}
                          </span>
                      </Box>
                  )}
                  
                  <Grid item container xs={12}  spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                          id="module_name"
                          label={t("common_module")+'*'}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          size="small"
                          sx={{ margin: '10px 0 0px 0' }}
                          onChange={handleModuleName}
                          // {...formik.getFieldProps('module_name')}
                          value={formik.values.module_name}
                          error={formik.touched.module_name && Boolean(formik.errors.module_name)}
                          helperText={formik.touched.module_name && formik.errors.module_name}
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                          id="modulecode"
                          label={t("modulecode")}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          size="small"
                          disabled
                          sx={{ margin: '10px 0 0px 0' }}
                          onChange={formik.handleChange}
                          {...formik.getFieldProps('modulecode')}
                          value={formik.values.modulecode}
                      />
                    </Grid>
                  </Grid>
              </CustomBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <span className="commonCreateLabel">
                  {t("common_module_list")}
              </span>
              <Item>
                  <div className="actionSectionRight">
                      <div className="commonactionRight">
                          <div className="commonActionItem">
                          <IconButton onClick={handleOpenModel}>
                              <DeleteIcon sx={{ fontSize: 30, color: "#8B9635" }} />
                          </IconButton>
                          <span>{t("common_delete")}</span>
                          </div>
                      </div>
                      <div className="searchInvoiceDiv">
                          <TextField
                              label={t("common_search")}
                              id="searchInvoices"
                              variant="outlined"
                              size="small"
                              fullWidth
                              onKeyDown={handleSearchKeyDown}
                              onChange={handleSearchChange}
                              sx={{ mb: 1, width: "100%" }}
                              InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchIcon onClick={handleSearch} sx={{cursor: 'pointer'}}/>
                                  </InputAdornment>
                              ),
                              }}
                          />
                      </div>
                  </div>
              </Item>
              <div style={{ height: "725px", marginTop: "10px" }}>
                  <CustomDataGrid
                  rows={moduleData}
                  getRowId={(row) => row.id}
                  columns={columns}
                  rowHeight={33}
                  headerHeight={40}
                  pagination
                  rowsPerPageOptions={[10, 20]}
                  onPageChange={handlePageChange}
                  page={modules.page-1}
                  getRowClassName={getRowClassName}
                  selectionModel={selectedRowIds}
                  onSortModelChange={handleSortModelChange}
                  onSelectionModelChange={handleModuleSelectionChange}
                  pageSize={modules.limit}
                  loading={tableLoader}
                  onPageSizeChange={handlePageSizeChange}
                  paginationMode="server"
                  paginationComponent={Pagination}
                  rowCount={moduleCount}
                  localeText={localeText}
                  scrollbarSize={0}
                  components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      LoadingOverlay: CustomLoadingOverlay,
                  }}
                  disableColumnMenu
                  componentsProps={{
                      pagination: {
                      labelRowsPerPage: t("common_rows_per_page"),
                      },
                  }}
                  />
              </div>
            </Grid>
          </Grid>
        </Box>
        {/* <CustomSnackbar
            open={snackopen}
            onClose={handleCloseSnackbar}
            TransitionComponent={transition}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            key={transition ? transition.name : ""}
        >
            <Alert
             onClose={handleCloseSnackbar}
             severity={pushAlert}
             sx={{ width: "100%" }}
             >
                {isPushed !== "" ? t(isPushed) : ""}
            </Alert>
        </CustomSnackbar> */}
        <CustomModal
          openModal={openDeleteModal}
          onclose={handleCloseDeleteModal}
          header={t("common_delete")}
          content={DeleteModalContent}
        />
        </>
    )
}

export default Module;