
export const DataGridActionTypes  ={
  ADD_DATA:'ADD DATA',
  ASSIGN_DATA:'ASSIGN DATA',
  UPDATE_DATA:'UPDATE DATA',
  UPDATE_DATA_NO_CID:'UPDATE DATA WITH NO CID',
  DELETE:'DELETE DATA',
  APPEND_DATA:'APPENT DATA',
  REORDER_DATA:'REORDER DATA'
}

export const  DataGridReducer = (state,action)=>{
    switch(action.type){
      case DataGridActionTypes.ADD_DATA:
        return {
          ...state,
          data: [...state.data,action.payload]
        }
      case DataGridActionTypes.APPEND_DATA:
        return {
          ...state,
          data: [...state.data,action.payload]
        }
      case DataGridActionTypes.ASSIGN_DATA:
        return {
          ...state,
          data: [...action.payload]
        }
      case DataGridActionTypes.UPDATE_DATA:
        let index =state.data.findIndex((item)=>item.cid === action.payload.cid);
        if (index === -1) {
           return state;
        }
        const updateData = [...state.data];
        updateData[index]=action.payload
        return {
          ...state,
          data: [...updateData]
        }
      case DataGridActionTypes.UPDATE_DATA_NO_CID:
        let index2 =action.payload.cid;
        if (index2 === -1) {
          return state;
       }
        const editData = [...state.data];
        editData[index2]=action.payload.value
        return {
          ...state,
          data: [...editData]
        }
      case DataGridActionTypes.DELETE:
        let cid = action.payload.cid;
        let previousData = state.data;
        let remingData = previousData.filter((item,index)=>index !== cid);
        return {
          ...state,
          data: [...remingData]
        }
      case DataGridActionTypes.REORDER_DATA:
        const moveId = action.payload.cid;
        const moveDirection = action.payload.move;
        let moveArray = [...state.data];
        let moveIndex = moveId;
        
        if(moveDirection.toLowerCase() ==='up'){
          if(moveId===0){
            return state;
          };
          moveIndex = moveId - 1 ;
        }else{
          if((moveArray.length - 1) === moveId){
            return state;
          };
          moveIndex = moveId + 1 ;
        }
        
        [moveArray[moveIndex], moveArray[moveId]] = [moveArray[moveId], moveArray[moveIndex]];

        // let temp = moveArray[moveIndex];
        // moveArray[moveIndex]=moveArray[moveId];
        // moveArray[moveId] = temp;
        return {
          ...state,
          data: [...moveArray]
        }
      default:
        return state;
    }
  }