import { getEncryptedItem } from "../services/localStorageUtil";

const CheckRights =(routes)=>{
    let permission = getEncryptedItem('loginCookieData') ? getEncryptedItem('loginCookieData').split(','):null;
    let value = [];

    if((!permission)||(permission.length===0)){
        return [];
    }else{
        value = routes.filter((route)=> {
            let isRight = permission.length>0 ? permission.some((rout)=>rout.includes(route?.rights)) : false;
            if((route.children)&& isRight){
                route.children = CheckRights(route.children);
            }
            return isRight;
        })
    }
    
    return value;
}

export default CheckRights;