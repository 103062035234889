import { decrypt } from './services/commonService';
import { createAccessToken } from "./services/Services";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from './hooks/useAuth'
import { getEncryptedItem } from './services/localStorageUtil';

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn,login,logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
      
    useEffect(() => {
        if (!isLoggedIn) {
            login();
        }else{
             let permission = getEncryptedItem('loginCookieData') ? getEncryptedItem('loginCookieData').split(','):null;
             if((!permission)||(permission.length===0)){
                 logout();
                 return;
             }
            let expiredCookie = decrypt(process.env.REACT_APP_MTC_COOKIE);
            if(!expiredCookie){
                logout();
                return;
            }

            if (tokenExpired(expiredCookie)) {
                // token expired
                createAccessToken().then(d => { });
            } 
        }
    }, [isLoggedIn, navigate, location,login]);
      
    return children;
};

const tokenExpired = (token) => {
    let cookieExpiredTime = JSON.parse(token)
    let t = new Date(cookieExpiredTime[process.env.REACT_APP_TOKENEXPIRETIME]).getTime() < new Date().getTime();
    return t;
}

export default ProtectedRoute;