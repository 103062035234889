import { createContext, useEffect, useReducer } from "react";
import { clearCookieStorage, getEncryptedItem, redirectToLogin, setEncryptedItem } from "../../services/localStorageUtil";
import { getUserProfileData } from "../../services/Services";
import { decrypt } from "../../services/commonService";
import auth, { AuthDatatypes } from "../../reducer/AuthReducer";
import Cookies from "js-cookie";
import Loader from "../common/utilites/PageLoader/PageLoader";
// import Loader from "../common/utilites/PageLoader/PageLoader";
const AuthContext = createContext(null);
// const mtcPermissions = process.env.PERMISSION_COOKIE_NAME || 'mtcPermissions';

export const AuthProvider =({children})=>{
    const initialState = {
        isLoggedIn: false,
        isInitialized: false,
        user: null,
    };
    const [state, dispatch] = useReducer(auth, initialState);

    const getUserProfile =async()=>{
        try {
            if (!getEncryptedItem('userData')) {
              await getUserProfileData().then(d => {
                  if(d.status){ 
                    if(d.data.length>0){
                      setEncryptedItem('userData', JSON.stringify(d?.data[0]));
                      const userPermissions = d?.data[0].permissions.Allow ;
                      if(userPermissions && userPermissions.length>0){
                        setEncryptedItem('loginCookieData', userPermissions);
                        checkRight(userPermissions);
                        // resolve(true);
                      }else{
                        // reject(false);
                      }
                    }
                  }else{
                      logout();
                  }
                }).catch((error)=>{
                  logout();
                });
            }
            return null;
        }
        catch (error) {
            logout();
        }
  }

    const checkRight = async(permissions)=>{
      if((!permissions) || (permissions.length==0)){
        await redirectToLogin();
        return;
      }
      const isAuthenticated = permissions.includes("proffix.login.*");
      if (!isAuthenticated || isAuthenticated === "") {
        logout();
      }else{
        dispatch({
          type: AuthDatatypes.LOGIN,
          payload: {
            isInitialized: true,
            isLoggedIn: true
          }
        });
      }
    }

    const login = async()=>{
      let expiredCookie = decrypt(process.env.REACT_APP_MTC_COOKIE);
      if(!expiredCookie){
          await redirectToLogin();
          return;
      }else{
        if ((!getEncryptedItem('loginCookieData'))||(!getEncryptedItem('userData'))) {
          await getUserProfile();
          // if(!response){
          //   logout();
          // }
        }else{
            const useData = getEncryptedItem('loginCookieData');
            if((useData)&&(useData.length>0)){
              const permissions= useData.split(',');
              checkRight(permissions);
            }else{
              logout();
            }
        }
      }
    }

    async function logout(){
        dispatch({type: AuthDatatypes.LOGOUT});
        await clearCookieStorage();
    }

    useEffect(()=>{
      if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
      }
      // if(state.isInitialized){
      //   const useData = getEncryptedItem('loginCookieData')
      //   if(!useData){
      //     getUserProfile();
      //   }
      // }
    },[state])
    

    return(
        <AuthContext.Provider
          value={{
            ...state,
            login,
            logout
          }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;