// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left_section {
  display: flex;
  flex: 1 0 20%;
  vertical-align: center;
}

.left_section {
  display: flex;
  flex: 1 0 20%;
  margin-top: 1%;
}

@media screen and (max-width: 1300px) {
  .left_section {
    margin-top: 3% !important;
  }
}
.activeSelection {
  background-color: #8B9635;
  color: #000;
}

.MuiFormHelperText-root.Mui-error {
  margin: 0px !important;
  height: 13px !important;
}`, "",{"version":3,"sources":["webpack://./src/shared/layout/mainLayout.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AACA;EACI,aAAA;EACA,aAAA;EACA,cAAA;AAEJ;;AAAA;EACI;IACI,yBAAA;EAGN;AACF;AADA;EACI,yBAAA;EACA,WAAA;AAGJ;;AAAA;EACI,sBAAA;EACA,uBAAA;AAGJ","sourcesContent":[".left_section {\n    display: flex;\n    flex: 1 0 20%;\n    vertical-align: center;\n}\n.left_section {\n    display: flex;\n    flex: 1 0 20%;\n    margin-top: 1%;\n}\n@media screen and (max-width: 1300px) {\n    .left_section {\n        margin-top: 3% !important;\n    }\n  }\n.activeSelection {\n    background-color: #8B9635;\n    color: #000;\n}\n\n.MuiFormHelperText-root.Mui-error {\n    margin: 0px !important;\n    height: 13px !important;\n}\n// .sideNav {\n//     .css-z2ncjf-MuiPaper-root-MuiAppBar-root {\n//         background-color: #0000 !important;\n//         color: #ffff;\n//     }\n// }\n\n// .MuiPaper-root {\n//     background-color: #0000 !important;\n//     color: #ffff;\n// }\n\n// .togglenavbtn{\n//     color: #fff;\n// }\n// .selectedList {\n//     .MuiTypography-root{\n//         color: #000;\n//     } \n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
