// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.historySection2 {
  max-height: 760px;
  overflow-y: scroll;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 750px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.historySection2::-webkit-scrollbar {
  display: none;
}

.MuiTextField-root {
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/features/log/log.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;EACA,aAAA;AACJ;;AAEA,gDAAA;AACA;EACI,aAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".historySection2{\n    max-height: 760px;\n    overflow-y: scroll;\n    width: 100%;\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n    height: 750px;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.historySection2::-webkit-scrollbar {\n    display: none;\n}\n\n.MuiTextField-root {\n    margin: 0px;\n}\n// .autocompleteList::-webkit-scrollbar {\n//     display: none !important;\n//   }\n// .autocompleteList {\n// overflow-y: scroll;\n// width: 100%;\n// scrollbar-width: none !important;\n// -ms-overflow-style: none !important;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
