import React, {  useContext, useLayoutEffect, useState } from "react";
import '../layout/mainLayout.scss'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DatasetIcon from '@mui/icons-material/Dataset';
import DescriptionIcon from '@mui/icons-material/Description';
import ArchiveIcon from '@mui/icons-material/Archive';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HistoryIcon from '@mui/icons-material/History';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import i18next from 'i18next';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LanguageMenu from "../common/languageMenu/languageMenu";
import { getEncryptedItem, getCookie, clearCookieStorage, removeEncryptedItem, setEncryptedItem } from '../../services/localStorageUtil';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
import Companylogo from '../common/logo/companylogo';
import { useNavigate } from "react-router-dom";
// import { createTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Typography from '@mui/material/Typography';
// import NotificationSnackbar from '../../shared/notifications/notificationSnackBar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CloseIcon from "@mui/icons-material/Close";
import Logout from '@mui/icons-material/Logout';
import ContactsIcon from '@mui/icons-material/Contacts';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckRights from "../../routes/checkRights";
import NavItemCollapse from "../common/Navigation/NavItemCollaps";
import NavItem from "../common/Navigation/NavItem";
import useAuth from "../../hooks/useAuth";
import { ConfigContext } from "../../themes/config";

// import Button from '@mui/material/Button';
// import {
//   Route,
//   createBrowserRouter,
//   createRoutesFromElements,
//   RouterProvider,
// } from "react-router-dom";
// import QueueIcon from '@material-ui/icons/Queue';
const drawerWidth = 240;
var isOpened;

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "20px",
  background: "unset",
  border: "2px solid #8B9635",
  textAlign: 'center',
  padding: '0px 35px 0px 35px',
  color: theme.palette.mode === "light" ? "#000" : "#fff",
  "&:hover": {
    backgroundColor: "#8B9635",
    color: "#000",
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "#000",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));

const Main = styled('main',
  {
    shouldForwardProp: (prop) => prop !== 'open'
  })
  (
    ({ theme, open }) =>
    ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin',
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      marginLeft: `-${drawerWidth}px`,
      ...(open &&
      {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );

const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff'
}))



const CustomHomeIcon = styled(HomeIcon)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.54);'
}))

const CustomMenu = styled(Menu)(({ theme }) => ({
  overflow: 'visible',
  textAlign: 'start',
  padding: '10px 10px 10px 10px',
  margin: '0px 10px 0px 10px',
  flex: '0 0 100%',
  flexDirection: 'column',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    justifyContent: 'center',
    // ml: -0.5,
    // mr: 1,
  },
  '& .MuiList-root': {
    margin: '0px 10px 10px 10px',
    borderRadius: '10px',
  },
}))

const AppBar = styled(MuiAppBar,
  {
    shouldForwardProp: (prop) => prop !== 'open',
  })
  (({ theme, open }) =>
  ({
    // backgroundColor: '#f5f5f5',
    height: '64px',
    padding: 'unset',
    transition: theme.transitions.create(['margin', 'width'],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }
    ),
    ...(open &&
    {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: `${drawerWidth}px`,
      display: 'flex',
      flex: '1 1 100%',
      // backgroundColor: '#f5f5f5',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
  );
  const CustomBoxClearCache = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    width: 500,
    // bgcolor: "background.paper",
    textAlign: "center",
    // border: '2px solid ',
    // boxShadow: 24,
    // backgroundColor:'white',
    // color :'black',
    backgroundColor: theme.palette.mode === "light" ? "white" : "black",
    // padding: "15px 25px 20px 25px",
  }));

const invoiceManagementMenuList =[
    { 
      label: 'item',
      icon: <FileCopyIcon />,
      path: 'invoice/item',
      rights:'proffix.invoice.item.'
    },
    { 
      label: 'common_scheduler', 
      icon: <TodayRoundedIcon />, 
      path: 'invoice/scheduler',
      rights:'proffix.invoice.scheduler.' 
    },
    { 
      label: 'common_template', 
      icon: <BackupTableOutlinedIcon />, 
      path: 'template',
      rights:'proffix.template.' 
    }, 
    { 
      label: 'address', 
      icon: <ContactsIcon />, 
      path: 'address',
      rights:'proffix.address.' 
    },
    { 
      label: 'common_module', 
      icon: <DatasetIcon />, 
      path: 'module',
      rights:'proffix.module.' 
    }
];

const menuLists =[
    { 
      label: 'dash_home',
      icon: <HomeOutlinedIcon />,
      path: 'dashboard',
      rights:'proffix.dashboard.'
    }, 
    { 
      label: 'reminderlist',
      icon:  <NotificationsIcon/>,
      path: 'reminderlist' ,
      rights:'proffix.reminderlist.'
    },
    { 
      label: 'sidebar_queue',
      icon: <NoteAddOutlinedIcon />,
      path: 'queue' ,
      rights:'proffix.queue.'
    },
    { 
      label: 'common_archive',
      icon: <ArchiveIcon />,
      path: 'archive' ,
      rights:'proffix.archive.'
    },
    
    // { 
    //   label: 'sidebar_invoice_queue',
    //   icon: <NoteAddOutlinedIcon />,
    //   path: 'queue' ,
    //   rights:'proffix.queue.'
    // },
    // { 
    //   label: 'common_invoice_management', 
    //   icon: <DescriptionIcon />,
    //   path: 'invoice' ,
    //   rights:'proffix.invoice.',
    //   children:[
    //     { 
    //       label: 'common_create_invoice',
    //       icon: <AddBoxOutlinedIcon />,
    //       path: 'invoice/createinvoice' ,
    //       rights:'proffix.invoice.createinvoice.'
    //     },
    //     { 
    //       label: 'item',
    //       icon: <FileCopyIcon />,
    //       path: 'invoice/item',
    //       rights:'proffix.invoice.item.'
    //     },
    //     { 
    //       label: 'common_scheduler', 
    //       icon: <TodayRoundedIcon />, 
    //       path: 'invoice/scheduler',
    //       rights:'proffix.invoice.scheduler.' 
    //     }
    //   ]
    // },
    { 
      label: 'sidebar_create',
      icon: <AddBoxOutlinedIcon />,
      path: 'invoice/createinvoice',
      rights:'proffix.invoice.createinvoice.'
    },
    // { 
    //   label: 'common_template', 
    //   icon: <BackupTableOutlinedIcon />, 
    //   path: 'template',
    //   rights:'proffix.template.' 
    // }, 
    // { 
    //   label: 'address', 
    //   icon: <ContactsIcon />, 
    //   path: 'address',
    //   rights:'proffix.address.' 
    // },
    // { 
    //   label: 'common_module', 
    //   icon: <DatasetIcon />, 
    //   path: 'module',
    //   rights:'proffix.module.' 
    // },
    { 
      label: 'common_log', 
      icon: <HistoryIcon />, 
      path: 'log',
      rights:'proffix.log.' 
    },

];
function MainLayout() {
  const [open, setOpen] = useState(isOpened || true);
  // const [openMenu, setOpenMenu] = useState(false);
  // const [isThemeChanged, isSetThemechange] = useState(false);
  // const { logout } = useAuth();
  const [themeMode, setThemeMode] = useState('dark');
  const { i18n, t } = useTranslation(["language", "common"]);
  const [darkMode, setDarkmode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [mainMenuList, setMainMenuList] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [updatedMenuList, setUpdatedMenuList] = useState([]);
  const [openCacheModal, setOpenCacheModal] = useState(false);
  const handleOpenCacheModal = () => setOpenCacheModal(true);
  const handleCloseCacheModal = () => setOpenCacheModal(false);
  const {onChangeMode } = useContext(ConfigContext);
  const menuOpen = Boolean(anchorEl);
  const currentLanguage = i18n.language;
  const handleClick = (event) => {
    setClicked(true);
    setAnchorEl(event.currentTarget);
  };
  const { isLoggedIn,login,logout} = useAuth();
  const navigate = useNavigate();
  const logoutSession = ()=>{
    let cookieExist = getCookie(process.env.PERMISSION_COOKIE_NAME);
    if (cookieExist !== "") {
      clearCookieStorage();
    } else {
      removeEncryptedItem('loginCookieData')
      clearCookieStorage();
    }
  }
  const theme  =useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  // const matchDownMD = useMediaQuery(theme.breakpoints.down(1024));
  // const handleClickMenu = () => {
  //   setOpenMenu(!open);
  // };

  const handleLogout = () => {
    handleClose();
    logoutSession();

    //let encryptedData = encryptForm(process.env.REACT_APP_REDIRECT_PROFFIX_URL, process.env.REACT_APP_ENCRIPTIONKEY);
     //window.location.assign(process.env.REACT_APP_MTCURL + '/auth/login/' + encodeURIComponent(encryptedData));
  }

  const handleClose = () => {
    setAnchorEl(null);
    setClicked(false);
  };

  const location = useLocation();
  useEffect(()=>{
    if(matchDownLG){
      setOpen(false);
    }else{
      setOpen(true);
    }
  },[matchDownLG])

  const handleDrawerOpen = (eve) => {
    setOpen(!open);
    // if (eve) {
    //   setOpen(!isOpened);
    //   isOpened = false;
    // }
    // else {
    //   setOpen(isOpened);
    //   isOpened = true;
    // }
  };
  // const font = {
  //   fontSize: 14,
  //   fontFamily: [
  //     '-apple-system,BlinkMacSystemFont', "Segoe UI", 'Roboto', "Helvetica Neue", 'Arial', "Noto Sans", 'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  //   ].join(','),
  // }
  // const darkTheme = createTheme({
  //   palette: {
  //     mode: "dark",
  //     primary: {
  //       main: '#fff',
  //       text: '#fff',
  //       label: '#fff',
  //       icon: '#fff'
  //     },
  //     secondary: {
  //       main: '#000',
  //       text: '#fff',
  //       label: '#fff',
  //       icon: '#fff'
  //     }
  //   },
  //   typography: font,
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 768,
  //       md: 1024,
  //       lg: 1266,
  //       xl: 1440
  //     },
  //   },
  // });
  // const whiteTheme = createTheme({
  //   palette: {
  //     mode: "light",
  //     primary: {
  //       main: '#000',
  //       text: '#000',
  //       label: '#000',
  //       icon: '#000'
  //     },
  //     secondary: {
  //       main: '#fff',
  //       text: '#000',
  //       label: '#000',
  //       icon: '#000',
  //     }
  //   },
  //   typography: font,
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 768,
  //       md: 1024,
  //       lg: 1266,
  //       xl: 1440
  //     },
  //   },
  // });

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      // console.log(localStorage.getItem("i18nextLng"));
      i18next.changeLanguage("de");
    }
  }, [t]);

  const handleLogoClick = async()=>{
    let value = await CheckRights(menuLists) || [];
    if(value.length > 0)
    {
      navigate("/"+value[0]?.path);
      setMainMenuList(true);
    }
  }

  useLayoutEffect(() => {
    const menuUpdate =async()=>{
      let value = []
      if(mainMenuList){
        value = await CheckRights(menuLists);
      }else{
        value = await CheckRights(invoiceManagementMenuList);
      }
      
      if(value.length > 0)
      {
        navigate("/"+value[0].path);
        setUpdatedMenuList(value);
      }else{
        logout();
      }
    }
    if(isLoggedIn){
      menuUpdate ();
    }else{
      login()
    }
  },[isLoggedIn,login,logout,mainMenuList])

  useEffect(() => {
    try {
      let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
    } catch (error) {
      logoutSession();
    }

  }, [clicked]);

  useEffect(() => {
    // let themeMod = localStorage.getItem("theme");
    let themeMod = getEncryptedItem('theme');
    
    if ((themeMod === null)||(themeMod === undefined)) return (setEncryptedItem('theme', 'dark'), setThemeMode('dark'))
    setThemeMode(themeMod);
  }, [darkMode]);

  const changeTheme = () => {
    onChangeMode(themeMode);
    setDarkmode(!darkMode);
    // if (themeMode === 'light') return localStorage.setItem("theme", 'dark');
    // localStorage.setItem("theme", 'light');
  };

  const changeMenu = () => {
    setMainMenuList(!mainMenuList);
  };

  return (
    ((updatedMenuList.length >0) ? 
    <>
    <CssBaseline />
    <Box sx={{ display: 'flex', position: 'relative', zIndex: 1 }}>
      <AppBar position="fixed" xs={12} sm={8} md={6} lg={4} open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: (theme) => theme.palette.secondary.main }}>
        <Toolbar className='' sx={{ display: 'flex', flex: '0 0 100%' }}>
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleDrawerOpen(isOpened)}
              edge="start"
              sx={{ mt: 0, mr: 2, ...(open) }}
              className='togglenavbtn'
            >
              <CustomMenuIcon sx={{ color: '' }} />
            </IconButton>
          </div>
          <Box className='left_section'>
            <Companylogo handleLogoClick={handleLogoClick}/>
          </Box>
          <Box sx={{ display: { xs: 'flex', sm: 'flex' }, flex: '0 0 25%', justifyContent: 'end', marginLeft: '20px', height: '64px' }}>
            <Button title={currentLanguage === 'en' ? 'English' : 'German'}>
              <LanguageMenu />
            </Button>
            <Button key="HELP"  onClick={changeTheme}>
              <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                {themeMode === 'dark' ? 
                  <LightModeIcon />: 
                  <DarkModeRoundedIcon />
                }
              </ListItemIcon>
            </Button>
            <Button key="InvoiceManagement"  onClick={changeMenu}>
              <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                  <SettingsIcon sx={{ color: (!mainMenuList) ? '#8B9635' : '' }} />
              </ListItemIcon>
            </Button>
            <Button key="AccountSettings" title={t('account_settings')} sx={{ color: '', justifyContent: 'center' }} onClick={handleClick}>
              <Avatar sx={{ width: 32, height: 32, justifyContent: 'center', ml: 2 }}><CustomHomeIcon /></Avatar>
                {clicked ? 
                  <ArrowDropUpIcon /> : 
                  <ArrowDropDownIcon />
                }
            </Button>
          </Box>
          <CustomMenu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem sx={{ pointerEvents: 'none' }} >
              <Avatar />
              <div style={{ padding: '0px 0px 0px 10px', display: 'flex', flexDirection: 'column' }}>
                <b>{userDetails?.username}</b>
                <span>{userDetails?.email}</span>
              </div>
            </MenuItem>
            <CustomBtn onClick={handleLogout} sx={{ width: '100%', flex: ' 0 0 100%', mt: 1 }}>
              <Logout fontSize="small" sx={{ pr: 1 }} />
              {t('logout')}
            </CustomBtn>
          </CustomMenu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        sx={{
          position: 'relative',
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          [`& .MuiList-root`]: { paddingTop: '0px' },

        }}
        anchor="left"
        open={ open}
        className='sideNav'
      >
        <Toolbar />
        <Divider />
        <List>
          {updatedMenuList.map((menu, index) => (
              menu.children ? 
                <NavItemCollapse menu={menu} key={index} location={location} />:
                <NavItem menu={menu} key={index} location={location} isChild={false}/>
            ))
          }
        </List>
        <Box sx={{ display: 'flex', position: 'absolute', bottom: '10px',left:'10px' }}>
          {(!matchDownLG)?
            <CustomBtn  onClick={handleOpenCacheModal} startIcon={<RefreshIcon />}>
            {t('common_clear_cache')}
            </CustomBtn>:
            <CustomBtn  onClick={handleOpenCacheModal} startIcon={<RefreshIcon />} sx={{padding :'0px'}}/>}
        </Box>
      </Drawer>
      <Main open={open} sx={{ marginTop: "40px" }}>
        <Outlet/>
      </Main>
    </Box>
    {/* <NotificationSnackbar /> */}
    <Modal
      open={openCacheModal}
      onClose={handleCloseCacheModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBoxClearCache>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={handleCloseCacheModal}>
            <CloseIcon sx={{ color: "#8B9635" }} />
          </IconButton>
        </div>
        <div style={{ padding: "0px 25px 20px 25px", textAlign: 'start' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('common_clear_cache')}
          </Typography>
          <p id="child-modal-description">
            {t('confirm_clear_cache')}
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CustomBtn onClick={handleCloseCacheModal} sx={{ mt: 2 }}>
              {t('common_no')}
            </CustomBtn>
            <CustomBtn onClick={handleLogout} sx={{ mt: 2 }}>
              {t('common_yes')}
            </CustomBtn>
          </div>
        </div>
      </CustomBoxClearCache>
    </Modal>
  </>
  :null)
    

  );
}

export default MainLayout;
