import React, { useCallback, useEffect, useState,useRef,useReducer } from "react";
import "../log/log.scss";
import { Autocomplete, Button, CssBaseline, Grid, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import CustomDataGridTable from "../../shared/common/utilites/customDategrid";
import debounce from 'lodash.debounce'
import { createObject, encryptForm } from "../../services/commonService";
import { getActions, getLog, loadAddress } from "../../services/Services";
import { useFormik } from "formik";
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import { DataGridActionTypes, DataGridReducer } from "../../reducer/datagridReducer";
import styled from "@emotion/styled";

const CustomBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  border: '2px solid #8B9635',
  width:'100%',
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  "&:hover": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "#000",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));

const defaultSearch = {
  company_name: "",
  invoice_id: "",
  page: 1,
  pageSize: 20,
  column:"",
  order:"",
  action:""
};

const defaultAddressParams = { 
  page: 1, 
};

const schedulerInitialState ={
  data:[],
}

const CreditLogs = () => {
  const { t,i18n } = useTranslation(["language", "common"]);
  const [logData, setLogData] = useState([]);
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  // const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [logListCount, setLogListCount] = useState(0);
  const [searchlog, setSearchlog] = useState(defaultSearch);
  const [addressOptionParams, setAddressOptionParams] = useState(defaultAddressParams);
  const isSearchAddress = useRef(true);
  const [isMounted, setIsMounted] = useState(true);
  const [addressData, setAddressData] = useState([]);
  const [addressOption, addressOptionDispatch] = useReducer(DataGridReducer, schedulerInitialState);
  const [searchColumnAddress, setSearchColumnAddress] = useState("");
  const [actions, setActions] = useState([]);

  const initialValuesSearchLog = {
    company_name: "",
    company_id:"",
    invoice_id: "",
    action:""
  };

  useEffect(()=>{
    const flattenedArray = [].concat(...addressOption.data);
    setAddressData([flattenedArray]);
  },[addressOption]);


  const formikLog = useFormik({
    initialValues: initialValuesSearchLog,
    onSubmit: (values) => {
      let action = values.action ? values.action.name : '';
      setSearchlog((prevSearchlog) => ({
        ...prevSearchlog,
        company_name: values.company_name,
        invoice_id: values.invoice_id,
        action:action,
        page: 1,
      }));
    },
  });

  const cancelSearch = () => {
    formikLog.resetForm();
    isSearchAddress.current = true ;
    setSearchColumnAddress("");
    setSearchlog(defaultSearch);
  };

  const loadLogList = useCallback(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const d = new FormData();
      d.append("limit", searchlog.pageSize);
      d.append("page", searchlog.page);
      d.append("column", searchlog.column);
      d.append("order", searchlog.order);
      d.append("companyName",searchlog.company_name ? searchlog.company_name : "");
      d.append("invoiceId", searchlog.invoice_id ? searchlog.invoice_id : "");
      d.append("action", searchlog.action ? searchlog.action : "");
     // d.append("isAuthUser", "");

      let object = createObject(d);
      let encryptedToken = encryptForm(object);
      setTableLoader(true);
      setLogData([]);
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      getLog(encrData, signal)
        .then((res) => {
          if (isMounted) {
            setTableLoader(false);
            if (res.status) {
              if (res.data.length > 0) {
                setLogData(res.data);
                setLogListCount(Number(res.filtered));
              } else {
                setLogListCount(0);
              }
            } else {
              openAlertSnackBar('error',res.message,'127px');
            }
          }
        })
        .catch((err) => {
          if (isMounted) {
            setTableLoader(false);
            openAlertSnackBar('error','common_alert','127px');
          }
        });

      return () => {
        controller.abort();
        setIsMounted(false);
      };
    },
    [searchlog,isMounted]
  );

  const loadAddressData = useCallback(() => {
    const controller4 = new AbortController();
    const signal4 = controller4.signal;
    const d = new FormData();
    d.append("limit", 100);
    d.append("page", isSearchAddress.current ? 1 : addressOptionParams.page);
    d.append("column", "companyname");
    d.append("order", "asc");
    d.append("search", searchColumnAddress);
    d.append("iscompanyname", true);
  //d.append("isAuthUser", '');
    // setAddressData([]);
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    // const getTemplateData = setTimeout(() => {
       loadAddress(encrData, signal4)
        .then((res) => {
            if (res.status) {
              // setAddressData(res.data);
              if(isSearchAddress.current)
                {
                  addressOptionDispatch({
                    type:DataGridActionTypes.ASSIGN_DATA,
                    payload:res.data
                  })
                }else{
                  addressOptionDispatch({
                    type:DataGridActionTypes.APPEND_DATA,
                    payload:res.data
                  })
                }
            } else {
              openAlertSnackBar('error',res.message,'127px');
            }
        })
        .catch((err) => {
          openAlertSnackBar('error','common_alert','127px')
        })
    // }, 1000);

    return () => {
      controller4.abort();
      // clearTimeout(getTemplateData);
      // setIsMounted(false);
    };
  }, [searchColumnAddress,addressOptionParams]);

  useEffect(() => {
    loadAddressData();
  }, [loadAddressData]);

  const handleSortModelChange = (newModel) => {
    // setSortModel(newModel);
    if (newModel.length > 0) {
      setSearchlog((prevSearchlog)=>({
        ...prevSearchlog,
        page:1,
        column:newModel[0].field,
        order:newModel[0].sort
      }))
    }else{
      setSearchlog((prevSearchlog)=>({
        ...prevSearchlog,
        page:1,
        column:"",
        order:""
      }))
    }
  };
  const companyListRef = useRef(null);

  const handleScrollAddress = (event) => {
    const { target } = event;
    const lastEntryIndex = addressData[0].length - 10; 
    const count = Math.ceil((addressData[0].length)/100); 

    if (
      target.scrollTop + target.clientHeight >=
      target.scrollHeight - target.children[lastEntryIndex].clientHeight
    ) {
      isSearchAddress.current = false;
      debouncedFilterScroll(count+1);
    }
  };

  const debouncedFilterScroll = debounce((query) =>
  setAddressOptionParams({page:query})
, 300)

  const handlePageChange = (event, newPage) => {
    const pageValue = event + 1;
    setSearchlog((prevSearchlog) => ({
      ...prevSearchlog,
      page: pageValue,
    }));
  };

  // const actions =[
  //   {
  //     cid:1,
  //     code:'CREDIT',
  //     label:'Credit'
  //   }
  // ]

  const handlePageSizeChange = (event) => {
    setSearchlog((prevSearchlog) => ({
      ...prevSearchlog,
      pageSize: event,
      page:1
    }));
  };

  const columns = [
    {
      field: "date",
      headerName: t("date"),
      flex: 1.6,
      valueFormatter: (params) => formatDate(params.value),
    },
    { field: "username", headerName: t("user"), flex: 1.5 },
    { field: "companyname", headerName: t("company"), flex: 1.5 },
    { field: "invoiceid", headerName: t("invoiceid"), flex: 1 ,sortable: false},
    { field: "type", headerName: t("type"), flex: 1 },
    { field: "name", headerName: t("action"), flex: 1.3 },
    { field: "oldvalue", headerName: t("oldvalue"), flex: 0.8,sortable: false },
    { field: "newvalue", headerName: t("newvalue"), flex: 0.8,sortable: false },
    { field: "message", headerName: t("message"), flex: 3,sortable: false }
  ];

  const getActionList = useCallback(() => {
    const controllerAction = new AbortController();
    const signalAction = controllerAction.signal;
    const d = new FormData();
    d.append("search", '');
   // d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
      getActions(encrData, signalAction)
        .then((res) => {
            if (res.status) {
              console.log(res.data);
              setActions(res.data);
            } else {
              openAlertSnackBar('error',res.message,'127px');
            }
        })
        .catch((err) => {
          openAlertSnackBar('error','common_alert','127px')
        })

    return () => {
      signalAction.abort();
    };
  }, []);

  useEffect(() => {
    getActionList();
  }, [getActionList]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        formikLog.handleSubmit()
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    loadLogList();
    return() =>{
      closeAlertSnackBar();
    }
  }, [loadLogList]);

  const currentLanguage = i18n.language;

  const handleKeyDown = (event,value) => {
    event.preventDefault();
    if ((event.target.value === "" )) {
      formikLog.setFieldValue("address", "");
    }
      debouncedFilter(event.target.value);
  };

  const debouncedFilter = debounce(query =>
      setSearchColumnAddress(query)
    , 1000)

  const handleClearField = () => {
    if (formikLog.values.address === "") {
      isSearchAddress.current = true ;
      setSearchColumnAddress("");
      formikLog.setFieldValue("company_name", "");
    }
  };

  const handleAddressChange = (event)=>{
    isSearchAddress.current = true ;
    if(!event)
    {
      setSearchColumnAddress("");
      formikLog.setFieldValue("company_name", '');
      formikLog.setFieldValue("company_id", '');
      return;
    }
    const addressItem =addressData[0].filter(item=>item.cid ===event.cid)
    formikLog.setFieldValue("company_name", addressItem.length>0 ?addressItem[0].companyname:'');
    setSearchColumnAddress(addressItem[0].companyname);
    formikLog.setFieldValue("company_id",event);
  }

  const formatDate = useCallback(
    (data) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      };

      if (currentLanguage === "en") {
        return new Date(data).toLocaleDateString("en-US", options);
      } else {
        return new Date(data).toLocaleDateString("de-DE", options);
      }
    },
    [currentLanguage]
  );

  return (
    <>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, margin: "10px 0 0 0" }}>
        <Grid container>
            <Grid item container xs={12}  sx={{alignItems:"center"}}>
              <Grid item xs={12} lg={4} md={12}  >
                  <Typography
                    variant="h6"
                    noWrap
                    component="span"
                  >
                    {t("common_log")}
                  </Typography>
              </Grid>
              <Grid item container xs={12} lg={8} md={12} spacing={1} sx={{alignItems:"center"}}>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    fullWidth
                    sx={{  width: "100%" }}
                    value={formikLog.values.action||null}
                    // ListboxProps={{
                    //   ref: companyListRef,
                    //   onScroll: handleScrollAddress,
                    //   className: "autocompleteList",
                    //   style:{maxHeight: '225px'}
                    // }}

                    // onChange={formikLog.handleChange}
                    onChange={(event, value) => {
                      // handleAddressChange(value.name);
                      formikLog.setFieldValue("action", value);
                    }}
                    options={actions}
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option?.cid}>
                        {option?.name}
                      </Box>
                    )}
                    ListboxProps={{
                      className: "autocompleteList",
                      style:{maxHeight: '225px'}
                    }}
                    // getOptionLabel={(cid) => {
                    //   const option = addressData.find((option) => option.cid === cid);
                    //   return option ? option.companyname : "";
                    // }} {actions?.map((option) => (
                  //     <MenuItem key={option.cid} value={option.name}>
                  //     {option.name}
                  //   </MenuItem>
                  // ))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("action") }
                        // onChange={handleKeyDown}
                        // value={ searchColumnAddress}
                        // onBlur={handleClearField}
                      />
                    )}
                  />
                  {/* <TextField
                    id="action"
                    label={t("action")}
                    name="action"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    select
                    value={formikLog.values.action}
                    onChange={formikLog.handleChange}
                    fullWidth
                    sx={{ width: "100%",m:'0px' }}
                  >
                    {actions?.map((option) => (
                      <MenuItem key={option.cid} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField> */}
                 </Grid>
                 <Grid item xs={12} sm={4} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    fullWidth
                    sx={{  width: "100%" }}
                    value={formikLog.values.company_id||null}
                    isOptionEqualToValue={(option, value) => option.cid === value.cid}
                    ListboxProps={{
                      ref: companyListRef,
                      onScroll: handleScrollAddress,
                      className: "autocompleteList",
                      style:{maxHeight: '225px'}
                    }}
                    onChange={(event, value) => {
                      handleAddressChange(value);
                      // handleKeyDown(event, value);
                    }}
                    inputValue={searchColumnAddress}
                    onInputChange={(event, newInputValue) => {
                      isSearchAddress.current =true;
                      setSearchColumnAddress(newInputValue);
                    }}
                    options={addressData[0]}
                    getOptionLabel={(option) => option?.companyname}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option?.cid}>
                        {option?.companyname}
                      </Box>
                    )}
                    // getOptionLabel={(cid) => {
                    //   const option = addressData.find((option) => option.cid === cid);
                    //   return option ? option.companyname : "";
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("common_company_name") }
                        // onChange={handleKeyDown}
                        // value={ searchColumnAddress}
                        // onBlur={handleClearField}
                      />
                    )}
                  />
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    fullWidth
                    sx={{  width: "100%" }}
                    value={formikLog.values.company_id||null}
                    ListboxProps={{
                      ref: companyListRef,
                      onScroll: handleScrollAddress,
                      className: "autocompleteList",
                      style:{maxHeight: '225px'}
                    }}
                    onChange={(event, value) => {
                      handleAddressChange(value);
                    }}
                    options={addressData[0]}
                    getOptionLabel={(option) => option?.companyname}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option?.cid}>
                        {option?.companyname}
                      </Box>
                    )}
                    // getOptionLabel={(cid) => {
                    //   const option = addressData.find((option) => option.cid === cid);
                    //   return option ? option.companyname : "";
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("common_company_name") }
                        onChange={handleKeyDown}
                        value={ searchColumnAddress}
                        onBlur={handleClearField}
                      />
                    )}
                  /> */}
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    <TextField
                      id="invoice_id"
                      name="invoice_id"
                      label={t("invoiceid")}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size="small"
                      sx={{ width: "100%", margin: "0px"}}
                      // {...formikLog.getFieldProps("invoiceid")}
                      value={formikLog.values.invoice_id}
                      onChange={formikLog.handleChange}
                    />
                  </Grid>
                  <Grid item container xs={12} sm={12} lg={3} md={3} spacing={1} justifyContent='flex-end'>
                  <Grid item xs={12} sm={2} lg={6} md={6} >
                  <CustomBtn
                                type="reset"
                                variant="contained"
                                onClick={cancelSearch}
                                size="small"
                                severity="sucess"
                                // sx={{width:'100%'}}
                                // sx={{ alignSelf: "center", margin: "10px 10px" }}
                                // sx={{ margin: "10px 10px" }}
                              >
                                {t("common_cancel")}
                              </CustomBtn>
                    {/* <CustomButton
                      label={t("common_cancel")}
                      action={cancelSearch}
                      margin={"0px 10px 0px 0px"}
                    /> */}
                     </Grid>
                     <Grid item xs={12} sm={2} lg={6}  md={6}>
                     <CustomBtn
                                onClick={formikLog.handleSubmit}
                                variant="contained"
                                size="small"
                                severity="sucess"
                                sx={{ alignSelf: "center"}}
                              
                              >
                                {t("common_search")}
                              </CustomBtn>
                      {/* <CustomButton label={t("common_search")} action={formikLog.handleSubmit} /> */}
                  </Grid>
                  </Grid>
              </Grid>
            </Grid>
            {/* <CustomBox sx={{ flexGrow: 1, margin: "10px 0 0 0" }}> */}
            <Grid item md={12} xs={12} style={{ height: "755px",marginTop: '12px' }}>
              <CustomDataGridTable
                // rowId={(row, i) => logData.indexOf(row)}
                rowId={(row, i) => parseInt(row.row_number)}
                data={logData}
                columns={columns}
                pagesize={handlePageSizeChange}
                currentPage={searchlog.page}
                handleSort={handleSortModelChange}
                limit={searchlog.pageSize}
                // selection={handleSelectionModelChange}
                // selectedRowId={selectedRowIds}
                isLoader={tableLoader}
                rowCount={logListCount}
                pageChange={handlePageChange}
              />
            </Grid>
            {/* </CustomBox> */}
        </Grid>
      </Box>
    </>
  );
};

export default CreditLogs;
