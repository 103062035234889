import React, { useCallback } from "react";
import { useTheme } from '@mui/material/styles';
import {Grid} from '@mui/material';
import "../reminder-list/reminder-list.scss";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { Tabs } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { GridFilterPanel } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { fetchReminderList, gate2BUI, loadPdf, sendReminderMail } from "../../services/Services";
import { useTranslation } from "react-i18next";
import Loader from "../../shared/common/utilites/PageLoader/PageLoader";
import { createObject, encryptForm } from '../../services/commonService';
import { CustomModal } from "../../shared/common/utilites/customModal";
import { getEncryptedItem } from "../../services/localStorageUtil";
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";




const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border:
    theme.palette.mode === "light"
      ? "1px solis #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
  "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#8B9635",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "unset",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "unset",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    "& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#8B9635",
      color: theme.palette.mode === "light" ? "#fff" : "#000"
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
    border: 'none',
  },
  "& .MuiSvgIcon-root .MuiSvgIcon-fontSizeSmall": {
    display: 'none',
  },
}));

const CustomDiv = styled("div")(({ theme }) => ({
  display: "",
  flex: "0 0 100%",
  borderRadius: "10px",
  border: theme.palette.mode === "light" ? "1px solid #ccc" : "1px solid #333",
}));

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .commonqueueTab": {
    textTransform: "none",
    minHeight: '36px',
  },
  '&.MuiTabs-root': {
    minHeight: '0px',
  },
  "& .css-azyy17": {
    textTransform: "none",
  },
  "& .MuiButtonBase-root.Mui-selected": {
    color: theme.palette.mode === "light" ? "#000" : "#ffff",
  },
}));

const CustomGridPanel = styled(GridFilterPanel)(({ theme }) => ({
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.mode === "light" ? "#000" : "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.mode === "light" ? "#000" : "#fff",
  },
}));

const CustomBtn = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  width:'100%',
  border: '2px solid #8B9635',
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  "&:hover": {
    backgroundColor: "#8B9635",
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  '& .css-151k6oy-MuiButtonBase-root-MuiButton-root': {
    margin: '0px 10px 0px 0px',
  },
  "&.MuiLoadingButton-root.Mui-disabled": {
    background: theme.palette.mode === "light" ? "#fff" : "#000",
    padding: "0px 0px",
    span: {
      color: "#8B9635",
    },
  },
}));


const initialValues = {
  invoice_date: null,
  invoice_number: "",
  address: "",
  due_date: null,
};

function CustomLoadingOverlay() {
  return <Loader />;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const defaultReminderListParams = {
  invoice_number: '',
  invoice_date: '',
  address: '',
  due_date: '',
  page: 1,
  sort: '',
  column: '',
  limit: 20
}

const marginTopValue = '131px';

function ReminderList() {
  const [isLoading, setIsLoading] = useState(true);
  const [reminderListData, setReminderListData] = useState(([]));
  const { t, i18n } = useTranslation(["language", "common"]);
  const [tabIndex, setTabIndex] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [cid, setCid] = useState(null);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [email, setEmail] = useState(null);
  const [reminderListParams, setReminderListParams] = useState(defaultReminderListParams);
  const [pdfData, setPDFData] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [isReminderListUpdated, setIsReminderListUpdated] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [lastReminderListUpdatedTime, setLastReminderListUpdatedTime] = useState("");
  const [reminderListCount, setReminderListCount] = useState(0);
  const [openSendModal, setOpenSendModal] = useState(false);
  const currentLanguage = i18n.language;

  const theme = useTheme();

  const handleOpenSendModal = () => {
    let cutomerEmail = null;
    const reminderValue = reminderListData.filter((item)=>item.cid === cid)[0]
    if(reminderValue)
    {
      cutomerEmail=reminderValue.email;
    }
    setEmail(cutomerEmail)
    setOpenSendModal(true)
  };
  const handleCloseSendModal = () => {
    setOpenSendModal(false);
  }

  const handleSelectionModelChange = (newSelection) => {
    setTabIndex(0);
    setPDFData(null);
    if ((newSelection.length === 0) || (selectedRowIds[0] === newSelection[0])) {
      setSelectedRowIds([]);
      setSelectedRow([]);
      setCid(null);
      return
    }
    setSelectedRowIds(newSelection);
    const filteredObject = reminderListData.filter(
      (obj) => obj.BuchungNr === newSelection[newSelection.length - 1]
    )
    if (filteredObject.length === 0) {
      setSelectedRow([]);
      return
    }
    setSelectedRow(filteredObject[0]);
    const cidValue = filteredObject[0].cid ? filteredObject[0].cid : '';
    setCid(cidValue);
  };

  useEffect(() => {
    let userData = getEncryptedItem('userData');
    if (userData) {
      let userDataobj = JSON.parse(userData)
      setUserDetails(userDataobj);
    }
  }, []);

  const sendModalContent = () => {
    return (
      <>
        <p id="child-modal-description">
          {email ? t('confirm_send_reminder')+' '+ email +'?': t('confirm_send')}
        </p>
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item xs={6}>
            <CustomBtn onClick={handleCloseSendModal} sx={{ mt: 2 }}>
              {t('common_cancel')}
            </CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn onClick={handleReminderSend} sx={{ mt: 2 }}>
              {t('common_send')}
            </CustomBtn>
          </Grid>
        </Grid>
      </>
    )
  }

  const handleReminderSend = () => {
    if (!cid) { return }
    handleCloseSendModal();
    const currentLanguage = i18n.language;
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(cid));
    _formData.append("sendType", 'reminder');
    _formData.append("language", currentLanguage);
  //  _formData.append("isAuthUser", '');
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setSendLoading(true);
    sendReminderMail(encrData)
      .then(res => {
        setSendLoading(false);
        if (res.status) {
          openAlertSnackBar('success',res.message,marginTopValue);
        } else {
          openAlertSnackBar('error',res.message,marginTopValue);
        }
      }).catch((err) => {
        setSendLoading(false);
        openAlertSnackBar('error','common_alert',marginTopValue)
      });
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      let invoiceDateVar = null;
      let dueDateVar = null;
      if (values.invoice_date !== null) {
        invoiceDateVar = DateConverter(values.invoice_date);
      }
      if (values.due_date !== null) {
        dueDateVar = DateConverter(values.due_date);
      }
      setReminderListParams((previousSearch) =>
      ({
        ...previousSearch,
        invoice_number: values.invoice_number,
        invoice_date: invoiceDateVar,
        address: values.address,
        due_date: dueDateVar,
        page: 1
      }));
    },
  });

  const formattedAmount = (amount) => {
    return amount.toLocaleString('de-CH', {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const DateConverter = (dateForm) => {
    let formattedDate = ''
    if (dateForm !== null) {
      const date = dateForm.$d;
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      formattedDate = `${year}-${month}-${day}`;
    }
    return formattedDate;
  }

  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setReminderListParams((previousSearch) => ({
        ...previousSearch,
        sort: newModel[0].sort,
        column: newModel[0].field,
        page: 1
      }))
    } else {
      setReminderListParams((previousSearch) => ({
        ...previousSearch,
        sort: "",
        column: "",
        page: 1
      }))
    }
  };

  const getPdfData = useCallback(() => {
    setPdfLoader(true);
    setPDFData(null);
    const _formData = new FormData();
    _formData.append("id", JSON.stringify(cid));
    // _formData.append("isAuthUser", '');
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadPdf(encrData)
      .then(res => {
        setPdfLoader(false);
        if (res.status) {
          setPDFData(res.data);
        }
      });
  }, [cid])

  const localeText = {
    noRowsLabel: t('common_no_rows'),
    footerRowSelected: (count) => `${count} ${t('common_row_selected')}`,
  };

  function CustomNoRowsOverlay() {
    const { t } = useTranslation(["language", "common"]);
    return (
      <GridOverlay>
        <Typography>{t("common_no_data_available")}</Typography>
      </GridOverlay>
    );
  }

  const lastSelectedRowIndex =
    selectedRowIds.length > 0
      ? selectedRowIds[selectedRowIds.length - 1]
      : null;

  const getRowClassName = useCallback(
    (params) => {
      if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
        return "commonSelected";
      }
      if (lastSelectedRowIndex === null) {
        return "commonSelected";
      }
      return "";
    },
    [lastSelectedRowIndex]
  );

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    (newValue === 1) ? (cid && getPdfData()) : setPDFData(null);
  };

  const cancelSearch = () => {
    formik.resetForm();
    setReminderListParams(defaultReminderListParams);
  };

  const handlePageChange = (event, newPage) => {
    setReminderListParams((previousSearch) => ({
      ...previousSearch,
      page: event + 1
    }))
  };

  const handlePageSizeChange = (event) => {
    setReminderListParams((previousSearch) => ({
      ...previousSearch,
      limit: event,
      page: 1
    }));
  };

  const formatDate = useCallback((data) => {
    if (currentLanguage === "en") {
      return new Date(data).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else {
      return new Date(data).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }
  }, [currentLanguage]);

   const columns = [
    { field: "Belegnummer", headerName: t("Belegnummer"),  minWidth: 110,flex: 1 },
    {
      field: "Belegdatum",
      headerName: t("Belegdatum"),
      // minWidth: 100,
      flex: 1,
      valueFormatter: (params) => formatDate(params.value)
    },
    {
      field: "Kunde", headerName: t("customer"),
      minWidth: 180,
      flex: 2
    },
    {
      field: "WaehrungNr",
      headerName: t("WaehrungNr"),
      // minWidth: 90,
      flex: 0.6,
      sortable: false,
    },
    {
      field: "BetragFW",
      headerName: t("BetragFW"),
      // minWidth: 90,
      flex: 1,
      valueFormatter: (params) => formattedAmount(parseFloat(params.value)),
      sortable: false,
    },
    {
      field: "Verfalldatum",
      headerName: t("Verfalldatum"),
      // minWidth: 110,
      flex: 1,
      valueFormatter: (params) => formatDate(params.value)
    },
    {
      field: "module",
      headerName: t("common_module"),
      // minWidth: 120,
      flex: 1,
      sortable: false,
    },
    {
      field: "sentvia",
      headerName: t("Send Via"),
      // minWidth: 80,
      flex: 1,
      sortable: false,
    }
  ];
  
  const formatDateWithTime = useCallback((data) => {
    if (currentLanguage === "en") {
      return new Date(data).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    } else {
      return new Date(data).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    }
  }, [currentLanguage])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        formik.submitForm();
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);
  
  const loadInvoiceList = useCallback(
    (isMounted) => {
      let obj = {
        invoice_number: reminderListParams?.invoice_number,
        companyname: reminderListParams?.address,
        invoice_date: reminderListParams?.invoice_date,
        due_date: reminderListParams?.due_date,
        column: reminderListParams?.column,
        page: reminderListParams?.page,
        sorting: reminderListParams?.sort,
        limit: reminderListParams.limit,
      };
      setTabIndex(0);
      const d = new FormData();
      d.append("message", JSON.stringify(obj));
      // d.append("isAuthUser", '');
      let object = createObject(d);
      let encryptedToken = encryptForm(object);
      setTableLoader(true);
      setIsLoading(false);
      setReminderListData([]);
      let encrData = new FormData();
      const lastUpdated = localStorage.getItem("LastUpdated");
      if (lastUpdated) {
        setLastReminderListUpdatedTime(lastUpdated);
        setIsReminderListUpdated(true);
      }
      encrData.append("encrToken", encryptedToken);
      fetchReminderList(encrData)
        .then((data) => {
          if (isMounted) {
            setTableLoader(false);
            if (data.status) {
              if (data.data.length > 0) {
                setReminderListData(data.data);
                setSelectedRow(data.data[0]);
                setSelectedRowIds([data.data[0].BuchungNr]);
                setReminderListCount(Number(data.filtered));
                if(data.data[0]?.cid)
                {
                  setCid(data.data[0].cid);
                }
              } else {
                setReminderListCount(0);
              }
            }
            else {
              openAlertSnackBar('error',data.message,marginTopValue);
            }
          }
        })
        .catch((err) => {
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue)
        });
    },
    [ reminderListParams]
  );

  const loadGate2bUI = useCallback(() => {
    const controllerLoadGate2b = new AbortController();
    const signalLoadGate2b = controllerLoadGate2b.signal;
    setTableLoader(true);
    setIsLoading(false);
    setReminderListData([]);
    const d = new FormData();
    // d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    setIsReminderListUpdated(false);
    encrData.append("encrToken", encryptedToken);
    gate2BUI(encrData, signalLoadGate2b)
      .then(res => {
        setTableLoader(false);
        if (res.status) {
          loadInvoiceList(true);
          const now = new Date();
          setLastReminderListUpdatedTime(now);
          localStorage.setItem("LastUpdated", now);
          setIsReminderListUpdated(true);
        } else {
          setTableLoader(false);
          openAlertSnackBar('error',res.message,marginTopValue);
        }
      }).catch(
        (err) => {
          setIsReminderListUpdated(false);
          setTableLoader(false);
          openAlertSnackBar('error','common_alert',marginTopValue);
        }
      );

    return () => {
      controllerLoadGate2b.abort();
    }
  }, [ loadInvoiceList]);

  useEffect(() => {
    let isMounted = true;
    loadInvoiceList(isMounted);
    return () => {
      isMounted = false;
      closeAlertSnackBar();
    };
  }, [loadInvoiceList]);


  return (
    <div className="reminder-list">
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container  spacing={1}>
          <Grid container item xs={12} spacing={1} sx={{ margin: "10px 0 10px 0"}}>
              <Grid item xs={12} md={12} lg={4}>
                <Grid 
                  container 
                  sx={{
                    alignItems:'center',
                    [theme.breakpoints.up('sm')]: {
                        flexDirection:'row'
                    }
                    }}>
                     <Grid item >
                      <Typography
                          variant="h6"
                          noWrap
                          component="span"
                          sx={{ display: "flex", alignItems: "center", flex: "0 0 35%" }}
                        >
                          {t('liste_offene_debitoren')}
                          <IconButton sx={{ fontSize: '1em', margin: '0px 0px 0px 10px' }} onClick={loadGate2bUI}><CachedIcon sx={{ color: '#8B9635' }} /></IconButton>
                        </Typography>
                     </Grid>
                     <Grid item>
                     {isReminderListUpdated &&(
                        <span className="last-updated-time">
                          {t('queue_last_updatedTime')}:{formatDateWithTime(lastReminderListUpdatedTime)}
                        </span>)
                      }
                     </Grid>
                   </Grid>
              </Grid>
              <Grid item xs={12}  md={12}  lg={8}>
                <Grid container spacing={1} justifyContent='flex-end' alignItems='center'>
                {(cid !== null) && (cid !== '') ?
                  (<Grid item xs={12} sm={2.4} md={2} justifyContent='end'>
                    <CustomBtn
                      type="submit"
                      variant="contained"
                      size="small"
                      severity="sucess"
                      loading={sendLoading}
                      onClick={handleOpenSendModal}
                      sx={{ alignSelf: "center" }}
                    >
                      {t("common_send_reminder")}
                    </CustomBtn>
                  </Grid>):null}
                <>
                <Grid container item xs={12} sm={9.6} md={10} spacing={1} justifyContent='end'>
                    <Grid item xs={12} sm={ 3} md={2.4}>
                      <TextField
                        id="address"
                        label={t('company_name')}
                        name="address"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{ width: "100%" ,margin: '0px'}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={ 3} md={2.4}>
                      <TextField
                        id="invoice_number"
                        label={t('invoice_number')}
                        name="invoice_number"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={formik.values.invoice_number}
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{  width: "100%" ,margin: '0px'}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2.4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                        <DatePicker
                          id="invoice_date"
                          name="invoice_date"
                          label={t('invoice_date')}
                          format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                          variant="small"
                          inputVariant="outlined"
                          size="small"
                          error={false}
                          slotProps={{ textField: { size: 'small' } }}
                          onKeyDown={(event) => {
                            if (event.keyCode === 8) {
                              formik.setFieldValue('invoice_date', null);
                            }
                          }
                          }
                          value={formik.values.invoice_date}
                          onChange=
                          {
                            (newValue) => {
                              formik.setFieldValue('invoice_date', newValue);
                            }
                          }
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2.4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          id="due_date"
                          name="due_date"
                          label={t('Verfalldatum')}
                          size="small"
                          value={formik.values.due_date}
                          error={false}
                          slotProps={{ textField: { size: 'small' } }}
                          format={currentLanguage === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                          onChange=
                          {
                            (newValue) => {
                              formik.setFieldValue('due_date', newValue)
                            }
                          }
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item container xs={12} md={2.4} spacing={1} justifyContent="flex-end" alignSelf='center'>
                      <Grid item xs={12} sm={2} md={7}>
                        <CustomBtn
                          type="reset"
                          variant="contained"
                          onClick={cancelSearch}
                          size="small"
                          severity="sucess"
                        >
                          {t("common_cancel")}
                        </CustomBtn>
                      </Grid>
                      <Grid item xs={12}sm={2} md={5} >
                        <CustomBtn
                          onClick={formik.handleSubmit}
                          variant="contained"
                          size="small"
                          severity="sucess"
                          sx={{ alignSelf: "center" }}
                        >
                          {t("common_search")}
                        </CustomBtn>
                      </Grid>
                    </Grid>
                  </Grid>
                  </>
                </Grid>
              </Grid>
        
            </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flex: "0 0 100%",
              [theme.breakpoints.up('md')]: {
                flexDirection:'row',
              }
            }}
          >
            <Grid sx={{ display: 'flex', flex: '1 0 48%',margin:'0px 12px 0px 0px',[theme.breakpoints.down('md')]: {
                flex: "1 0 100%",
                margin:'12px 0px 0px 0px'
              } }}>
              <div color="secondary" style={{ height: "755px", flex: "0 0 100%" }}>
                <CustomDataGrid
                  rows={reminderListData}
                  getRowId={(row) => row.BuchungNr}
                  columns={columns}
                  pageSize={reminderListParams.limit}
                  onSelectionModelChange={handleSelectionModelChange}
                  selectionModel={selectedRowIds}
                  page={reminderListParams.page - 1}
                  onSortModelChange={handleSortModelChange}
                  getRowClassName={getRowClassName}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rowHeight={33}
                  headerHeight={40}
                  pagination
                  disableColumnMenu
                  loading={tableLoader}
                  rowsPerPageOptions={[10, 20, 50]}
                  scrollbarSize={theme.breakpoints.down('md') ? 1:0}
                  paginationMode="server"
                  paginationComponent={Pagination}
                  rowCount={reminderListCount}
                  localeText={localeText}
                  components={{
                    FilterPanel: CustomGridPanel,
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: t('common_rows_per_page'),
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid sx={{ display: 'flex', flex: '1 0 48%',margin:'0px 0px 0px 12px',[theme.breakpoints.down('md')]: {
                 flex: "1 0 100%",
                 margin:'12px 0px 0px 0px'
              }}}>
              <CustomDiv sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <CustomTabs
                    value={tabIndex}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#8B9635",
                      },
                    }}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      className="commonqueueTab"
                      label={t("details")}
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="commonqueueTab"
                      label={t("common_pdf")}
                      {...a11yProps(1)}
                    />
                  </CustomTabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                  {Object.keys(selectedRow).length !== 0 ? (
                    Object.keys(selectedRow).map((item, index) =>
                      ((item === "HabenKonto" ||
                        item === "SollKonto" ||
                        item === "Adresse" ||
                        item === "BelegartNr" ||
                        item === "AdressNr" ||
                        item === "Kurs" ||
                        item === "Buchungstext" ||
                        item === "SteuercodeNr" ||
                        item === "BetragSW" ||
                        item === "BuchungNr") &&
                        selectedRow[item]) ? (
                        <span style={{ display: "flex" }} key={index}>
                          <span
                            style={{
                              display: "Flex",
                              flex: "0 0 30%",
                              padding: "10px 0px 0px 10px",
                            }}
                          >
                            {t(item)}:
                          </span>
                          <span style={{ padding: "10px 0px 0px 0px" }}>
                            {item === "Belegdatum"
                              ? formatDate(selectedRow[item])
                              : (item === "BetragSW" ? formattedAmount(parseFloat(selectedRow[item])) : selectedRow[item])}
                          </span>
                        </span>
                      ) : (
                        ""
                      )
                    )
                  ) : (
                    <p>{t('common_no_rows_selected')}</p>
                  )}
                </TabPanel>
                <TabPanel className='pdf-section' value={tabIndex} index={1} sx={{ height: '100%' }}>
                  <div style={{ position: 'relative', height: '100%' }}>
                    {cid ?
                      (pdfData !== null ? ((pdfLoader) ? (<Loader />) : <iframe
                        title="PDF Viewer"
                        src={`data:application/pdf;base64,${pdfData}`}
                        width="100%"
                        height="100%"
                      ></iframe>) : (<p>{t('no_pdf_found')}</p>)) :
                      (<p>{t('common_no_rows_selected')}</p>)
                    }
                  </div>
                </TabPanel>
              </CustomDiv>
            </Grid>
          </Grid>
        </Grid>
      )}
      <CustomModal
        openModal={openSendModal}
        onclose={handleCloseSendModal}
        header={t('common_send')}
        content={sendModalContent} />
    </div>
  );
}

export default ReminderList;
