import { encryptForm } from './commonService';

export function getEncryptedItem(item){
    if (item) {
        let encodedKey = btoa(item);
        let itemValue = localStorage.getItem(encodedKey);
        if (itemValue) {
            return atob(itemValue);
        }
    }
    return null;
}

export  function setEncryptedItem(item, itemValue) {
    if (item && itemValue) {
        let encodedKey = btoa(item);
        let encodedValue = btoa(itemValue);
        localStorage.setItem(encodedKey, encodedValue);
        return true;
    }
    return false;
}

export function removeEncryptedItem(item) {
    if (item) {
        let encodedKey = btoa(item);
        localStorage.removeItem(encodedKey);
        return true;
    }
    return false;
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function clearCookieStorage(hotReload=false) {
     localStorage.clear();
    (async() => {
      let cookies = await document.cookie.split("; ");
      for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
          let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
          let p = window.location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          };
          d.shift();
        }
      }
      redirectToLogin();
    })();
    if(hotReload)
      window.location.reload();
}

export const redirectToLogin = async()=>{
  let encryptedData =await encryptForm(window.location.href, process.env.REACT_APP_ENCRIPTIONKEY);
  await window.location.assign(process.env.REACT_APP_MTCURL + '/auth/login/' + encodeURIComponent(encryptedData));
  
}