// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baseTabsItem {
  border: "1px solis #ccc";
}

.commonSelected {
  background-color: "#8B9635" !important;
}

.pdfsection div {
  height: 97% !important;
}

.MuiTimeline-root:last-child .MuiTimelineSeparator-root .MuiTimelineConnector-root {
  display: none !important;
}

.MuiFormHelperText-root {
  margin: 0px !important;
}

.MuiInputBase-root {
  text-align: start !important;
}

.historySection {
  max-height: 660px;
  overflow-y: scroll;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.historySection::-webkit-scrollbar {
  display: none;
}

.submitAddressqueue {
  padding-left: 6px !important;
  padding-right: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/features/queue/queue.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ;;AAEA;EACI,sCAAA;AACJ;;AAGI;EACI,sBAAA;AAAR;;AAKQ;EACI,wBAAA;AAFZ;;AAOA;EACI,sBAAA;AAJJ;;AAOA;EACI,4BAAA;AAJJ;;AAMA;EACI,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AAHJ;;AAMA,gDAAA;AACA;EACI,aAAA;AAHJ;;AAMA;EACI,4BAAA;EACA,6BAAA;AAHJ","sourcesContent":[".baseTabsItem {\n    border: '1px solis #ccc'\n}\n\n.commonSelected {\n    background-color: '#8B9635' !important;\n}\n\n.pdfsection {\n    div {\n        height: 97% !important;\n    }\n}\n.MuiTimeline-root:last-child {\n    .MuiTimelineSeparator-root {\n        .MuiTimelineConnector-root {\n            display: none !important;\n        }\n    }\n}\n\n.MuiFormHelperText-root {\n    margin: 0px !important;;\n}\n\n.MuiInputBase-root{\n    text-align: start !important;\n}\n.historySection{\n    max-height: 660px;\n    overflow-y: scroll;\n    width: 100%;\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.historySection::-webkit-scrollbar {\n    display: none;\n}\n\n.submitAddressqueue{\n    padding-left: 6px !important;\n    padding-right: 6px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
