import { useContext, useMemo } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { ConfigContext } from './config';

// ==============================||  THEME - MAIN  ||============================== //

const font = {
    fontSize: 14,
    fontFamily: [
      '-apple-system,BlinkMacSystemFont', "Segoe UI", 'Roboto', "Helvetica Neue", 'Arial', "Noto Sans", 'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
    ].join(','),
  }


export default function ThemeCustomization({ children }) {
    const {mode } = useContext(ConfigContext);

    const darkTheme =useMemo(()=> ({
        mode: "dark",
        primary: {
            main: '#fff',
            text: '#fff',
            label: '#fff',
            icon: '#fff'
        },
        secondary: {
            main: '#000',
            text: '#fff',
            label: '#fff',
            icon: '#fff'
        }
    }),[]);

    const whiteTheme = useMemo(()=>({
        mode: "light",
        primary: {
            main: '#000',
            text: '#000',
            label: '#000',
            icon: '#000'
        },
        secondary: {
            main: '#fff',
            text: '#000',
            label: '#000',
            icon: '#000',
        }
    }),[]);

    const themeOptions = useMemo(() => ({
        palette : mode ==="light" ? whiteTheme :darkTheme,
        breakpoints: {
            values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1266,
            xl: 1440
            }
        },
        typography: font 
    }),[mode,darkTheme,whiteTheme]);

    const themes = createTheme(themeOptions);

    return (
        <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes}>
            <CssBaseline />
            {children}
        </ThemeProvider>
        </StyledEngineProvider>
    );
}
