import { useTheme } from "@emotion/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {  Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NavItem from "./NavItem";

const NavItemCollapse =({menu,location})=>{
    const [open, setOpen] = useState(false);

    // useEffect(() => {
        // let route = location.pathname.split('/')[1];
        // if(route === menu.path){
        //     setOpen(true);
        // }
      // }, [location]);

    const theme = useTheme();
    // console.log("NAVcOLLAPSE",menu);
    const {  t } = useTranslation(["language", "common"]);
    return(
        <>
        <ListItem  id={menu.path} disablePadding
         component={Link}
         onClick={() => setOpen(!open)}
        //  to={menu.path}
        //   onClick={(e) => menu.nested ? handleNestedMenuClick(menu.id,menu.nested): routeTo(e, index) }
          sx={(open) ? { backgroundColor: '#8B9635', color: '#000' } :{color: theme.palette.mode === "dark" ?'#fff':'#000'}}
          className={ location.pathname === '/' + menu.path ? 'selectedList' : ''}
        >
            <ListItemButton title={t(menu.label)}>
                <ListItemIcon sx={(open) ? { color: '#000', minWidth: '30px' } : { minWidth: '30px' }} >
                {menu.icon}
                </ListItemIcon>
                <ListItemText primary={t(menu.label)} />
                { (<div style={{display:'flex',margin:'0px -14px 0px 0px'}}>{open ? (<ExpandLess />) : (<ExpandMore />)}</div>)}
            </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto">
          {/* <List> */}
            {menu.children?.map((route, index) => (
                route.children ? (
                  <NavItemCollapse menu={route} key={index} location={location}/>
                ) : (
                  <NavItem menu={route} key={index} location={location} isChild={true} />
                )
            ))}
          {/* </List> */}
        </Collapse>
        </>
    )
}

export default NavItemCollapse;