import styled from '@emotion/styled';
import React from 'react';
import logo from'./logo.svg';
import logoWhite from './logoWhite.svg';
import './companylogoimage.scss';

const LogoSection =styled('div')(({theme}) => ({
  backgroundRepeat:'no-repeat',
	backgroundSize: '100%',
	height: '50px',
	width: '150px',
	display: 'block',
  cursor: 'pointer',
	// marginTop: '5px',
	// marginLeft: '5px',
  backgroundImage:theme.palette.mode === 'light' ? `url(${logoWhite})` : `url(${logo})`
}));

function Companylogo({handleLogoClick}) {
  // const clickLogo = ()=>{

  // }
  return (
    <>
      <LogoSection onClick={handleLogoClick}/>
    </>
    
  )
}

export default Companylogo