import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

const CustomCircularProgress =styled(CircularProgress)(({theme}) => ({
  color:"#8B9635"
}));

const Loader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CustomCircularProgress  />
      </div>
    );
  }

export default Loader;