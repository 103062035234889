// import axios from 'axios';
import EncryptToken from '../shared/common/utilites/encryptToken';
import api from '../shared/interceptor/apiInterceptor';

const apiUrl = process.env.REACT_APP_PROFIC_URL;
const mtcUrl = process.env.REACT_APP_API_END_POINT_MTC;
const encryptToken = EncryptToken();

export async function getQue(data) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'queue/listqueue',
            data: data
        })
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }

}

export async function pushToProffix(pushData) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'profic/gate2B/RQCI',
            data: pushData
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function listInvoices(listInvoiceData) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'profic/gate2B/LIP',
            data: listInvoiceData
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function searchData(search) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'profic/gate2B/LIS',
            data: search
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getUserProfileData() {
    try {
        const response = await api({
            method: 'post',
            url: mtcUrl + '/userProfile',
            data: {}
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function createAccessToken() {
    try {
        const response = await api({
            method: 'post',
            url: mtcUrl + '/createAccessToken',
            data: {}
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function createPdf(data) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'profic/createInvoice',
            data: data
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function previewPdf(data){
    try{
        const response =await api({
            method:'post',
            url:apiUrl+'profic/viewPreview',
            data:data
        })
        return response.data
    }catch(error){
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function sendPdf(data){
    try{
        const response= await api({
            method:'post',
            url:apiUrl+'profic/createOtInvoiceSend',
            data:data
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function loadPdf(data) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'profic/readInvoice',
            data: data
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function generatePdf(data) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'profic/getCreatedInvoice',
            data: data
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getCurrency(signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getCurrency',encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function getLanguages(signal) {
    try {
        // const response= await api({
        //     method:'post',
        //     url:apiUrl+'profic/getLanguages',
        //     data:{}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getLanguages', encryptToken, config);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getModules(signal) {
    try {
        // const response= await api({
        //     method:'post',
        //     url:apiUrl+'profic/getModules',
        //     data:{}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getModules', encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getConditions(signal) {
    try {
        // const response = await api({
        //     method: 'post',
        //     url: apiUrl + 'profic/getConditions',
        //     data: {}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getConditions', encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getCountry(signal) {
    try {
        // const response = await api({
        //     method: 'post',
        //     url: apiUrl + 'profic/getQrEsr',
        //     data: {}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getCountries', encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function createTemplate(data) {
    try {
        // const config = { signal }
        const response = await api.post(apiUrl + 'profic/createTemplate', data);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function loadTemplates(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/listTemplate',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function deleteTemplates(params) {
    try {
        // const config = { signal }
        const response = await api.post(apiUrl + 'profic/deleteTemplate',params);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateTemplates(params) {
    try {
        // const config = { signal }
        const response = await api.post(apiUrl + 'profic/updateTemplate',params);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function loadAddress(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/listAddress',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function createAddress(params) {
    try {
        // const config = { signal }
        const response = await api.post(apiUrl + 'profic/gate2B/CA',params);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateAddressQueue(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/updateAddressId',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateAddress(params) {
    try {
        // const config = { signal }
        const response = await api.post(apiUrl + 'profic/gate2B/EA',params);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateTemplate(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/updateTemplateId',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function gate2BUI(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/gate2B/UI',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getTaxcode(signal) {
    try {
        // const response = await api({
        //     method: 'post',
        //     url: apiUrl + 'profic/getQrEsr',
        //     data: {}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getTaxcode', encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getQrEsr(signal) {
    try {
        // const response = await api({
        //     method: 'post',
        //     url: apiUrl + 'profic/getQrEsr',
        //     data: {}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getQrEsr', encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getHistory(data) {
    try {
        const response = await api.post(apiUrl + 'profic/getHistory', data);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getIncomeAccounts(data,signal) {
    try {
        const response = await api.post(apiUrl + 'profic/getIncomeAccounts', data,signal);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getExpenseAccounts(data,signal) {
    try {
        const response = await api.post(apiUrl + 'profic/getExpenseAccounts', data,signal);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getCountryTemplate(signal) {
    try {
        // const response = await api({
        //     method: 'post',
        //     url: apiUrl + 'profic/getQrEsr',
        //     data: {}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getTemplateCountries', encryptToken, config);
        return response.data
    } catch (error) { 
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function getQueueStatus(signal) {
    try {
        // const response= await api({
        //     method:'post',
        //     url:apiUrl+'profic/getLanguages',
        //     data:{}
        // })
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getStatus',encryptToken, config);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function checkLoadAddress(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/gate2B/IA',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getInvoiceItems(params,signal){
    try{
        const config = {signal}
        const response =await api.post(apiUrl + 'profic/getItems',params,config);
        return response.data;
    }catch(error){
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getInvoiceAllItems(params,signal){
    try{
        const config = {signal}
        const response =await api.post(apiUrl + 'profic/getAllItems',params,config);
        return response.data;
    }catch(error){
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function createInvoice(params){
    try{
        // const config = {signal}
        const response =await api.post(apiUrl + 'profic/gate2B/CI',params);
        return response.data;
    }catch(error){
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getListdraftInvoices(listInvoiceData) {
    try {
        const response = await api({
            method: 'post',
            url: apiUrl + 'manual/listdraft',
            data: listInvoiceData
        })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateInvoice(params){
    try{
        // const config = {signal}
        const response =await api.post(apiUrl + 'profic/updateInvoices',params);
        return response.data;
    }catch(error){
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function saveInvoice(params){
    try{
        // const config = {signal}

        const response =await api.post(apiUrl +'profic/saveInvoices',params);
        return response.data;
    }catch(error){
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateCredit(params){
    try{
        const response=await api.post(apiUrl+'profic/updateCredit',params);
        return response.data;
    }catch(error){
        if(error.response){
            return error.response.data;
        }else{
            return{status:false,data:[]}
        }
    }
}

export async function getLog(params,signal){
    try{
        const config = {signal}
        const response =await api.post(apiUrl+'profic/getLog',params,config);
        return response.data;
    }catch(error){
        if(error.response){
            return error.response.data;
        }else{
            return{status:false,data:[]}
        }
    }
}

export async function pushToQueue(params){
    try{
        const response =await api.post(apiUrl+'profic/pushToQueue',params);
        return response.data;
    }catch(error){
        if(error.response){
            return error.response.data;
        }else{
            return {status:false,data:[]}
        }
    }
}

export async function deleteInvoice(params){
    try {
        const response =await api.post(apiUrl+'profic/deleteInvoice',params);
        return response.data
    } catch (error) {
        if(error.response)
        {
            return error.response.data;
        }else{
            return {status:false,data:{}}
        }
        
    }
}

export async function previewInvoice(params){
    try {
        const response =await api.post(apiUrl+'profic/previewDraftInvoice',params);
        return response.data
    } catch (error) {
        if(error.response)
        {
            return error.response.data;
        }else{
            return {status:false,data:{}}
        }
        
    }
}

export async function loadAllAddress(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/listAllAddress',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getInvoiceShedulers(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getShedulers',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function createInvoiceSheduler(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/createScheduler',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateInvoiceSheduler(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/updateScheduler',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function deleteInvoiceSheduler(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/deleteScheduler',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function copyInvoiceSheduler(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/copyInvoice',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function saveInvoiceItem(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/createItem',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function updateInvoiceItem(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/updateItem',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function deleteItem(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/deleteItem',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getModulelists(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getModulelist',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function saveModule(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/createModule',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function editModule(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/updateModule',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function deleteModule(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/deleteModule',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function listSearchAddress(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/listSearchAddress',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateStatusToDone(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/changeStatusToDone',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function updateStatusToDelete(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/changeStatusToDelete',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function changeQueueEmail(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/updateEmail',params,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getBank(signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getBank',encryptToken,config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getTemplates(signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/listAllTemplates', encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function fetchReminderList(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getReminderList', params, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function sendReminderMail(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/createOtInvoiceSendReminder', params, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function getActions(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getAllActions', params, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function getInvoiceType(signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/getInvoiceType', encryptToken, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}
export async function listDraftByAddress(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'queue/getListDraftByAddress',params,config);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function deleteInvoiceQueue(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/deleteInvoiceFromQueue',params,config);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function removeInvoiceCredit(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/removeInvoiceCredit',params,config);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function addInvoiceCredit(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/addInvoiceCredit',params,config);
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}

export async function createOtInvoiceSendArchive(params,signal) {
    try {
        const config = { signal }
        const response = await api.post(apiUrl + 'profic/createOtInvoiceSendArchive', params, config);
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return {status: false, data: []};
        }
    }
}