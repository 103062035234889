import React ,{ useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const options = 
  [
    'DE',
    'EN',
  ];
// const { t } = useTranslation(["language", "common"]);


function LanguageMenu() 
{
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) =>
    {
      setAnchorEl(event.currentTarget);
    };
  
  const handleMenuItemClick = (event, index) => 
    {
      // setSelectedIndex(index);
      setAnchorEl(null);
      i18next.changeLanguage(event.target.textContent.toLowerCase());
    };
  const handleClose = () => 
    {
      setAnchorEl(null);
    };

  return (
    <div>
      <List
          component="nav"
          aria-label="Device settings"
          sx={{ bgcolor: '' }}
          >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          >
            <ListItemText
              secondary={
                options.filter((e)=>
                  {
                    if(e !== currentLanguage.toUpperCase())
                    {
                      e='';
                    }
                    return e
                  })
                }
            />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {
          options.map((option, index) => (
            <MenuItem
            key={option}
            selected={index === options.findIndex( x => x === currentLanguage.toUpperCase() )}
            onClick={(event) => handleMenuItemClick(event, index)}
            >
            {option}
          </MenuItem>
          ))
        }
      </Menu>
    </div>
    )
}

export default LanguageMenu