import MainLayout from '../shared/layout/mainLayout';
import Dashboard from '../features/dashboard/dashboard';
import Createinvoice from '../features/create-invoice/create-invoice';
import Queue from '../features/queue/queue';
import Templates from '../features/Templates/templates';
import Address from "../features/address/address";
import ProtectedRoute from '../ProtectedRoute';
import CreditLogs from "../features/log/log";
import { Navigate } from 'react-router-dom';
import Items from "../features/items/items";
import Scheduler from "../features/scheduler/scheduler";
import Module from "../features/module/module";
import ReminderList from '../features/reminder-list/reminder-list';
import { useEffect, useMemo, useState } from 'react';
import useAuth from '../hooks/useAuth';
import CheckRights from './checkRights';
import Loader from '../shared/common/utilites/PageLoader/PageLoader';
import Archive from '../features/archive/archive';

const MainRoutes=()=>{
    const [rightRoute,setRightRoute] = useState([]);
    const { isLoggedIn,login,logout } = useAuth();

    const childRoutes = useMemo(()=>{
        return[
        {
            path:'dashboard',
            element:<Dashboard />,
            rights:'proffix.dashboard.'
        },
        {
            path:'reminderlist',
            element:<ReminderList />,
            rights:'proffix.reminderlist.'
        },
        {
            path:'queue',
            element:<Queue />,
            rights:'proffix.queue.'
        },
        {
            path:'invoice',
            rights:'proffix.invoice.',
            children:[
                {
                    path:"createinvoice",
                    element:<Createinvoice/>,
                    rights:'proffix.invoice.createinvoice.'
                },
                {
                    path:"item",
                    element:<Items/>,
                    rights:'proffix.invoice.item.'
                },
                {
                    path:"scheduler",
                    element:<Scheduler/>,
                    rights:'proffix.invoice.scheduler.'
                }
            ]
        },
        {
            path:'template',
            element:<Templates/>,
            rights:'proffix.template.'
        },
        {
            path:'address',
            element:<Address/>,
            rights:'proffix.address.'
        },
        {
            path:'module',
            element:<Module/>,
            rights:'proffix.module.'
        },
        {
            path:'log',
            element:<CreditLogs />,
            rights:'proffix.log.'
        },
        {
            path:'archive',
            element:<Archive />,
            rights:'proffix.archive.'
        }
    ]},[]);

    useEffect(()=>{
        const initialLogin = async()=>{
            if(!isLoggedIn)
                {
                    console.log("mainRoute failed");
                    await login();
                }else{
                    const rightRoutes = CheckRights(childRoutes) || [];
                    if(rightRoutes.length === 0){
                        await logout();
                    }else{
                        await setRightRoute(rightRoutes);
                    }
                }
        }
        initialLogin();
    },[isLoggedIn,logout,login,childRoutes]);

    const routes=[{
        path:'/',
        children:[{
            path:'/',
            element:(
                <ProtectedRoute>
                  <MainLayout/>
                </ProtectedRoute>
            ),
            children:[
                {
                    path:'/',
                    element:(rightRoute.length)> 0 ?(<Navigate to={rightRoute[0].path} />):<Loader/>,
                },
                ...rightRoute,
                {
                    path:'/*',
                    element: <Navigate to="/" replace />,
                    rights:'no.rights'
                }
            ]
        }]
    }]
    return routes;
}

export default MainRoutes;